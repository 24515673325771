import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import React from "react";
import { Formik } from "formik";
import * as Yup from 'yup';
import { useClassNames } from "@metaforcelabs/metaforce-core";
import { updateOAuthAppConnection } from "../../../../../api/workflowOAuthAppConnection";

const EditOAuthAppConnectionSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
});


export default function EditOAuthAppConnectionDialog({ open, setOpen, connection }) {
    const { classNames } = useClassNames();

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmitForm = async (values) => {
        connection.name = values.name;
        await updateOAuthAppConnection(connection);
        handleClose();
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                // static
                className="fixed z-40 inset-0 overflow-y-auto"
                open={open}
                onClose={handleClose}
            >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">

                            <Formik
                                initialValues={{ name: connection?.name || ''}}
                                validationSchema={EditOAuthAppConnectionSchema}
                                onSubmit={async (values, { setSubmitting }) => {
                                    await handleSubmitForm(values);
                                    setSubmitting(false);
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    /* and other goodies */
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="">
                                            <div className="font-medium">Edit connection name</div>
                                            <div className="space-y-1 mt-5">
                                                <label
                                                    htmlFor="appSelect"
                                                    className="block text-sm text-gray-900"
                                                >
                                                    Name
                                                </label>
                                                <input
                                                    type={"text"}
                                                    name="name"
                                                    value={values.name}
                                                    className={classNames(`focus:ring-brand-pink focus:border-brand-pink block sm:text-sm rounded-md w-full`,
                                                        errors.name ? "border-red-500" : "border-gray-300"
                                                    )}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.name ? (
                                                    <div className="text-red-500 text-sm">{errors.name}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="mt-5 sm:mt-6 flex sm:block">
                                            <button
                                                type="submit"
                                                className="w-1/2 sm:w-min sm inline-flex justify-center w-auto rounded-md border border-transparent shadow-sm px-4 py-2 bg-brand-pink text-base font-medium text-white hover:bg-brand-pink-hover sm:text-sm disabled:cursor-not-allowed"
                                                disabled={isSubmitting}
                                            >
                                                Save
                                            </button>
                                            <button
                                                type="button"
                                                disabled={isSubmitting}
                                                className="w-1/2 ml-2 sm:w-min sm:mt-3 w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-pink sm:mt-0 sm:col-start-1 sm:text-sm disabled:cursor-not-allowed"
                                                onClick={handleClose}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}


