import { Table, stringToLocaleDateTimeString } from "@metaforcelabs/metaforce-core";
import { OAuthAppConnectionContext } from "../../../../../contexts";
import { useContext } from "react";
import { NavLink } from "react-router-dom";
import WorkflowDefinitionStateBadge from "../../../WorkFlowEditor/workflowDefinitionStateBadge";


export default function OAuthAppWorkflowDefinitionComponent() {
    const { getOAuthAppName, connectionToViewWorkflows, isLoading, workflowTableActions } = useContext(OAuthAppConnectionContext);

    return !isLoading && (
        <div>
            <h4 className="mt-6 text-xl font-medium">All Workflows using {connectionToViewWorkflows ? connectionToViewWorkflows.name : getOAuthAppName()}</h4>
            <div className="relative z-0 mt-5">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                            onClick={() => workflowTableActions.orderBy("name")}
                                        >
                                            Name {workflowTableActions.getSortIcon("name")}
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                            onClick={() =>
                                                workflowTableActions.orderBy("workflowDefinitionState")
                                            }
                                        >
                                            State {workflowTableActions.getSortIcon("workflowDefinitionState")}
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                            onClick={() =>
                                                workflowTableActions.orderBy("createdDate")
                                            }
                                        >
                                            Created{" "}
                                            {workflowTableActions.getSortIcon("createdDate")}
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                            onClick={() =>
                                                workflowTableActions.orderBy("updatedDate")
                                            }
                                        >
                                            Updated{" "}
                                            {workflowTableActions.getSortIcon("updatedDate")}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {workflowTableActions?.pageData?.map((workflowDefinition) => (
                                        <tr key={workflowDefinition.id}>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-indigo-600 relative" style={{ minWidth: "250px" }}>
                                                <NavLink className={"cursor-pointer hover:underline"}
                                                    to={`/admin/workflow/editor/${workflowDefinition.id}`}
                                                >
                                                    {workflowDefinition.name}
                                                </NavLink>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                <WorkflowDefinitionStateBadge state={workflowDefinition.workflowDefinitionState} useShortText={true} />

                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {stringToLocaleDateTimeString(
                                                    workflowDefinition.createdDate
                                                )}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {stringToLocaleDateTimeString(
                                                    workflowDefinition.updatedDate
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <Table.Pagination tableActions={workflowTableActions} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
