
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import LoaderSpinner from "react-loader-spinner";
import React from "react";

export default function Loader() {
  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}
    >
      <LoaderSpinner
        type="Oval"
        color="#2b59c0"
        height={25}
        width={25}
      />
    </div>
  );
}