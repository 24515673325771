export default function DigipostIcon({ className }) {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" className={className}>
            <path d="M0 0 C66 0 132 0 200 0 C200 66 200 132 200 200 C134 200 68 200 0 200 C0 134 0 68 0 0 Z " fill="#D73127" transform="translate(0,0)" />
            <path d="M0 0 C40.26 0 80.52 0 122 0 C122 49.5 122 99 122 150 C81.74 150 41.48 150 0 150 C0 100.5 0 51 0 0 Z " fill="#D6342B" transform="translate(39,25)" />
            <path d="M0 0 C40.26 0 80.52 0 122 0 C122 49.5 122 99 122 150 C81.74 150 41.48 150 0 150 C0 100.5 0 51 0 0 Z M9 47 C9 78.02 9 109.04 9 141 C43.32 141 77.64 141 113 141 C113 109.98 113 78.96 113 47 C78.68 47 44.36 47 9 47 Z " fill="#FEFBFB" transform="translate(39,25)" />
            <path d="M0 0 C34.32 0 68.64 0 104 0 C104 9.24 104 18.48 104 28 C85.99864364 29.77603863 74.697379 23.98687122 59.0625 15.5625 C55.66872984 13.7819682 52.26951567 12.0121909 48.86949158 10.24363708 C46.77101862 9.14695407 44.67731725 8.04107916 42.58912659 6.92494202 C41.18001259 6.17427711 41.18001259 6.17427711 39.74243164 5.40844727 C38.5093502 4.74559471 38.5093502 4.74559471 37.25135803 4.0693512 C35.15976115 3.07588343 33.26123314 2.46386399 31 2 C29.41524955 4.99925342 27.83246479 7.99954008 26.25 11 C25.8065625 11.83917969 25.363125 12.67835937 24.90625 13.54296875 C22.20630754 18.66491841 19.57014546 23.81166899 17 29 C11.39 29 5.78 29 0 29 C0 19.43 0 9.86 0 0 Z " fill="#DC2F25" transform="translate(48,34)" />
            <path d="M0 0 C16.5 0 33 0 50 0 C50 7.26 50 14.52 50 22 C33.5 22 17 22 0 22 C0 14.74 0 7.48 0 0 Z " fill="#FAE9E8" transform="translate(75,89)" />
            <path d="M0 0 C34.32 0 68.64 0 104 0 C104 9.24 104 18.48 104 28 C103.67 28 103.34 28 103 28 C103 19.09 103 10.18 103 1 C67.36 1.495 67.36 1.495 31 2 C23.90605763 15.45179933 23.90605763 15.45179933 17 29 C11.39 29 5.78 29 0 29 C0 19.43 0 9.86 0 0 Z " fill="#DF4138" transform="translate(48,34)" />
            <path d="M0 0 C2.87780801 0.63000567 5.31361779 1.53452095 7.94238281 2.85766602 C8.70286926 3.23798477 9.46335571 3.61830353 10.24688721 4.01014709 C11.46272491 4.62580788 11.46272491 4.62580788 12.703125 5.25390625 C13.54091492 5.67421616 14.37870483 6.09452606 15.24188232 6.52757263 C17.91315788 7.86880087 20.58159541 9.21557043 23.25 10.5625 C25.06231565 11.47367406 26.87481356 12.3844857 28.6875 13.29492188 C33.12712459 15.52575054 37.56421226 17.76155251 42 20 C42 20.33 42 20.66 42 21 C25.17 21 8.34 21 -9 21 C-6.75 14.25 -6.75 14.25 -5.2734375 11.1328125 C-4.9640625 10.47539063 -4.6546875 9.81796875 -4.3359375 9.140625 C-3.86027344 8.14289062 -3.86027344 8.14289062 -3.375 7.125 C-2.88773437 6.09246094 -2.88773437 6.09246094 -2.390625 5.0390625 C-1.59676528 3.35794781 -0.79880442 1.67877079 0 0 Z " fill="#DC3127" transform="translate(80,41)" />
            <path d="M0 0 C13.2 0 26.4 0 40 0 C40 3.96 40 7.92 40 12 C26.8 12 13.6 12 0 12 C0 8.04 0 4.08 0 0 Z " fill="#D9382F" transform="translate(80,94)" />
            <path d="M0 0 C8.58 0 17.16 0 26 0 C26 0.99 26 1.98 26 3 C17.42 3 8.84 3 0 3 C0 2.01 0 1.02 0 0 Z " fill="#F5CECC" transform="translate(82,96)" />
            <path d="M0 0 C7.26 0 14.52 0 22 0 C22 0.99 22 1.98 22 3 C14.74 3 7.48 3 0 3 C0 2.01 0 1.02 0 0 Z " fill="#F5CECC" transform="translate(82,101)" />
        </svg>
    )
}