// import saveAs from "file-saver";
import { useFileSaver, useTableSort, useToastAction } from '@metaforcelabs/metaforce-core';
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { getWorkflowDefinition, getWorkflowDefinitionValues } from "../../../api/workflowDefinition";
import { destroyWorkflowValues } from "../../../api/workflowValues";
import { stringToLocaleDateTimeString } from "../../../utils/date";
import { WorkflowDataListCtxMenu } from "./Components/workflowDataListCtxMenu";
import WorkflowValuesStateBadge from "./Components/workflowValuesStateBadge";
import { ContentWrapper, PageHeader } from '@metaforcelabs/metaforce-core';

export const WorkflowData = ({ }) => {
    const { workflowId } = useParams();
    const [workflowValues, setWorkflowValues] = useState([]);
    const [workflowDefintion, setWorkflowDefintion] = useState({});
    const tableSort = useTableSort(workflowValues);
    const loadAction = useToastAction();

    const loadWorkflowDefenition = async () => {
        const data = await getWorkflowDefinition(workflowId);
        setWorkflowDefintion(data);
    }

    const loadWorkflowValues = async () => {
        const data = await getWorkflowDefinitionValues(workflowId);
        setWorkflowValues(data);
    }

    const loadData = async () => {
        loadAction.execute(async () => {
            await Promise.all([loadWorkflowValues(), loadWorkflowDefenition()]);

        }, "Failed to load workflow data");
    }

    useEffect(() => {
        loadData();
    }, [])

    const handleWorkflowValuesDestroyed = (workflowValues) => {
        setWorkflowValues(prev => {
            const idx = prev.findIndex(x => x.id === workflowValues.id);
            if (idx !== -1) {
                prev.splice(idx, 1);
            }
            return [...prev];
        })
    }

    return (
        <ContentWrapper>
            <div className="flex flex-row justify-between items-end">
                <PageHeader
                    title={workflowDefintion?.name ? `${workflowDefintion?.name} Workflow Data` : ""}
                />
            </div>
            <div className="mt-5 py-5">
                <div className="relative z-0">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                                onClick={() => tableSort.orderBy("name")}
                                            >
                                                ID {tableSort.getSortIcon("name")}
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                                onClick={() =>
                                                    tableSort.orderBy("workflowDefinitionState")
                                                }
                                            >
                                                State {tableSort.getSortIcon("workflowDefinitionState")}
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                                onClick={() =>
                                                    tableSort.orderBy("createdDate")
                                                }
                                            >
                                                Created{" "}
                                                {tableSort.getSortIcon("createdDate")}
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                                onClick={() =>
                                                    tableSort.orderBy("updatedDate")
                                                }
                                            >
                                                Updated{" "}
                                                {tableSort.getSortIcon("updatedDate")}
                                            </th>
                                            <th
                                                scope="col"
                                                className="relative px-6 py-3"
                                            >
                                                <span className="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {tableSort?.tableData?.map((element) => (
                                            <TableRow key={element.id} element={element} onDestroyed={workflowValues => handleWorkflowValuesDestroyed(workflowValues)} />
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ContentWrapper>)
}

const TableRow = ({ element, onDestroyed }) => {
    const { saveAs } = useFileSaver();
    const destroyAction = useToastAction();

    const handleDestroyWorkflowValues = (workflowValues) => {
        destroyAction.execute(async () => {
            const destroyed = await destroyWorkflowValues(workflowValues.id);
            onDestroyed(destroyed);
        });
    }

    const handleExportValues = (workflowValues) => {
        const fileName = `export-workflowvalues-${element.id}.json`;
        saveAs(JSON.stringify(workflowValues), fileName, "text/plain;charset=utf-8");
    }

    return (
        <tr>
            {/* <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">

                                </td> */}
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 relative" style={{ minWidth: "250px" }}>
                <span>{element.id}</span>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <WorkflowValuesStateBadge isComplete={element.isComplete} />
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {stringToLocaleDateTimeString(
                    element.createdDate
                )}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {stringToLocaleDateTimeString(
                    element.updatedDate
                )}
            </td>
            <td className="p-2 whitespace-nowrap text-sm font-medium">
                <WorkflowDataListCtxMenu dataElement={element}
                    onDestroy={workflowValues => handleDestroyWorkflowValues(workflowValues)}
                    onExport={workflowValues => handleExportValues(workflowValues)} />
            </td>
        </tr>
    )
}
