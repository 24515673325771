import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import { useReactOidc } from '@axa-fr/react-oidc-context';

import './styles.scss';
import { config } from '../../utils/config';
import { setAuthHeader } from '../../api/api'
import { getCustomerUserAccess } from '../../api/customer';
import { Link } from "react-router-dom";
import { MenuBar, ContentWrapper } from '@metaforcelabs/metaforce-core';

const Oidc = () => {
  const history = useNavigate();
  const { oidcUser } = useReactOidc();

  const navigationMenu = [
    {
      linkElement: <Link to="/">Home</Link>,
      name: "Home",
      current: true,
      featureEnabled: true
    }
  ];

  useEffect(() => {
    if (oidcUser) {
      setAuthHeader(oidcUser.access_token)
      validateUserAccess()
    }
  }, [oidcUser])

  const validateUserAccess = async () => {
    const customerUserAccess = await getCustomerUserAccess();
    const product = customerUserAccess.find(x=> x.productId === config.productLicense);
    const hasAccess = product.featureCodes?.includes(config.workflowUsageFeatureCode) || false;
    if (!hasAccess) {
      history('/missing-license');
    }
  }

  return (
    <>
      <div className="fixed flex flex-col bg-gray-50 h-screen w-screen overflow-auto">
        <MenuBar
          navigation={navigationMenu}
          homeButtonLink={config.centerpointUiBaseUrl}
          helpLink={config.docsUrl}
        />
          <ContentWrapper>
            <p className="text-2xl mt-6">Loading ...</p>
          </ContentWrapper>
      </div>
    </>
  )

}

export default Oidc;