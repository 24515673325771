import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../contexts";

export const useBranchAbbrevation = () => {
    const { workFlow: workflow } = useContext(WorkflowContext);
    const [branchAbbrevations, setBranchAbbrevations] = useState([])

    const abbrevateName = (name, max = 2) => {
        name = name || '';
        const nameParts = name.split(' ');

        return nameParts.length === 1 || nameParts[0].length === max ?  name.slice(0,max).toUpperCase() : name.match(/\b([A-Za-z0-9])/g)?.slice(0,max).join('').toUpperCase();
    }
    const creteAbbrevations = () => {
        return workflow?.workflowBranches?.map(b => ({ id: b.id, abbrevation: abbrevateName(b.name) })) || [];
    }

    useEffect(() => {
        setBranchAbbrevations(prev => {
            return creteAbbrevations();
        })
    }, [workflow?.workflowBranches])

    return {
        getBranchAbbrevation: (branchId) => {
            return branchAbbrevations.find(x => x.id === branchId)?.abbrevation || 'A';
        }
    }
}