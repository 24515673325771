import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { BranchContext, WorkflowContext, WorkflowStepContext } from '../../../../contexts';
import { workflowStepType } from '../../../../utils/constants';
import WorkflowEventAppIcon from '../workflowAppIcon'
import AppEventList from './appEventList';
import CollabsableSection from './collapsableSection'
import WorkflowEditorModal from './workflowEditorModal';
import { NO_STEP_EVENT_ALLOWED, useWorkflowhelper } from '../../../../hooks/useWorkflowHelper';

export default function AppEventSelect({ setupStepIdx, activeStepSection, setActiveStep, workflowStep }) {

    const workflowContext = useContext(WorkflowContext);
    const branchContext = useContext(BranchContext);
    const {isLastStep, ...stepContext} = useContext(WorkflowStepContext);
    const [showAppEventListModal, setShowAppEventListModal] = useState(false);
    const { appEvent } = workflowStep;
    const { getAllowedFutureSteps,noFutureStepsAreAllowed } = useWorkflowhelper(workflowContext.workFlow);
    const allowedStepEvents = getAllowedFutureSteps(workflowStep.id);

    useEffect(() => {
        evaluateAutoSelect(appEvent);
    }, []);

    const evaluateAutoSelect = (selectedAppEvent) => {
        if (selectedAppEvent?.autoSelectEvent && selectedAppEvent.events?.length === 1) {
            if (!workflowStep.selectedEvent?.key) {
                handleStepEventChange(selectedAppEvent.events[0]);
            }
        }
    }
    const handleChangeApp = () => {
        setShowAppEventListModal(true);
        workflowContext.setDragAndDropDisabled(true);
    }

    const handleStepEventChange = (event) => {
        workflowContext.setStepEvent(workflowStep.id, event)
        setActiveStep(setupStepIdx + 1);
    }

    const handleChangeAppEvent = (appEvent) => {
        workflowContext.setStepAppEvent(workflowStep.id, appEvent);
        evaluateAutoSelect(appEvent);
        closeAppEventListModal();
    }

    const closeAppEventListModal = () => {
        setShowAppEventListModal(false);
        workflowContext.setDragAndDropDisabled(false);
    }

    return (
        <>
            <CollabsableSection sectionTitle={"Choose App and Event"} stepIdx={setupStepIdx} isActiveStep={activeStepSection === setupStepIdx} setActiveStep={setActiveStep}>

                <div>
                    <div className="flex justify-between items-center border rounded-lg hover:shadow-md p-4 cursor-pointer mt-4"
                        onClick={e => handleChangeApp()}
                    >
                        <div className="flex space-x-4 items-center">
                            <WorkflowEventAppIcon iconKey={workflowStep?.appEvent.appIcon} className="flex-none h-8 w-8 p-1 rounded shadow-md" />
                            <div>
                                <h4 className="font-semibold">{workflowStep?.appEvent.name}</h4>
                            </div>
                        </div>
                        <div className="font-semibold text-sm">Change</div>
                    </div>
                </div>
                <div>
                    {
                        workflowStep?.appEvent && !workflowStep?.appEvent?.autoSelectEvent && (
                            <div className="mt-4">
                                <h4 className="">{workflowStep.stepType === workflowStepType.action ? "Action" : "Trigger"} Event: </h4>
                                <div className="mt-2 relative">
                                    <Menu>
                                        <Menu.Button className="w-full">
                                            <div className="flex justify-between items-center rounded-lg bg-gray-100 py-2 px-4 cursor-pointer hover:shadow-sm ">
                                                <div className="font-semibold">
                                                    {workflowStep.selectedEvent?.name}
                                                </div>
                                                <div className="font-semibold text-gray-600">
                                                    <ChevronDownIcon className="h-5 w-5" />
                                                </div>
                                            </div>
                                        </Menu.Button>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="w-full z-10 absolute left-0 w-56 mt-1 origin-top-left bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <div className=" ">
                                                    {
                                                        appEvent.events?.filter(x => {
                                                            const correctStepType = x.stepType === workflowStep.stepType;

                                                            let correctBranchType = true;
                                                            if (x.forBranchTypes && x.forBranchTypes.length > 0 && branchContext?.branch?.branchType) {
                                                                correctBranchType = x.forBranchTypes.includes(branchContext?.branch?.branchType);
                                                            }
                                                            
                                                            const isAllowed = allowedStepEvents.length === 0 || allowedStepEvents.includes(x.key);

                                                            let lastStepOnly = false;
                                                            if(!isLastStep){
                                                                lastStepOnly = x.allowedFutureStepEvents?.includes(NO_STEP_EVENT_ALLOWED);
                                                            }

                                                            return correctStepType && correctBranchType && isAllowed && !lastStepOnly;
                                                        }).map(e => {
                                                            return (
                                                                <Menu.Item key={e.name}>
                                                                    {({ active }) => (
                                                                        <button
                                                                            className={`${active ? 'bg-gray-100' : 'text-gray-900'
                                                                                } group flex rounded-md items-center w-full px-4 py-3 text-sm font-semibold`}
                                                                            onClick={() => {
                                                                                handleStepEventChange(e)
                                                                            }}
                                                                        >
                                                                            {e.name}
                                                                        </button>
                                                                    )}
                                                                </Menu.Item>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                            </div>)
                    }
                </div>


            </CollabsableSection>
            <WorkflowEditorModal open={showAppEventListModal} setOpen={closeAppEventListModal} >
                <AppEventList onAppSelect={e => handleChangeAppEvent(e)} workflowStepId={workflowStep.id} />
            </WorkflowEditorModal>
        </>
    )
}
