import React from 'react'
import sampleImg from '../../../assets/images/sample-dashboard-workflow.png';

export default function WorkflowDisplayArt() {
  return (
    <div className='w-full flex justify-end'>
      <div className='relative h-32 p-1 overflow-hidden'>
        <img src={sampleImg} className="w-32 rounded-3xl mr-10" alt="sample" />
      </div>
    </div>
  )
}