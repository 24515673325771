import { getParagrphPlaceHolders } from "./slate";

export const placeHoldersHaveChanged = (prevValue, newValue) => {
    const placeHolderIdsPrev = getPlaceholderIdsFromValue(prevValue);
    const placeHolderIdsNew = getPlaceholderIdsFromValue(newValue);

    if (placeHolderIdsPrev.length !== placeHolderIdsNew.length) {
        return true;
    }

    const areEqual = placeHolderIdsPrev.every((v, i) => v === placeHolderIdsNew[i]);
    return !areEqual;
}

export const getPlaceholderIdsFromValue = (value) => {
    const placeHolders = getParagrphPlaceHolders(value);
    const ids = placeHolders.map(x => JSON.parse(x[1]).id);
    return ids;
}

export const concatObjectStringValues = (obj) => {
    if (!obj) return null;

    const values = Object.values(obj);
    
    return values.reduce((prev, element) => {
        if (typeof element == 'string') {
            prev += '\n' + element
        }
        return prev;
    },'');
}