import { useEffect, useState } from "react";
import React from "react";
import { Formik } from "formik";
import * as Yup from 'yup';
import { useToastAction } from "@metaforcelabs/metaforce-core";
import { createOAuthAppConnection, getOAuthAppConnections, updateOAuthAppConnection } from "../../../../../api/workflowOAuthAppConnection";
import { appConnectionType, sftpAuthenticationMethods } from "../../../../../utils/constants";
import toast from "react-hot-toast";
import Modal from "../../../../../components/Modals/modal";
import { Select } from "../../../../../components/Form/Select";
import { Password } from "../../../../../components/Password";
import { Button } from "../../../../../components/Form/Button";
import { InputText } from "../../../../../components/Form/InputText";
import { FileUpload } from "components/Form/FileUpload";

const EditSftpConnectionPasswordSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    sftpHost: Yup.string().required('Required'),
    sftpPort: Yup.number().required('Required'),
    sftpUsername: Yup.string().required('Required'),
    sftpPassword: Yup.object().test('sftpPassword', 'sftpPassword', (value, context) => {
        if (value.hasValue) {
            return true;
        } else if (!value.value) {
            return new Yup.ValidationError(
                'Required',
                null,
                'sftpPassword'
            );
        }
        return true;
    }),
});

const EditSftpConnectionKeypairSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    sftpHost: Yup.string().required('Required'),
    sftpPort: Yup.number().required('Required'),
    sftpUsername: Yup.string().required('Required'),
    sftpKeyFile: Yup.string().required('Required'),
    sftpPassPhrase: Yup.object().test('sftpPassPhrase', 'sftpPassPhrase', (value, context) => {
        if (value.hasValue) {
            return true;
        } else if (!value.value) {
            return new Yup.ValidationError(
                'Required',
                null,
                'sftpPassPhrase'
            );
        }
        return true;
    }),
});

export default function EditSftpConnectionDialog({ isOpen, onClose, connection }) {
    const [schema, setSchema] = useState(EditSftpConnectionPasswordSchema);
    const [initialValues, setInitialValues] = useState({});
    const [connections, setConnections] = useState([]);
    const loadAction = useToastAction();
    const saveAction = useToastAction();
    const dummyPassword = "•••";

    const loadData = async () => {
        loadAction.execute(async () => {
            const result = await getOAuthAppConnections(appConnectionType.sftp);
            setConnections(result.connections);
        }, "Failed to load data");
    }

    useEffect(() => {
        if (isOpen) {
            loadData();
        }
    }, [isOpen])

    useEffect(() => {
        if (connection) {
            setInitialValues(connection);
            const newSchema = connection.sftpAuthenticationMethod === sftpAuthenticationMethods[0].value ? EditSftpConnectionPasswordSchema : EditSftpConnectionKeypairSchema;
            setSchema(newSchema);
        } else {
            setInitialValues({
                name: '',
                sftpHost: '',
                sftpPort: '',
                sftpUsername: '',
                sftpAuthenticationMethod: sftpAuthenticationMethods[0].value,
                sftpPassword: {
                    value: '',
                    hasValue: false,
                    valueChanged: false,
                },
                sftpKeyFile: '',
                sftpPassPhrase: {
                    value: '',
                    hasValue: false,
                    valueChanged: false,
                },
            });
            setSchema(EditSftpConnectionPasswordSchema);
        }
    }, [connection])

    const handleClose = () => {
        onClose();
    };

    const handleSubmitForm = async (values) => {
        saveAction.execute(async () => {
            connection = values;
            connection.appConnectionType = appConnectionType.sftp;
            connection.sftpPort = Number(values.sftpPort);

            if (connection.id) {
                await updateOAuthAppConnection(connection);
            } else {
                await createOAuthAppConnection(connection);
            }

            handleClose();
        }, "Failed to save SFTP connection");
    }

    return (
        <Modal
            size={"large"}
            title={"SFTP connection"}
            isOpen={isOpen}
            onClose={onClose}
        >
            <Formik
                initialValues={initialValues}
                validateOnChange={false}
                validationSchema={schema}
                onSubmit={async (values, { setSubmitting, setErrors }) => {
                    let foundConnection = connections.filter(x => x.id !== connection?.id).find(x => x.name === values.name);
                    if (foundConnection) {
                        toast.error(`It can not save SFTP connection. ${foundConnection?.name} already exists.`);
                        setErrors({
                            name: 'Already exists',
                        });
                        return;
                    }

                    await handleSubmitForm(values);
                    setSubmitting(false);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="mt-3 space-y-5">
                            <InputText
                                id="name"
                                name="name"
                                label="Name"
                                value={values.name}
                                onChange={handleChange}
                                error={errors.name}
                            />

                            <InputText
                                id="sftpHost"
                                name="sftpHost"
                                label="Host"
                                value={values.sftpHost}
                                onChange={handleChange}
                                error={errors.sftpHost}
                            />
                            <InputText
                                id="sftpPort"
                                name="sftpPort"
                                label="Port"
                                value={values.sftpPort}
                                onChange={handleChange}
                                error={errors.sftpPort}
                            />
                            <InputText
                                id="sftpUsername"
                                name="sftpUsername"
                                label="Username"
                                value={values.sftpUsername}
                                onChange={handleChange}
                                error={errors.sftpUsername}
                            />
                            <Select
                                id="sftpAuthenticationMethod"
                                name="sftpAuthenticationMethod"
                                label="Authentication Method"
                                options={sftpAuthenticationMethods}
                                selectedValue={values.sftpAuthenticationMethod}
                                onChange={(v, name) => {
                                    const authMethod = Number(v);
                                    const newSchema = authMethod === sftpAuthenticationMethods[0].value ? EditSftpConnectionPasswordSchema : EditSftpConnectionKeypairSchema;
                                    setSchema(newSchema);
                                    handleChange({
                                        target: {
                                            name: name,
                                            value: authMethod,
                                        }
                                    });
                                }}
                            />

                            {values.sftpAuthenticationMethod === sftpAuthenticationMethods[0].value && (
                                <div className="space-y-1 mt-5">
                                    <Password
                                        label="SSH Password"
                                        name="sftpPassword"
                                        value={values?.sftpPassword?.value}
                                        onChange={e => {
                                            setFieldValue("sftpPassword", {
                                                hasValue: false,
                                                value: e.target.value,
                                                valueChanged: true,
                                            });
                                        }}
                                        showEyeButton={values?.sftpPassword?.valueChanged === true || false}
                                        placeHolder={values?.sftpPassword?.hasValue === true ? dummyPassword : ''}
                                        error={errors.sftpPassword}
                                    />
                                </div>
                            )}

                            {values.sftpAuthenticationMethod === sftpAuthenticationMethods[1].value && (
                                <>
                                    <div>
                                        <FileUpload
                                            name="sftpKeyFile"
                                            label={"Key File"}
                                            fileExtension=""
                                            onChange={(v) => {
                                                handleChange({
                                                    target: {
                                                        name: "sftpKeyFile",
                                                        value: v.fileContent,
                                                    }
                                                });
                                            }}
                                            error={errors.sftpKeyFile}
                                        />
                                        {values.sftpKeyFile &&
                                            <div className="mt-1 text-sm text-gray-500 font-medium">Key file Uploaded!</div>
                                        }
                                    </div>

                                    <Password
                                        label="Passphrase"
                                        name="sftpPassPhrase"
                                        value={values?.sftpPassPhrase?.value}
                                        onChange={e => {
                                            setFieldValue("sftpPassPhrase", {
                                                hasValue: false,
                                                value: e.target.value,
                                                valueChanged: true,
                                            });
                                        }}
                                        showEyeButton={values?.sftpPassPhrase?.valueChanged === true || false}
                                        placeHolder={values?.sftpPassPhrase?.hasValue === true ? dummyPassword : ''}
                                        error={errors.sftpPassPhrase}
                                    />
                                </>

                            )}

                        </div>
                        <div className="mt-5 sm:mt-6 flex sm:block">
                            <div className='flex space-x-4 justify-end'>
                                <Button onClick={() => { handleClose() }} text={"Cancel"} theme='white' />
                                <Button onClick={() => { handleSubmit() }} text={"Save"} />
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </Modal>

    );
}


