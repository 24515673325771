import { useClassNames } from "@metaforcelabs/metaforce-core";

export const InputText = ({
  id,
  name,
  label = null,
  value,
  onChange,
  disabled = false,
  horizontal = false,
  className,
  error
}) => {
  const { classNames } = useClassNames();
  return (
    <div className={classNames(className, horizontal ? 'flex items-center mt-1' : '')}>
      {label && (
        <label
          htmlFor={id}
          className={classNames(
            horizontal ? 'flex-1' : '',
            'block text-sm text-gray-700'
          )}
        >
          {label}
        </label>
      )}
      <input
        type="text"
        id={id}
        name={name}
        className={classNames(
          horizontal ? 'flex-1' : '',
          label && 'mt-1',
          error && 'placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900',
          'block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-gray-400 focus:border-gray-400 sm:text-sm rounded-md'
        )}
        onChange={onChange}
        value={value}
        disabled={disabled}
      >
      </input>
      {
        error &&
        <p className="mt-1 text-sm text-red-600" id="email-error">
          {error}
        </p>
      }
    </div>
  );
};
