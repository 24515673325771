export const usePreviousSteps = (workflowSteps, workflowBranches = []) => {

    if (!workflowBranches) {
        workflowBranches = [];
    }

    return {
        filterPreviousSteps: (stepId) => {
            
            const step = workflowSteps.find(ws => ws.id === stepId);
            if (!step) {
                return [];
            }

            let steps = [];
            let iteration = 0;
            let workingBranchId = step.branchId;
            while (true) {
                const branch = workflowBranches.find(x => x.id === workingBranchId);
                let branchSteps = workflowSteps.filter(ws => ws.branchId === workingBranchId);
                if (iteration === 0) {
                    const branchStepIdx = branchSteps.findIndex(ws => ws.id === stepId);
                    branchSteps = branchSteps.slice(0, branchStepIdx)
                }
                // reverse it as we are working from the bottom and up the hierarchy
                const reversed = branchSteps.reverse();
                steps = [...steps, ...reversed];
                iteration++;
                if (!workingBranchId) {
                    break;
                }
                workingBranchId = branch?.parentBranchId;
            }

            // corrects the order back to the workflow order
            return steps.reverse();

        }
    }
}