import Select from "react-select";
import { Button } from "../../../components/Form/Button";
import Modal from "../../../components/Modals/modal";
import { useEffect, useState } from "react";
import { useToastAction } from "@metaforcelabs/metaforce-core";
import { systemFolderIds } from "utils/folders";

export default function FolderModal({ isOpen, onClose, folders, onMoveToFolder }) {
    const [folderOptions, setFolderOptions] = useState([]);
    const [selectedFolderOption, setSelectedFolderOption] = useState(systemFolderIds.home);
    const loadAction = useToastAction();

    const loadData = async () => {
        loadAction.execute(() => {
            const options = []
      
            options.push({
              value: systemFolderIds.home,
              label: "Home"
            })
      
            folders?.forEach(folder => {
              options.push({
                value: folder.id,
                label: folder.name
              })
            });
      
            setFolderOptions(options);
          }, "Failed to load folders")
    }

    useEffect(() => {
        if (isOpen) {
            loadData();
        }
    }, [isOpen])

    return (
        <Modal
            size={"medium"}
            title={"Move To Folder"}
            isOpen={isOpen}
            onClose={() => onClose(false)}
        >
            <div className="pt-3 pb-6">
                <Select
                    className="relative"
                    name="language"
                    options={folderOptions}
                    onChange={(event) => {
                        setSelectedFolderOption(event.value);
                    }}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />
            </div>

            <div className='flex space-x-4 justify-end'>
                <Button onClick={() => onClose(false)} text={"Cancel"} theme='white' />
                <Button onClick={() => onMoveToFolder(selectedFolderOption)} text={"Move workflow"} />
            </div>
        </Modal>
    )
}