import { Menu, Transition } from "@headlessui/react";
import { DotsVerticalIcon, TrashIcon, PencilAltIcon, RefreshIcon, WifiIcon, EyeIcon } from "@heroicons/react/outline";
import { useClassNames } from '@metaforcelabs/metaforce-core';
import { OAuthAppConnectionContext } from "../../../../../contexts";
import React, { Fragment, useContext } from "react";
import { NavLink } from "react-router-dom";
import { appConnectionType } from "../../../../../utils/constants";

export const OAuthAppConnectionCtxMenu = ({ connection, onEdit, onReconnect, onTest, onDestroy }) => {
    const { classNames } = useClassNames();
    const { getViewWorkflowLink, getAppConnectionType } = useContext(OAuthAppConnectionContext);

    const showRename = () => {
        const connectionType = getAppConnectionType();
        return connectionType !== appConnectionType.kivra && connectionType !== appConnectionType.digipost;
    }

    return (
        <Menu as="div" className="inline-block text-left">
            <div>
                <Menu.Button className="text-gray-400 p-2 hover:text-gray-600">
                    <DotsVerticalIcon className="w-5 h-5" />
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute z-10 right-2 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-1 py-1 ">
                        {showRename() && (
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        className={classNames("group flex rounded-md items-center w-full px-2 py-2 text-sm",
                                            active ? "text-brand-pink font-semibold" : "text-gray-700")}
                                        onClick={e => {
                                            onEdit(connection)
                                        }}
                                    >
                                        <PencilAltIcon
                                            className="w-5 h-5 mr-2"
                                            aria-hidden="true"
                                        />
                                        Edit connection name
                                    </button>
                                )}
                            </Menu.Item>
                        )}
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    className={classNames("group flex rounded-md items-center w-full px-2 py-2 text-sm",
                                        active ? "text-brand-pink font-semibold" : "text-gray-700")}
                                    onClick={e => {
                                        onReconnect(connection)
                                    }}
                                >
                                    <RefreshIcon
                                        className="w-5 h-5 mr-2"
                                        aria-hidden="true"
                                    />
                                    Reconnect
                                </button>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    className={classNames("group flex rounded-md items-center w-full px-2 py-2 text-sm",
                                        active ? "text-brand-pink font-semibold" : "text-gray-700")}
                                    onClick={e => {
                                        onTest(connection)
                                    }}
                                >
                                    <WifiIcon
                                        className="w-5 h-5 mr-2"
                                        aria-hidden="true"
                                    />
                                    Test connection
                                </button>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <NavLink
                                    className={classNames("group flex rounded-md items-center w-full px-2 py-2 text-sm",
                                        active ? "text-brand-pink font-semibold" : "text-gray-700")}
                                    to={getViewWorkflowLink(connection?.id)}
                                >
                                    <EyeIcon
                                        className="w-5 h-5 mr-2"
                                        aria-hidden="true"
                                    />
                                    View Workflows
                                </NavLink>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    className={classNames("group flex rounded-md items-center w-full px-2 py-2 text-sm",
                                        active ? "text-brand-pink font-semibold" : "text-gray-700")}
                                    onClick={e => {
                                        onDestroy(connection)
                                    }}
                                >
                                    <TrashIcon
                                        className="w-5 h-5 mr-2"
                                        aria-hidden="true"
                                    />
                                    Delete
                                </button>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}