import { useContext, useState } from "react";
import { useClassNames } from "@metaforcelabs/metaforce-core";
import { FolderContext, FolderSidebarContext } from "../../../../contexts";
import { WorkflowFolderCtxMenu } from "./workflowFolderCtxMenu";
import DeleteFolderModal from "./deleteFolderModal";
import RenameFolderModal from "./renameFolderModal";

export default function SidebarItem({ item, index }) {
    const { selectedFolderId } = useContext(FolderContext);
    const { onSelectFolderClick, onDeleteFolder, onRenameFolder } = useContext(FolderSidebarContext);
    const current = selectedFolderId === item.id;
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showRenameModal, setShowRenameModal] = useState(false);
    const { classNames } = useClassNames();

    const handleClick = (e) => {
        current
            ? e.preventDefault()
            : item.onClick
                ? item.onClick(e, item, index)
                : onSelectFolderClick(e, item, index)
    }

    return (
        <>
            <div
                tabIndex={0}
                key={item.name}
                className={`flex px-3 py-2 text-sm group font-medium cursor-pointer items-center rounded-md 
                    ${item?.isAddItem ? "bg-white sticky top-0 justify-between flex-row-reverse py-4" : current ? "bg-indigo-100 text-gray-900" : "text-gray-600 hover:bg-gray-50 hover:text-gray-900"}`}
                aria-current={current ? 'page' : undefined}
                onClick={handleClick}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') 
                        handleClick(e);
                }}
            >
                <item.icon
                    className={
                        `flex-shrink-0 -ml-1 mr-3 ${item?.isAddItem ? "h-4 w-4" : current ? "text-gray-500 h-6 w-6" : "text-gray-400 group-hover:text-gray-500 h-6 w-6"}`
                    }
                    aria-hidden="true"
                />
                <span className={`truncate whitespace-nowrap overflow-hidden overflow-ellipsis select-none ${item?.isAddItem ? "text-base" : ""}`}>
                    {item.name}
                </span>
                {
                    current && !item.isStatic &&
                    <div className="ml-auto mr-2">
                        <WorkflowFolderCtxMenu
                            folder={item}
                            onRename={() => setShowRenameModal(true)}
                            onDestroy={() => setShowDeleteModal(true)}
                        />
                    </div>
                }
            </div>

            <DeleteFolderModal
                open={showDeleteModal}
                setOpen={(v) => setShowDeleteModal(v)}
                folder={item}
                onDelete={onDeleteFolder}
            />
            <RenameFolderModal
                open={showRenameModal}
                setOpen={(v) => setShowRenameModal(v)}
                folder={item}
                onRename={onRenameFolder}
            />
        </>
    )
}