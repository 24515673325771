import { useContext, useEffect, useState } from "react";
import CollabsableSection from "../collapsableSection";
import { useToastAction } from "@metaforcelabs/metaforce-core";
import toast from "react-hot-toast";
import ConnectionOptionsMenu from "./connectionOptionsMenu";
import { Link } from "react-router-dom";
import { viewpointConnectionState, workflowAppType } from "../../../../../utils/constants";
import { getViewpointSettings } from "../../../../../api/viewpoint";
import { config } from "../../../../../utils/config";
import { WorkflowContext } from "../../../../../contexts";

export default function ViewpointAccountSelect({ setupStepIdx, activeStepSection, setActiveStep, workflowStep, onConnectionChange }) {
    const { updateWorkflowStep } = useContext(WorkflowContext);
    const [connections, setConnections] = useState();
    const [selectedConnection, setSelectedConnection] = useState();
    const [selectedConnectionError, setSelectedConnectionError] = useState();

    const loadAction = useToastAction();

    const loadData = async () => {
        loadAction.execute(async () => {
            const viewpointSettings = await getViewpointSettings();
            setConnections(viewpointSettings.connections);

            if (workflowStep.oAuthAppConnectionId) {
                const foundConnection = viewpointSettings.connections.find(x => x.id === workflowStep.oAuthAppConnectionId);
                if (!foundConnection) {
                    toast.error(`Viewpoint connection is not found.`);
                    setSelectedConnectionError("Invalid");
                } else if (foundConnection.state === viewpointConnectionState.inactive) {
                    toast.error(`Viewpoint connection is inactive.`);
                    setSelectedConnectionError("Invalid");
                }
                setSelectedConnection(foundConnection);
            }
        }, "Failed to load")
    }

    useEffect(() => {
        loadData();
    }, []);

    const handleConnectionChange = async (connection) => {
        if (connection.id !== workflowStep.oAuthAppConnectionId) {
            setSelectedConnection(connection);

            workflowStep.oAuthAppConnectionId = connection.id;
            updateWorkflowStep(workflowStep, workflowStep.id);
        }

        setSelectedConnectionError(null);
        setActiveStep(setupStepIdx + 1);
    }

    return (
        <>
            <CollabsableSection sectionTitle={"Account"} stepIdx={setupStepIdx} isActiveStep={activeStepSection === setupStepIdx} setActiveStep={setActiveStep}>
                <div className="mt-5">
                    <h4 className="mb-1">{workflowStep.appEvent?.type === workflowAppType.kivra ? 'Environment:' : 'Choose Account:'} </h4>
                    <ConnectionOptionsMenu
                        connections={connections}
                        selectedConnection={selectedConnection}
                        onSelect={handleConnectionChange}
                        isLoading={loadAction.isExecuting}
                        error={selectedConnectionError}
                    />
                    <div className='mt-1 text-gray-500 text-sm'>
                        <span>You have to go to </span>
                        <Link target="_blank" rel="noopener noreferrer" to={`${config.centerpointUiBaseUrl}/admin/viewpoint`} className="text-indigo-700 underline">
                            Centerpoint Administration
                        </Link>
                        <span> to create connection</span>
                    </div>
                </div>
            </CollabsableSection>
        </>
    )
}