import { Disclosure } from "@headlessui/react";
import React, { useCallback, useContext, useRef } from "react";
import { useEffect, useState } from "react";
import { ClickOutside } from "../../../../../../components/ClickOutside";
import {
    createEditor, Transforms
} from 'slate'
import '../../components.scss'
import { Editable, ReactEditor, Slate, useFocused, useSelected, withReact } from "slate-react";
import { deserialize, serialize, wrapWorkflowValue } from "../../../../../../utils/slate";
import WorkflowEventAppIcon from "../../../workflowAppIcon";
import { StepValueOptions } from "../../stepValueOptions";
import { NodeItemContext, WorkflowContext } from "../../../../../../contexts";
import { placeHolderExists } from "../../../utils";
import { useClassNames } from '@metaforcelabs/metaforce-core';
import { minHeight } from "tailwindcss/defaultTheme";

const withWorkflowValues = editor => {
    const { isInline, isVoid } = editor;

    editor.isInline = element => {
        return ['workflow-value', 'mention'].includes(element.type) ? true : isInline(element);
    }

    editor.isVoid = element => {
        return ['workflow-value', 'mention'].includes(element.type) ? true : isVoid(element);
    }

    return editor;
}

const withdWorkflowStep = (editor, workflowStep) => {

    editor.workflowStepId = workflowStep.id;

    return editor;
}

const insertWorkflowValueElement = (editor, valueObj) => {
    const mention = {
        type: 'workflow-value',
        data: valueObj,
        children: [{ text: wrapWorkflowValue(JSON.stringify(valueObj)) }],
    }
    Transforms.insertNodes(editor, mention)
    Transforms.move(editor)
}

const WorkflowValueElement = ({ attributes, children, element }) => {
    const nodeContext = useContext(NodeItemContext);
    const selected = useSelected()
    const focused = useFocused()
    const { stepIdx, icon, stepName, name } = element.data;
    const { classNames } = useClassNames();

    const [placeHolderValid, setPlaceHolderValid] = useState(nodeContext.validatePlaceHolder(element.data));

    return (
        <span {...attributes}
            className={classNames("space-x-2 px-2 cursor-pointer bg-white rounded border-gray-200 border hover:shadow",
                selected && focused ? "shadow" : ""
            )}
        >
            <WorkflowEventAppIcon iconKey={icon} className={classNames("h-4 w-5  inline-block shadow rounded p-0.5", placeHolderValid ? "" : "opacity-50")} />
            <span className={classNames("font-semibold text-md", placeHolderValid ? "" : "opacity-30")}> <span>{placeHolderValid ? "" : "(No Data)"}</span> <span>{stepIdx + 1}. {name}</span></span>
            <span>{children}</span>
        </span>
    )
}

const Element = props => {
    const { attributes, children, element } = props
    switch (element.type) {
        case 'workflow-value':
            return <WorkflowValueElement {...props} />
        default:
            return <p {...attributes}>{children}</p>
    }
}
const initialValue = [
    {
        type: 'paragraph',
        children: [
            {
                text:
                    '',
            },
        ],
    }
];

export const NodeItem = ({ item, nodeValue, handleNodeValueChange, previousSteps, stepIdx, workflowStep, previousStepsValues, placeHolder = "Enter text or data...", elementHeight = null }) => {
    const [value, setValue] = useState(nodeValue ? deserialize(nodeValue) : initialValue)
    const workflowContext = useContext(WorkflowContext);
    const renderElement = useCallback(props => <Element {...props} />, [])
    const { classNames } = useClassNames();
    const editorRef = useRef(null);
    const editorRefDom = useRef(null);
    const [itemLoaded, setItemLoaded] = useState(false);
    if (!editorRef.current) {
        editorRef.current = withWorkflowValues(withReact(createEditor()));
    }
    const editor = editorRef.current;

    const [openValuesMenu, setOpenValuesMenu] = useState(false);

    useEffect(() => {
        editorRefDom.current = ReactEditor.toDOMNode(editor, editor);
    }, []);

    useEffect(() => {
        if (itemLoaded) {
            handleNodeValueChange(item.id, serialize(value));
        }
        setItemLoaded(true);

    }, [value])

    const handleAddElement = (option) => {
        insertWorkflowValueElement(editor, option);
    }

    const handleValidatePlaceHolder = (placeHolder) => {
        return placeHolderExists(previousStepsValues, placeHolder);
    }

    return (
        <NodeItemContext.Provider
            value={{
                validatePlaceHolder: (placeHolder) => {
                    return handleValidatePlaceHolder(placeHolder);
                }
            }}
        >
            <div>
                <div className={classNames(elementHeight ? `h-${elementHeight}` : "", "min-w-0 w-full border-transparent rounded-lg bg-gray-100 hover:shadow-sm focus:border-indigo-500 focus:border-sm cursor-text px-4 py-2 overflow-y-auto", workflowContext.saving ? 'opacity-50' : '')}>
                    <Slate
                        editor={editor}
                        value={value}
                        onChange={value => {
                            setValue(value);
                        }}
                    >
                        <Editable
                            renderElement={renderElement}
                            onKeyDown={e => { }}
                            readOnly={workflowContext.saving}
                            onFocus={e => {
                                setOpenValuesMenu(prev => true);
                            }}
                            placeholder={placeHolder}
                            className={"min-height"}
                        />
                    </Slate>
                </div>
                <div className="w-full relative">
                    <Disclosure>
                        {({ open }) => (
                            <>
                                {openValuesMenu && (
                                    <ClickOutside onOutsideClick={() => {
                                        if (openValuesMenu) {
                                            setOpenValuesMenu(false);
                                        }
                                    }}
                                        ignoreRef={editorRefDom}
                                    >
                                        <Disclosure.Panel
                                            static className="w-full origin-bottom-left top-2 absolute left-0 z-10 px-4 pt-4 pb-4 text-sm text-gray-500 bg-white shadow rounded-lg mb-12 max-h-80 overflow-y-auto"

                                        >

                                            <div className="text-gray-800">
                                                {
                                                    previousSteps.map((sa, i) => {
                                                        return (<StepValueOptions key={sa.id} step={sa} stepValues={previousStepsValues[i] || []} onAdd={option => handleAddElement(option)} isCollapsedDefault={i === previousSteps.length -1 } />)
                                                    })
                                                    // valueItems.map(i => <div key={i.id}>{i.value}</div>)
                                                }
                                            </div>
                                        </Disclosure.Panel>
                                    </ClickOutside>
                                )}
                            </>
                        )}
                    </Disclosure>
                </div>
            </div >
        </NodeItemContext.Provider>
    )
}