import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export default function DndSortable({ id, handle = true, isSortable, disabled = false, children }) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: id, disabled: disabled });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    if (!isSortable) {
        return (
            <>
                {children}
            </>
        );
    }

    return (
        <div ref={setNodeRef} style={style} {...(!handle ? attributes : undefined)} {...(!handle ? listeners : undefined)} >
            {children}
        </div>
    );
}