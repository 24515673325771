import { createWorkflowDefinitionByTemplate, getStepValues, updateWorkflowDefinition } from "../api/workflowDefinition";
import { specialApps, workFlowApps } from "../domain/workflowApps";
import { workflowTemplateType, workflowAppType, filterConditions } from "./constants";
import { v4 as uuidv4 } from 'uuid';
import { wrapWorkflowValue } from "./slate";
import { config } from "./config";

const checkIfSpecialApp = (appType) => {
    const found = Object.values(specialApps).find(x => x.type === appType);
    return found;
}

const getStepName = (appEvent, selectedEvent, isSpecialApp) => {
    if (isSpecialApp)
        return appEvent?.name;
    else
        return `${selectedEvent?.name} in ${appEvent?.name}`;
}

const convertToStep = (stepTemplate) => {
    const isSpecialApp = checkIfSpecialApp(stepTemplate.appType);
    const appEvent = isSpecialApp
        ?
        Object.values(specialApps).find(x => x.type === stepTemplate.appType)
        :
        workFlowApps.find(x => x.type === stepTemplate.appType);
    if (!appEvent)
        return null;

    const selectedEvent = appEvent.events?.find(x => x.key === stepTemplate.eventKey);
    if (!selectedEvent)
        return null;

    return {
        id: uuidv4(),
        name: getStepName(appEvent, selectedEvent, isSpecialApp),
        stepType: selectedEvent?.stepType,
        appEvent: appEvent,
        selectedEvent: selectedEvent,
    };
}

const convertToValue = (workflowStep, valueTemplate) => {
    const foundProperty = workflowStep.selectedEvent?.setupSteps?.find(x => x.property === valueTemplate.property);
    if (!foundProperty)
        return null;

    if (valueTemplate.dependsOn) {
        return null;
    }

    return {
        ...valueTemplate,
        workflowStepId: workflowStep.id,
    };
}

const convertDependsonToValue = (workflowStep, valueTemplate, stepsValues) => {
    const foundProperty = workflowStep.selectedEvent?.setupSteps?.find(x => x.property === valueTemplate.property);
    if (!foundProperty)
        return null;

    if (!valueTemplate.dependsOn || !stepsValues)
        return null;

    const foundStepValue = findDependsOnStepValue(valueTemplate.dependsOn, stepsValues);
    if (!foundStepValue)
        return null;

    return {
        ...valueTemplate,
        workflowStepId: workflowStep.id,
        value: { value: wrapWorkflowValue(JSON.stringify(foundStepValue)) },
    }
}

const convertToWorkflowDefinition = (name, template) => {
    const stepLists = [];

    const workflowDef = {
        name: name,
        workflowSteps: template.steps.map(stepTemplate => {
            const workflowStep = convertToStep(stepTemplate);
            if (workflowStep) {
                stepLists.push({
                    stepTemplate: stepTemplate,
                    workflowStep: workflowStep,
                });
            }
            return workflowStep;
        }).filter(x => !!x),
        values: [],
    }

    stepLists.forEach(({ stepTemplate, workflowStep }) => {
        stepTemplate.values?.forEach(valueTemplate => {
            const value = convertToValue(workflowStep, valueTemplate);
            if (value) {
                workflowDef.values.push(value);
            }
        })
    })

    return workflowDef;
}

const mapDialogElementsToWorkflowElement = (dialogElement) => {
    return { key: dialogElement.property, value: { value: dialogElement.property, name: dialogElement.property } };
}

const loadStepValues = async (workflowDef, step) => {
    let valuePromises = [];

    if (step.selectedEvent?.loadStepValuesFromApi) {
        const stepValues = await getStepValues(workflowDef.id, step);
        valuePromises.push(Promise.resolve(stepValues.map(e => mapDialogElementsToWorkflowElement(e))))
    }

    const values = (await Promise.all(valuePromises)).flat(1);
    return values;
}

const loadStepsValues = async (workflowDef) => {
    let stepsValues = [];

    for (let index = 0; index < workflowDef.workflowSteps.length; index++) {
        let { appEvent, id, name, selectedEvent } = workflowDef.workflowSteps[index];
        let step = { appEvent, selectedEvent, id, name, values: [] };
        const stepValues = await loadStepValues(workflowDef, step);

        stepsValues = stepsValues.concat(stepValues.map((stepValue) => {
            return {
                name: stepValue.value.name,
                id: stepValue.key,
                stepIdx: index,
                stepName: step.name,
                stepId: step.id,
                icon: step.appEvent?.appIcon,
            };
        }));
    };

    return stepsValues;
}

const findDependsOnStepValue = (dependsOn, stepsValues) => {
    if (!dependsOn)
        return null;
    return stepsValues.find(x => x.stepIdx === dependsOn.stepIdx && x.id === dependsOn.stepValueKey);
}

const handleFilterGroups = (stepTemplate, workflowDef, stepsValues) => {
    if (!stepTemplate.filterGroups)
        return null;

    const filterGroups = stepTemplate.filterGroups.map(filterGroup => {
        const groupFilters = filterGroup.groupFilters.map(groupFilter => {
            const foundStepValue = findDependsOnStepValue(groupFilter.dependsOn, stepsValues);
            const foundStepDefinition = workflowDef.workflowSteps[groupFilter.dependsOn?.stepIdx];
            if (!foundStepValue || !foundStepDefinition)
                return null;

            return {
                ...groupFilter,
                field: {
                    name: groupFilter.dependsOn.stepValueKey,
                    id: groupFilter.dependsOn.stepValueKey,
                    stepIdx: groupFilter.dependsOn?.stepIdx,
                    stepName: foundStepDefinition.name,
                    stepId: foundStepDefinition.id,
                    icon: foundStepDefinition.appEvent.appIcon,
                },
            };
        }).filter(x => !!x);

        return { groupFilters };
    });

    return filterGroups;
}

const handleDependsonValues = async (workflowDef, template) => {
    if (!template.loadStepValuesRequired)
        return;

    const stepsValues = await loadStepsValues(workflowDef);

    workflowDef.workflowSteps.forEach((workflowStep, index) => {
        const stepTemplate = template.steps[index];

        workflowStep.filterGroups = handleFilterGroups(stepTemplate, workflowDef, stepsValues);

        stepTemplate.values?.forEach(valueTemplate => {
            const value = convertDependsonToValue(workflowStep, valueTemplate, stepsValues);
            if (value) {
                workflowDef.values.push(value);
            }
        })
    });

    await updateWorkflowDefinition(workflowDef);
}

export const convertAndCreateWorkflowFromTemplate = async (name, template) => {
    const workflowDef = convertToWorkflowDefinition(name, template);
    let createdWorkflow = await createWorkflowDefinitionByTemplate(workflowDef);
    await handleDependsonValues(createdWorkflow, template);
    return createdWorkflow;
}

export const workflowTemplates = [
    {
        type: workflowTemplateType.workflowTriggeredByWorkflow,
        loadStepValuesRequired: false,
        steps: [
            {
                appType: workflowAppType.smartForm,
                eventKey: "submit",
            },
            {
                appType: workflowAppType.filter,
                eventKey: "filter-setup",
            },
            {
                appType: workflowAppType.smartForm,
                eventKey: "new-smartform",
            },
        ],
    },
    {
        type: workflowTemplateType.kivraSendLetter,
        loadStepValuesRequired: true,
        steps: [
            {
                appType: workflowAppType.smartForm,
                eventKey: "submit",
            },
            {
                appType: workflowAppType.editor,
                eventKey: "create-pdf",
            },
            {
                appType: workflowAppType.kivra,
                eventKey: "kivra-create-document",
                values: [
                    {
                        property: "pdf",
                        dependsOn: {
                            stepIdx: 1,
                            stepValueKey: "File",
                        },
                    }
                ],
            },
            {
                appType: workflowAppType.kivra,
                eventKey: "kivra-find-user",
                values: [
                    {
                        property: "RecipientIdentifier",
                        value: { value: "ssn", name: "SSN" },
                    }
                ],
            },
            {
                appType: workflowAppType.filter,
                eventKey: "filter-setup",
                filterGroups: [
                    {
                        groupFilters: [
                            {
                                dependsOn: {
                                    stepIdx: 3,
                                    stepValueKey: "IsValidKivraUser",
                                },
                                condition: filterConditions.find(x => x.key === "bool-is-true"),
                            }
                        ],
                    },
                ],
            },
            {
                appType: workflowAppType.kivra,
                eventKey: "kivra-send-letter",
                values: [
                    {
                        property: "RecipientIdentifier",
                        value: { value: "ssn", name: "SSN" },
                    },
                    {
                        property: "SSN",
                        dependsOn: {
                            stepIdx: 4,
                            stepValueKey: "SSN",
                        },
                    },
                    {
                        property: "Documents",
                        dependsOn: {
                            stepIdx: 2,
                            stepValueKey: "Kivra Document",
                        },
                    },
                ],
            },
        ],
    },
    {
        type: workflowTemplateType.elementValueChangeAndRestApiPostAndUpdateValue,
        steps: [
            {
                appType: workflowAppType.smartForm,
                eventKey: "value-change",
            },
            {
                appType: workflowAppType.restApi,
                eventKey: "rest-api-post",
            },
            {
                appType: workflowAppType.smartForm,
                eventKey: "set-values",
            },
        ],
    }
];