import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import React from "react";
import { Formik } from "formik";
import * as Yup from 'yup';
import { useClassNames, useToastAction } from "@metaforcelabs/metaforce-core";
import { createOAuthAppConnection, getOAuthAppConnections, updateOAuthAppConnection } from "../../../../../api/workflowOAuthAppConnection";
import { appConnectionType, kivraEnvironments } from "../../../../../utils/constants";
import toast from "react-hot-toast";
import { Password } from "../../../../../components/Password";

const EditKivraConnectionSchema = Yup.object().shape({
    kivraTenantKey: Yup.object().test('kivraTenantKey', 'kivraTenantKey', (value, context) => {
        if (value.hasValue) {
            return true;
        } else if (!value.value) {
            return new Yup.ValidationError(
                'Required',
                null,
                'kivraTenantKey'
            );
        }
        return true;
    }),
});

export default function EditKivraConnectionDialog({ open, onClose, connection }) {
    const { classNames } = useClassNames();
    const [initialValues, setInitialValues] = useState({});
    const [connections, setConnections] = useState([]);
    const loadAction = useToastAction();
    const saveAction = useToastAction();
    const dummyPassword = "•••";

    const loadData = async () => {
        loadAction.execute(async () => {
            const result = await getOAuthAppConnections(appConnectionType.kivra);
            setConnections(result.connections);
        }, "Failed to load data");
    }

    useEffect(() => {
        if (open) {
            loadData();
        }
    }, [open])

    useEffect(() => {
        if (connection) {
            setInitialValues(connection);
        } else {
            setInitialValues({
                kivraEnvironment: kivraEnvironments[0].value,
                kivraTenantKey: {
                    value: '',
                    hasValue: false,
                    valueChanged: false,
                },
            });
        }
    }, [connection])

    const handleClose = () => {
        onClose();
    };

    const handleSubmitForm = async (values) => {
        saveAction.execute(async () => {
            connection = values;
            connection.appConnectionType = appConnectionType.kivra;

            if (connection.id) {
                await updateOAuthAppConnection(connection);
            } else {
                await createOAuthAppConnection(connection);
            }

            handleClose();
        }, "Failed to save Kivra connection");
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                // static
                className="fixed z-40 inset-0 overflow-y-auto"
                open={open}
                onClose={handleClose}
            >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">

                            <Formik
                                initialValues={initialValues}
                                enableReinitialize={true}
                                validationSchema={EditKivraConnectionSchema}
                                onSubmit={async (values, { setSubmitting, setErrors }) => {
                                    let foundConnection = connections.filter(x => x.id !== connection?.id).find(x => x.kivraEnvironment === values.kivraEnvironment);
                                    if (foundConnection) {
                                        const foundEnvironment = kivraEnvironments.find(x => x.value === values.kivraEnvironment);
                                        toast.error(`It can not create Kivra connection. Environment ${foundEnvironment?.name} already exists.`);
                                        setErrors({
                                            kivraEnvironment: 'Already exists',
                                        });
                                        return;
                                    }

                                    await handleSubmitForm(values);
                                    setSubmitting(false);
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    setFieldValue,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    /* and other goodies */
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="">
                                            <div className="font-medium">Kivra connection</div>
                                            <div className="space-y-1 mt-5">
                                                <label
                                                    htmlFor="appSelect"
                                                    className="block text-sm text-gray-900"
                                                >
                                                    Environment
                                                </label>
                                                <select
                                                    name="kivraEnvironment"
                                                    value={values.kivraEnvironment}
                                                    className={classNames(`focus:ring-brand-pink focus:border-brand-pink block sm:text-sm rounded-md w-full`,
                                                        errors.kivraEnvironment ? "border-red-500" : "border-gray-300"
                                                    )}
                                                    onChange={(e) => {
                                                        handleChange({
                                                            target: {
                                                                name: e.target.name,
                                                                value: Number(e.target.value),
                                                            }
                                                        });
                                                    }}
                                                >
                                                    {kivraEnvironments.map((environment) => {
                                                        return (
                                                            <option key={environment.value} value={environment.value}>{environment.name}</option>
                                                        )
                                                    })}
                                                </select>
                                                {errors.kivraEnvironment ? (
                                                    <div className="text-red-500 text-sm">{errors.kivraEnvironment}</div>
                                                ) : null}
                                            </div>
                                            <div className="space-y-1 mt-5">
                                                <Password
                                                    label="Tenant key"
                                                    name="kivraTenantKey"
                                                    value={values?.kivraTenantKey?.value}
                                                    onChange={e => {
                                                        setFieldValue("kivraTenantKey", {
                                                            hasValue: false,
                                                            value: e.target.value,
                                                            valueChanged: true,
                                                        });
                                                    }}
                                                    showEyeButton={values?.kivraTenantKey?.valueChanged === true || false}
                                                    placeHolder={values?.kivraTenantKey?.hasValue === true ? dummyPassword : ''}
                                                    error={errors?.kivraTenantKey}
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-5 sm:mt-6 flex sm:block">
                                            <button
                                                type="submit"
                                                className="w-1/2 sm:w-min sm inline-flex justify-center w-auto rounded-md border border-transparent shadow-sm px-4 py-2 bg-brand-pink text-base font-medium text-white hover:bg-brand-pink-hover sm:text-sm disabled:cursor-not-allowed"
                                                disabled={isSubmitting}
                                            >
                                                Save
                                            </button>
                                            <button
                                                type="button"
                                                disabled={isSubmitting}
                                                className="w-1/2 ml-2 sm:w-min sm:mt-3 w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-pink sm:mt-0 sm:col-start-1 sm:text-sm disabled:cursor-not-allowed"
                                                onClick={handleClose}
                                            >
                                                Cancel
                                            </button>
                                        </div>

                                    </form>
                                )}
                            </Formik>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}


