import { SearchIcon } from '@heroicons/react/outline'
import { ButtonV2, CoreButtonTypes, Table, useAlertModal } from '@metaforcelabs/metaforce-core';
import { useTableSearch, useTableActions, useToastAction } from '@metaforcelabs/metaforce-core';
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import { activateWorkflowDefinition, deactivateWorkflowDefinition, destroyWorkflowDefinition, getWorkflowDefinitionsByFolder, moveWorkflowDefinitionToFolder } from '../../../api/workflowDefinition';
import { SpinnerBage } from '../../../components/Badge/spinnerBadge';
import { stringToLocaleDateTimeString } from '../../../utils/date';
import NewWorkflow from '../newWorkflow';
import WorkflowDefinitionStateBadge from '../WorkFlowEditor/workflowDefinitionStateBadge';
import { WorkflowListCtxMenu } from './Components/workflowListCtxMenu';
import { PageHeader } from '@metaforcelabs/metaforce-core';
import FoldersSidebar from './foldersSidebar';
import { systemFolderIds } from '../../../utils/folders';
import { getWorkflowDefinitionFolders } from '../../../api/workflowDefinitionFolder';
import FolderModal from './folderModal';
import { FolderContext } from '../../../contexts';

export default function WorkFlowList() {
    const [showNewDialog, setShowNewDialog] = useState(false);
    const [workflowDefinitions, setWorkflowDefinitions] = useState([]);
    const tableActions = useTableActions(workflowDefinitions, 10, "createdDate", "desc");
    const [searchTerm, setSearchTerm] = useState();
    const [selectedFolderId, setSelectedFolderId] = useState(systemFolderIds.home);
    const [folders, setFolders] = useState();
    const [showFolderModal, setShowFolderModal] = useState(false);
    const [workflowToMoveFolder, setWorkflowToMoveFolder] = useState();

    const loadAction = useToastAction();
    const loadFoldersAction = useToastAction();
    const moveToFolderAction = useToastAction();

    const loadData = async () => {
        loadAction.execute(async () => {
            const definitions = await getWorkflowDefinitionsByFolder(selectedFolderId);
            setWorkflowDefinitions(definitions);
        }, "Failed to load workflows");
    }

    const loadFolders = async () => {
        loadFoldersAction.execute(async () => {
            const foldersResult = await getWorkflowDefinitionFolders();
            setFolders(foldersResult);
        }, "Failed to load folders");
    }

    useEffect(() => {
        loadFolders();
    }, [])

    useEffect(() => {
        loadData();
    }, [selectedFolderId])

    // useEffect(() => {
    //     if (workflowDefinitions.length === 0) {
    //         return;
    //     }
    //     applySearchTerm(searchTerm);

    // }, [workflowDefinitions])

    const handleWorkflowChanged = (workflowDef) => {
        setWorkflowDefinitions(prev => {
            const wfIdx = prev.findIndex(wf => wf.id === workflowDef.id);

            if (wfIdx !== -1) {
                prev[wfIdx] = workflowDef;
            }

            return [...prev];
        })
    }

    const handleWorkflowDestroyed = (workflowDef) => {
        setWorkflowDefinitions(prev => {
            const filtered = prev.filter(x => x.id !== workflowDef.id);
            return [...filtered];
        })
    }

    const handleSearch = ({ value: searchBy }) => {
        setSearchTerm(searchBy);
        applySearchTerm(searchBy);
    }

    const applySearchTerm = (searchBy) => {
        tableActions.search(searchBy, "name");
    }

    const handleFolderDeleted = async () => {
        await loadFolders()
        setSelectedFolderId(systemFolderIds.home)
    }

    const handleFolderRenamed = async () => {
        await loadFolders()
    }

    const handleWorkflowMoveToFolderClicked = (workflowDef) => {
        setShowFolderModal(true);
        setWorkflowToMoveFolder(workflowDef);
    }

    const handleMoveToFolder = async (folderId) => {
        setShowFolderModal(false);

        moveToFolderAction.execute(async () => {
            await moveWorkflowDefinitionToFolder(workflowToMoveFolder.id, folderId);
            setSelectedFolderId(folderId);
        }, "Failed to move Workflow");
    }

    return (
        <FolderContext.Provider
            value={{
                selectedFolderId: selectedFolderId,
                setSelectedFolderId: setSelectedFolderId,
            }}
        >
            <div className="h-full">
                <div className="max-w-screen-2xl h-full mx-auto inset-0 py-6 px-4 sm:px-6 lg:px-8">
                    <div className="flex flex-row justify-between items-end">
                        <PageHeader
                            title="Workflows"
                            optionalSideElement={
                                <div className="flex gap-x-3">
                                    <div className="mt-3 sm:mt-0 sm:ml-4 w-96">
                                        <label htmlFor="desktop-search-candidate" className="sr-only">
                                            Search
                                        </label>
                                        <div className="flex rounded-md shadow-sm">
                                            <div className="relative flex-grow focus-within:z-10">
                                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                    <SearchIcon
                                                        className="h-5 w-5 text-gray-400"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                                <input
                                                    type="text"
                                                    name="desktop-search-candidate"
                                                    id="desktop-search-candidate"
                                                    className="hidden focus:ring-brand-pink focus:border-brand-pink w-full rounded-md pl-10 sm:block sm:text-sm border-gray-300"
                                                    placeholder="Search"
                                                    onChange={e => handleSearch(e.target)}
                                                    value={searchTerm}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <ButtonV2
                                    label={"Create new workflow"}
                                    type={CoreButtonTypes.cta}
                                    onClick={() => setShowNewDialog(true)}
                                    />
                                </div>
                            }
                        />
                    </div>
                    <div className="flex h-full gap-6 mt-6">
                        <FoldersSidebar
                            folders={folders}
                            onFolderCreated={loadFolders}
                            onFolderDeleted={handleFolderDeleted}
                            onFolderRenamed={handleFolderRenamed}
                        />
                        <div className="flex-1 h-full">
                            <div className="relative z-0">
                                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                        <div className="shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg">
                                            <table className="min-w-full divide-y divide-gray-200">
                                                <thead className="bg-gray-50">
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                                            onClick={() => tableActions.orderBy("name")}
                                                        >
                                                            Name {tableActions.getSortIcon("name")}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                                            onClick={() =>
                                                                tableActions.orderBy("workflowDefinitionState")
                                                            }
                                                        >
                                                            State {tableActions.getSortIcon("workflowDefinitionState")}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                                            onClick={() =>
                                                                tableActions.orderBy("createdDate")
                                                            }
                                                        >
                                                            Tags{" "}
                                                            {tableActions.getSortIcon("workflowTags")}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                                            onClick={() =>
                                                                tableActions.orderBy("createdDate")
                                                            }
                                                        >
                                                            Created{" "}
                                                            {tableActions.getSortIcon("createdDate")}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                                            onClick={() =>
                                                                tableActions.orderBy("updatedDate")
                                                            }
                                                        >
                                                            Updated{" "}
                                                            {tableActions.getSortIcon("updatedDate")}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="relative px-6 py-3"
                                                        >
                                                            <span className="sr-only">Edit</span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white divide-y divide-gray-200">
                                                    {tableActions?.pageData?.map((element) => (
                                                        <WorkflowTableRow key={element.id}
                                                            workflowDefinition={element}
                                                            onWorkflowChanged={wf => handleWorkflowChanged(wf)}
                                                            onWorkflowDestroyed={wf => handleWorkflowDestroyed(wf)}
                                                            onWorkflowMoveToFolderClicked={wf => handleWorkflowMoveToFolderClicked(wf)}
                                                        />
                                                    ))}
                                                </tbody>
                                            </table>
                                            <Table.Pagination tableActions={tableActions} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <NewWorkflow open={showNewDialog} setOpen={setShowNewDialog} />
                            <FolderModal
                                isOpen={showFolderModal}
                                onClose={setShowFolderModal}
                                folders={folders}
                                onMoveToFolder={handleMoveToFolder}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </FolderContext.Provider>
    )
}

const WorkflowTableRow = ({ workflowDefinition, onWorkflowChanged, onWorkflowDestroyed, onWorkflowMoveToFolderClicked }) => {
    const destroyAction = useToastAction();
    const activateAction = useToastAction();
    const { alertError, alertWarn } = useAlertModal();

    const handleDeactivateWorkflow = async (workflowDef) => {
        activateAction.execute(async () => {
            const updated = await deactivateWorkflowDefinition(workflowDef.id);
            onWorkflowChanged(updated)
        }, "Failed to disable")
    }

    const handleActivateWorkflow = async (workflowDef) => {
        const errors = workflowDef.errors.filter(e => e.type === "error");
        const warnings = workflowDef.errors.filter(e => e.type === "warning");
        if (errors.length > 0) {
            alertError("Workflow contains errors, please review workflow",
                () => { },
                false
            )
        } else if (warnings.length > 0) {
            alertWarn("Workflow contains warnings, still activate?", () => activateWorkflow(workflowDef), true, "Cancel", () => { }, "Activate");
        } else {
            activateWorkflow(workflowDef);
        }

    }
    const activateWorkflow = async (workflowDef) => {
        activateAction.execute(async () => {
            const updated = await activateWorkflowDefinition(workflowDef.id);
            onWorkflowChanged(updated);
        }, "Activate failed");
    }

    const handleDestroyWorkflow = async (workflowDef) => {
        destroyAction.execute(async () => {
            await destroyWorkflowDefinition(workflowDef.id);
            onWorkflowDestroyed(workflowDef);
        }, "Delete failed")
    }

    return (
        <tr>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-indigo-600 relative" style={{ minWidth: "250px" }}>
                <NavLink className={"cursor-pointer hover:underline"}
                    to={`/admin/workflow/editor/${workflowDefinition.id}`}
                >
                    {workflowDefinition.name}
                </NavLink>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {
                    (activateAction.isExecuting || destroyAction.isExecuting) ?
                        (<SpinnerBage />) :
                        (<WorkflowDefinitionStateBadge state={workflowDefinition.workflowDefinitionState} useShortText={true} />)
                }
            </td>
            <td className="px-6 py-4 whitespace-nowrap max-w-xs overflow-x-hidden text-sm text-gray-500">
                {
                    workflowDefinition.workflowTags.join(", ")
                }
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {stringToLocaleDateTimeString(
                    workflowDefinition.createdDate
                )}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {stringToLocaleDateTimeString(
                    workflowDefinition.updatedDate
                )}
            </td>
            <td className="p-2 whitespace-nowrap text-sm font-medium">
                <WorkflowListCtxMenu workflowDef={workflowDefinition}
                    onActivate={wf => handleActivateWorkflow(wf)}
                    onDeactivate={wf => handleDeactivateWorkflow(wf)}
                    onDestroy={wf => handleDestroyWorkflow(wf)}
                    onMoveToFolder={wf => onWorkflowMoveToFolderClicked(wf)}
                />
            </td>
        </tr>
    )
}
