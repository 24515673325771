import { Menu, Transition } from "@headlessui/react";
import { CollectionIcon, DotsVerticalIcon, FolderIcon, PencilIcon, PlayIcon, StopIcon, TrashIcon } from "@heroicons/react/outline";
import { useClassNames } from '@metaforcelabs/metaforce-core';
import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { workflowStates } from "../../../../utils/constants";

export const WorkflowListCtxMenu = ({ workflowDef, onActivate, onDeactivate, onDestroy, onMoveToFolder }) => {
    const { classNames } = useClassNames();
    return (
        <Menu as="div" className="inline-block text-left">
            <div>
                <Menu.Button className="text-gray-400 p-2 hover:text-gray-600">
                    <DotsVerticalIcon className="w-5 h-5" />
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute z-10 right-2 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-1 py-1 ">
                        <Menu.Item>
                            {({ active }) => (
                                <NavLink
                                    className={classNames("group flex rounded-md items-center w-full px-2 py-2 text-sm",
                                        active ? "text-brand-pink font-semibold" : "text-gray-700")}
                                    to={`/admin/workflow/editor/${workflowDef.id}`}
                                >
                                    <PencilIcon
                                        className="w-5 h-5 mr-2"
                                        aria-hidden="true"
                                    />
                                    Edit
                                </NavLink>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <NavLink
                                    className={classNames("group flex rounded-md items-center w-full px-2 py-2 text-sm",
                                        active ? "text-brand-pink font-semibold" : "text-gray-700")}
                                    to={`/admin/workflow/data/${workflowDef.id}`}
                                >
                                    <CollectionIcon
                                        className="w-5 h-5 mr-2"
                                        aria-hidden="true"
                                    />
                                    Data
                                </NavLink>
                            )}
                        </Menu.Item>
                    </div>
                    <div className="px-1 py-1">
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    className={classNames("group flex rounded-md items-center w-full px-2 py-2 text-sm",
                                        active ? "text-brand-pink font-semibold" : "text-gray-700")}
                                    onClick={e => {
                                        if (workflowDef.workflowDefinitionState === workflowStates.active) {
                                            onDeactivate(workflowDef);
                                        }
                                        if (workflowDef.workflowDefinitionState === workflowStates.inactive) {
                                            onActivate(workflowDef);
                                        }
                                    }}
                                >
                                    {workflowDef.workflowDefinitionState === workflowStates.inactive ? (
                                        <>
                                            <PlayIcon
                                                className="w-5 h-5 mr-2"
                                                aria-hidden="true"
                                            />
                                            Activate</>
                                    ) :
                                        (
                                            <>
                                                <StopIcon
                                                    className="w-5 h-5 mr-2"
                                                    aria-hidden="true"
                                                />
                                                Deactivate
                                            </>
                                        )}
                                </button>
                            )}
                        </Menu.Item>
                    </div>
                    <div className="px-1 py-1">
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    className={classNames("group flex rounded-md items-center w-full px-2 py-2 text-sm",
                                        active ? "text-brand-pink font-semibold" : "text-gray-700")}
                                    onClick={e => {
                                        onMoveToFolder(workflowDef)
                                    }}
                                >
                                    <FolderIcon
                                        className="w-5 h-5 mr-2"
                                        aria-hidden="true"
                                    />
                                    Move to Folder
                                </button>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    className={classNames("group flex rounded-md items-center w-full px-2 py-2 text-sm",
                                        active ? "text-brand-pink font-semibold" : "text-gray-700")}
                                    onClick={e => {
                                        onDestroy(workflowDef)
                                    }}
                                >
                                    <TrashIcon
                                        className="w-5 h-5 mr-2"
                                        aria-hidden="true"
                                    />
                                    Delete
                                </button>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}