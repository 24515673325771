import { useClassNames } from "@metaforcelabs/metaforce-core"
import { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { appConnectionType, routePath } from "../../../../../utils/constants";
import { OAuthAppConnectionContext } from "../../../../../contexts";

export default function ConnectionTabs({ children }) {
    const { pathname } = useLocation();
    const { classNames } = useClassNames();
    const { connections, workflowDefinitions, getAppConnectionType } = useContext(OAuthAppConnectionContext);

    const oAuthAppConnectionTabs = {
        [appConnectionType.microsoftOutlook]:
            [
                { index: 0, name: `Connection`, href: routePath.microsoftOutlook, component: children[0] },
                { index: 1, name: `Workflow`, href: routePath.microsoftOutlookWorkflows, component: children[1] },
            ],
        [appConnectionType.kivra]:
            [
                { index: 0, name: `Connection`, href: routePath.kivra, component: children[0] },
                { index: 1, name: `Workflow`, href: routePath.kivraWorkflows, component: children[1] },
            ],
        [appConnectionType.digipost]:
            [
                { index: 0, name: `Connection`, href: routePath.digipost, component: children[0] },
                { index: 1, name: `Workflow`, href: routePath.digipostWorkflows, component: children[1] },
            ],
        [appConnectionType.sftp]:
            [
                { index: 0, name: `Connection`, href: routePath.sftp, component: children[0] },
                { index: 1, name: `Workflow`, href: routePath.sftpWorkflows, component: children[1] },
            ],
    }

    const [tabs, setTabs] = useState();
    const [currentTab, setCurrentTab] = useState();

    useEffect(() => {
        let newTabs;
        const connectionType = getAppConnectionType();
        if (connectionType) {
            newTabs = oAuthAppConnectionTabs[connectionType];
            setTabs(newTabs);
        }

        if (pathname.includes("workflows")) {
            setCurrentTab(newTabs[1]);
        } else {
            setCurrentTab(newTabs[0]);
        }
    }, [pathname])

    const getCount = (index) => {
        if (index === 0) {
            return connections.length;
        } else {
            return workflowDefinitions.length;
        }
    }

    return (
        <div>
            <div className="hidden sm:block">
                <div className="border-b border-gray-200">
                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                        {tabs?.map((tab) => (
                            <NavLink
                                key={tab.index}
                                className={classNames(
                                    tab.index === currentTab?.index
                                        ? 'border-indigo-500 text-indigo-600'
                                        : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                                    'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                                )}
                                to={tab.href}
                            >
                                {tab.name}
                                <span
                                    className={classNames(
                                        tab.index === currentTab?.index ? 'bg-indigo-100 text-indigo-600' : 'bg-gray-100 text-gray-900',
                                        'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                                    )}
                                >
                                    {getCount(tab.index)}
                                </span>

                            </NavLink>
                        ))}
                    </nav>
                </div>
            </div>
            {tabs?.map((tab, index) => {
                return (
                    <div key={index} style={{ display: `${tab.index === currentTab?.index ? "block" : "none"}` }}>
                        {currentTab?.component}
                    </div>
                )
            })}
        </div>
    )
}
