import { CoreButton, Table } from "@metaforcelabs/metaforce-core";
import { OAuthAppConnectionContext } from "../../../../../contexts";
import { useContext, useEffect, useState } from "react";
import { OAuthAppConnectionCtxMenu } from "./oAuthAppConnectionCtxMenu";
import EditOAuthAppConnectionDialog from "./editOAuthAppConnectionDialog";
import { SpinnerBage } from "../../../../../components/Badge/spinnerBadge";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/outline";
import { oAuthConnectionTestComponentType } from "../../../../../utils/constants";
import DeleteOAuthAppConnectionDialog from "./deleteOAuthAppConnectionDialog";
import { stringToLocaleDateString, stringToLocaleTimeString } from "../../../../../utils/date";

export default function OAuthAppConnectionComponent() {
    const { connections, connectionsTableActions, loadData, handleTestConnections, handleReconnect } = useContext(OAuthAppConnectionContext);
    const [showEditConnectionDialog, setShowEditConnectionDialog] = useState(false);
    const [connectionToEdit, setConnectionToEdit] = useState();
    const [showDeleteConnectionDialog, setShowDeleteConnectionDialog] = useState(false);
    const [connectionToDelete, setConnectionToDelete] = useState();

    const handleConnectionEdit = async (connection) => {
        setShowEditConnectionDialog(true);
        setConnectionToEdit(connection);
    }

    const handleTestConnection = async (connection) => {
        await handleTestConnections([connection]);
    }

    const handleConnectionDelete = (connection) => {
        setShowDeleteConnectionDialog(true);
        setConnectionToDelete(connection);
    }

    return (
        <div>
            <div className="mt-6 text-xl font-medium">
                <span className="align-center">Connections</span>
                <span className="ml-2 bg-indigo-100 text-indigo-600 rounded-full py-0.5 px-2.5 text-base font-medium md:inline-block">
                    {connections?.length}
                </span>
            </div>
            <OAuthAppConnectionTable
                tableActions={connectionsTableActions}
                onReconnect={handleReconnect}
                onEdit={handleConnectionEdit}
                onTest={handleTestConnection}
                onDelete={handleConnectionDelete}
            />

            <EditOAuthAppConnectionDialog
                open={showEditConnectionDialog}
                setOpen={async (value) => {
                    setShowEditConnectionDialog(value);
                    if (!value) {
                        await loadData();
                        setConnectionToEdit(null);
                    }
                }}
                connection={connectionToEdit}
            />
            <DeleteOAuthAppConnectionDialog
                open={showDeleteConnectionDialog}
                setOpen={async (value) => {
                    setShowDeleteConnectionDialog(value);
                    if (!value) {
                        await loadData();
                        setConnectionToDelete(null);
                    }
                }}
                connection={connectionToDelete}
            />
        </div>
    )
}

const OAuthAppConnectionTable = ({ tableActions, onReconnect, onEdit, onTest, onDelete }) => {
    return (
        <div className="relative z-0 mt-4">
            <div className="-my-2 overflow-x-auto">
                <div className="py-2 align-middle inline-block min-w-full">
                    <div className="shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="pl-6 pr-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                        onClick={() => tableActions.orderBy("name")}
                                    >
                                        Name {tableActions.getSortIcon("name")}
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                    >
                                        <span className="sr-only">Share</span>
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                        onClick={() =>
                                            tableActions.orderBy("createdDate")
                                        }
                                    >
                                        Created{" "}
                                        {tableActions.getSortIcon("createdDate")}
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                        onClick={() =>
                                            tableActions.orderBy("createdBy")
                                        }
                                    >
                                        CreatedBy{" "}
                                        {tableActions.getSortIcon("createdBy")}
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                        onClick={() =>
                                            tableActions.orderBy("updatedDate")
                                        }
                                    >
                                        Updated{" "}
                                        {tableActions.getSortIcon("updatedDate")}
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                        onClick={() =>
                                            tableActions.orderBy("updatedBy")
                                        }
                                    >
                                        UpdatedBy{" "}
                                        {tableActions.getSortIcon("updatedBy")}
                                    </th>
                                    <th
                                        scope="col"
                                        className="relative px-6 py-2 text-right"
                                    >
                                        <span className="sr-only">Edit</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {tableActions?.pageData?.map((connection, index) => (
                                    <tr key={index}>
                                        <td className={(connection.isValid ? "text-indigo-600" : "text-brand-pink") + " pl-6 pr-2 py-4 text-sm font-medium relative"}
                                            style={{ minWidth: "250px" }}>
                                            {connection.name}
                                        </td>
                                        <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-500">
                                            <TestOrReconnectComponent
                                                connection={connection}
                                                onReconnect={onReconnect}
                                            />
                                        </td>
                                        <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-500">
                                            <div>{stringToLocaleDateString(connection.createdDate)}</div>
                                            <div>{stringToLocaleTimeString(connection.createdDate)}</div>
                                        </td>
                                        <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {connection.createdByEmail}
                                        </td>
                                        <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-500">
                                            <div>{stringToLocaleDateString(connection.updatedDate)}</div>
                                            <div>{stringToLocaleTimeString(connection.updatedDate)}</div>
                                        </td>
                                        <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {connection.updatedByEmail}
                                        </td>
                                        <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-right">
                                            <OAuthAppConnectionCtxMenu
                                                connection={connection}
                                                onEdit={onEdit}
                                                onReconnect={onReconnect}
                                                onTest={onTest}
                                                onDestroy={onDelete}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <Table.Pagination tableActions={tableActions} />
                    </div>
                </div>
            </div>
        </div>
    )
}

const TestOrReconnectComponent = ({ connection, onReconnect }) => {
    const { connectionsToTestRef } = useContext(OAuthAppConnectionContext);
    const [componentType, setComponentType] = useState(false);

    useEffect(() => {
        if (connection.id in connectionsToTestRef.current) {
            setComponentType(connectionsToTestRef.current[connection.id]);
        }
    }, [connectionsToTestRef.current][connection.id]);

    if (componentType === oAuthConnectionTestComponentType.testing) {
        return (
            <SpinnerBage text="Testing"></SpinnerBage>
        );
    } else if (componentType === oAuthConnectionTestComponentType.testSuccessful) {
        return (
            <span className="text-green-500 font-medium inline-flex items-center gap-x-2">
                <CheckCircleIcon className="h-5 w-5"></CheckCircleIcon>
                Test successful
            </span>
        )
    } else if (componentType === oAuthConnectionTestComponentType.testFailed) {
        return (
            <span className="text-red-500 font-medium inline-flex items-center gap-x-2">
                <XCircleIcon className="h-5 w-5"></XCircleIcon>
                Test failed
            </span>
        )
    } else if (componentType === oAuthConnectionTestComponentType.reconnect) {
        return (
            <CoreButton
                label={<>Reconnect App</>}
                onClick={() => onReconnect(connection)}
            />
        )
    } else {
        return <></>
    }
}