import React, { useContext, useEffect, useState } from 'react'
import { NodeItem } from './Components/nodeItem';
import { WorkflowStepContext } from '../../../../../contexts';
import { Checkbox } from '../../../../../components/Form/Checkbox';

export const CheckboxField = ({ workflowStepValues, setupActionStep, onStepValueChange, apiParameters, workflowStep }) => {

    const value = workflowStepValues[setupActionStep.property]?.value

    const checked = value === undefined ? setupActionStep.defaultValue : (/true/).test(value);

    const handleChange = (checked) => {
        onStepValueChange(setupActionStep.property, { value: '' + checked });
    }


    useEffect(() => {
        handleChange(checked)
    }, [])
    

    return (
        <div className='mt-4'>
            <div className=''>
                <h4 className="font-semibold">{setupActionStep?.name}:</h4>
                <Checkbox
                    inputClassName='mt-2 h-5 w-5'
                    checked={checked}
                    id={setupActionStep.property}
                    onChange={(checked, name) => handleChange(checked)}
                />
            </div>

            <div className='text-gray-500 text-sm mt-1'>
                {setupActionStep?.helperText}
            </div>
        </div>
    )
}
export const InputField = ({ workflowStepValues, setupActionStep, onStepValueChange, apiParameters, workflowStep }) => {
    const [nodeItem, setNodeItem] = useState({ id: setupActionStep.property })
    const stepContext = useContext(WorkflowStepContext);
    const [previousSteps, setPreviousSteps] = useState(stepContext.previousSteps);
    const [previousStepsValues, setPreviousStepsValues] = useState(stepContext.previousStepsValues);

    useEffect(() => {
        if (setupActionStep.dataSourceId) {
            const preSteps = stepContext.previousSteps.filter(step => step.selectedEvent?.key === setupActionStep.dataSourceId);
            const preStepsValues = stepContext.previousStepsValues.filter((x, idx) => stepContext.previousSteps[idx]?.selectedEvent?.key === setupActionStep.dataSourceId);
            setPreviousSteps(preSteps);
            setPreviousStepsValues(preStepsValues);
        } else {
            setPreviousSteps(stepContext.previousSteps);
            setPreviousStepsValues(stepContext.previousStepsValues);
        }
    }, []);

    const handleNodeValueChange = (nodeId, value) => {
        onStepValueChange(setupActionStep.property, { value });
    }

    return (
        <div className='mt-4'>
            <h4 className="font-semibold">{setupActionStep?.name}{
                setupActionStep?.required ? "*" : ""
            }:</h4>
            <NodeItem
                item={nodeItem}
                nodeValue={workflowStepValues[setupActionStep.property]?.value === undefined ? setupActionStep.defaultValue : workflowStepValues[setupActionStep.property]?.value}
                handleNodeValueChange={handleNodeValueChange}
                previousSteps={previousSteps}
                stepIdx={stepContext.stepIdx}
                workflowStep={workflowStep}
                previousStepsValues={previousStepsValues}
                elementHeight={setupActionStep?.elementHeight}
            />
            <div className='text-gray-500 text-sm mt-1'>
                {setupActionStep?.helperText}
            </div>
        </div>
    )
}
