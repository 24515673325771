
export const config = {
  apiBaseUrl: window._env_.REACT_APP_API_BASE_URL,
  tokenStorageKey: '@MetaForce:userToken',
  identityBaseUrl: window._env_.REACT_APP_IDENTITY_BASE_URL,
  centerpointBaseUrl: window._env_.REACT_APP_CENTERPOINT_BASE_URL,
  centerpointUiBaseUrl: window._env_.REACT_APP_CENTERPOINT_UI_BASE_URL,
  workflowBaseUrl: window._env_.REACT_APP_WORKFLOW_BASE_URL,
  pagesManagerBaseUrl: window._env_.REACT_APP_PAGESMANAGER_API_BASE_URL,
  archiveBaseUrl: window._env_.REACT_APP_ARCHIVE_API_BASE_URL,
  productLicense: '61de8cb5e123ded81bd7102e',
  workflowUsageFeatureCode: 'WFA',
  docsUrl: window._env_.REACT_APP_DOCUMENTATION_URL,
}

export const productCodesConfig = {
  archive: 'DG',
  editor: 'WE',
  smartform: 'DA',
  viewpoint: 'VP',
}

export const features = {
  JSONStepEnabled: window._env_.REACT_APP_JSONSTEPENABLED === 'true',
}

export const oidcConfig = {
  client_id: 'workflow_web',
  redirect_uri: window._env_.REACT_APP_REDIRECT_URI,
  response_type: 'code',
  post_logout_redirect_uri: window._env_.REACT_APP_POST_LOGOUT_REDIRECT_URI,
  scope: 'openid profile email api.internal offline_access',
  authority: window._env_.REACT_APP_AUTHORITY,
  silent_redirect_uri: window._env_.REACT_APP_SILENT_REDIRECT_URI,
  automaticSilentRenew: true,
  loadUserInfo: true,
};

export const msalConfig = {
  auth: {
    clientId: window._env_.REACT_APP_MICROSOFT_IDENTITY_CLIENTID,
    // authority: `${window._env_.REACT_APP_MICROSOFT_IDENTITY_AUTHORITY_BASE_ADDRESS}/${window._env_.REACT_APP_MICROSOFT_IDENTITY_TENANTID}`,
    // För att appen skall stödja auth från andra tenants än Metaforce
    authority: `${window._env_.REACT_APP_MICROSOFT_IDENTITY_AUTHORITY_BASE_ADDRESS}/common`,
    scopes: 'offline_access user.read openid email Mail.Send Mail.Send.Shared',
    redirectUri: window._env_.REACT_APP_MICROSOFT_IDENTITY_REDIRECT_URI,
  }
}