
export default function KivraIcon({ className }) {
    return (
        <svg version="1.1" viewBox="0 0 45 50" className={className} style={{ backgroundColor: "#8cc646" }}>
            <g >
                <path 
                style={{ fill: "#ffffff" }} 
                d="M28.31 28.4787L24.67 20.6297L36.2 7.554565H28.96L15.995 22.5782V7.554565H10V39.8201H15.995V30.4772L20.46 25.416L23.24 31.3815C25.64 36.4976 28.915 40 34.08 40C34.91 40 35.925 39.9101 36.665 39.7252V34.5641C36.665 34.5641 36.02 34.704 35.145 34.704C31.865 34.699 30.25 32.6705 28.31 28.4787Z"
                >
                </path>
            </g>
        </svg>


    )
}