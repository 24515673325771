export const isHomeFolder = (folderId) => {
  return folderId === systemFolderIds.home
}

export const isTrashFolder = (folderId) => {
  return folderId === systemFolderIds.trash
}

export const systemFolderIds = {
  home: "-1",
  trash: "inTrash",
}