import { get, post, destroy, BaseUrl, put, buildStatusCodeHandler } from './api';

const controller = "WorkflowOAuthAppConnections";

export const getOAuthAppConnectionsCount = async () => {
    return await get(`${controller}`, null, BaseUrl.WorkFlow);
}

export const getOAuthAppConnections = async (type) => {
    return await get(`${controller}/type/${type}`, null, BaseUrl.WorkFlow);
}

export const getOAuthAppWorkflowDefintions = async (id) => {
    return await get(`${controller}/workflowDefinitions/${id}`, null, BaseUrl.WorkFlow);
}

export const getOAuthAppConnection = async (id, notFoundHandler) => {
    return await get(`${controller}/${id}`, buildStatusCodeHandler(404, notFoundHandler), BaseUrl.WorkFlow);
}

export const createOAuthAppConnection = async (connection) => {
    return await post(controller, connection, null, BaseUrl.WorkFlow);
}

export const updateOAuthAppConnection = async (connection) => {
    return await put(`${controller}/update`, connection, null, BaseUrl.WorkFlow);
}

export const destroyOAuthAppConnection = async (id) => {
    return await destroy(`${controller}/${id}`, null, BaseUrl.WorkFlow);
}

export const testOAuthAppConnections = async (data) => {
    return await post(`${controller}/test`, data, null, BaseUrl.WorkFlow);
}

export const updateOAuthAppConnectionWorkflowIds = async (data) => {
    return await post(`${controller}/updateWorkflowIds`, data, null, BaseUrl.WorkFlow);
}