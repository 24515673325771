import { get, post, BaseUrl, put, destroy } from './api';

const controller = "WorkflowValues";

export const updateWorkflowValues = async (workflowId, workflowValues) => {
    return await put(controller, { workflowDefinitionId: workflowId, values: workflowValues }, null, BaseUrl.WorkFlow);
}

export const destroyWorkflowValues = async (workflowValuesId) => {
    return await destroy(`${controller}/${workflowValuesId}`, null, BaseUrl.WorkFlow);
}