import React, { useContext, useEffect, useState } from 'react'
import { WorkflowStepContext } from '../../../../../contexts';
import { NodeItem } from './Components/nodeItem';
import { v4 as uuidv4 } from 'uuid';
import { PlusIcon, XIcon } from '@heroicons/react/outline';
import { useClassNames } from '@metaforcelabs/metaforce-core';

export const InputDictionary = ({ workflowStepValues, setupActionStep, onStepValueChange, apiParameters, workflowStep }) => {
    const { classNames } = useClassNames();
    const [payLoadValues, setPayLoadValues] = useState([]);
    const stepContext = useContext(WorkflowStepContext);

    const handleParamsNodeChange = (nodeId, value) => {
        const idx = payLoadValues.findIndex(x => x.id === nodeId);
        if (idx === -1) {
            return;
        }

        if (payLoadValues[idx].value === value) {
            return;
        }

        payLoadValues[idx] = { ...payLoadValues[idx], value: value };

        return setPayLoadValues(prev => [...payLoadValues]);
    }

    const handleParamsKeyChange = (payloadItem, value) => {
        setPayLoadValues(prev => {
            const idx = prev.findIndex(x => x.id === payloadItem.id);

            prev[idx] = { ...prev[idx], key: value };

            return [...prev];
        })
    }

    // const payLoadValueToKvP = (payloadValue) => {
    //     return { [payloadValue.id]: payloadValue.value };
    // }

    const handleRemovePayloadValue = (payloadValue) => {
        setPayLoadValues(prev => ([...prev.filter(x => x.id !== payloadValue.id)]))
    }

    const handleAddPayloadValue = () => {
        const added = [...payLoadValues, { id: uuidv4(), key: '', value: '' }]
        setPayLoadValues(prev => added)
    }

    useEffect(() => {
        const stepValue = workflowStepValues[setupActionStep.property]
        let currValue;
        if (stepValue) {
            currValue = Object.keys(stepValue).map(k => ({ id: uuidv4(), key: k, value: stepValue[k] }))
        } else {
            currValue = [{ id: uuidv4(), key: '', value: '' }, { id: uuidv4(), key: '', value: '' }]
        }

        setPayLoadValues(currValue);
    }, []);

    useEffect(() => {

        if (payLoadValues.length === 0) {
            return;
        }

        const asKeyValue = payLoadValues.reduce((prev, curr, i) => {
            return { ...prev, [curr.key]: curr.value }
        }, {});
        const json = JSON.stringify(asKeyValue);
        const curr = workflowStepValues[setupActionStep.property]
        if (json !== JSON.stringify(curr)) {
            onStepValueChange(setupActionStep.property, asKeyValue)
        }

    }, [payLoadValues])

    return (
        <div className='mt-4'>
            <h4 className="font-semibold">{setupActionStep?.name}:</h4>
            {payLoadValues.map((p, i) => (
                <div key={p.id} className={classNames('grid grid-cols-12 space-x-2', i > 0 ? "mt-2" : "")}>
                    <div className='col-span-4'>
                        <input type="text" className='min-w-0 w-full rounded-lg bg-gray-100 hover:shadow-sm focus:border-indigo-500 focus:border-sm cursor-text px-4 py-2 border-none'
                            placeholder='Key'
                            value={p.key}
                            onChange={e => { handleParamsKeyChange(p, e.target.value) }} />
                    </div>
                    <div className='col-span-7'>
                        <NodeItem
                            item={p}
                            nodeValue={p.value}
                            placeHolder={"Value"}
                            handleNodeValueChange={(nodeId, value) => handleParamsNodeChange(nodeId, value)}
                            previousSteps={stepContext.previousSteps}
                            stepIdx={stepContext}
                            workflowStep={workflowStep}
                            previousStepsValues={stepContext.previousStepsValues} />
                    </div>
                    <div className='col-span-1 p-1 flex justify-center'>
                        <div className={classNames("text-lg h-full w-8 text-gray-500 p-1 border border-solid border-gray-400 rounded-lg",
                            i === 0 ? "opacity-0" : "cursor-pointer hover:shadow hover:text-red-600"
                        )} title="Remove"
                            onClick={e => {
                                if (i === 0) {
                                    return;
                                }
                                handleRemovePayloadValue(p);
                            }}
                        >
                            <XIcon />
                        </div>
                    </div>
                </div>
            ))}
            <div className='mt-1 text-gray-500 text-sm'>
                {setupActionStep?.helperText}
            </div>
            <div className="mt-2 grid grid-cols-12">
                <div className='col-span-1 p-1'>
                    <div className={classNames("text-lg h-full w-8 text-gray-500 p-1 border border-solid border-gray-400 rounded-lg",
                        "cursor-pointer hover:shadow hover:text-green-600"
                    )} title="Add"
                        onClick={e => {
                            handleAddPayloadValue();
                        }}
                    >
                        <PlusIcon />
                    </div>
                </div>
            </div>
            {/* <div className='text-gray-500 text-sm mt-1'>
                {setupActionStep?.helperText}
            </div> */}
        </div>
    )
}
