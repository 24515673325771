import React, { useContext, useState } from 'react'
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors, DragOverlay } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { WorkflowContext } from '../../../../contexts';
import WorkFlowStep from '../Components/workFlowStep';
import { useWorkflowStep } from '../../../../hooks/useWorkflowStep';

export const DndContextWrapper = ({ children, sortableItems }) => {
    const [activeWorkflowStep, setActiveWorkflowStep] = useState(null);
    const { workFlow: workflow, handleDragEnd } = useContext(WorkflowContext);
    const { getStepIndexInEditor } = useWorkflowStep();

    /**
     * https://github.com/clauderic/dnd-kit/issues/591
     * Put a minimum drag distance to prevent an accidental tiny drag from activating the drag event and preventing
     * any other click events
     */
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 4,
            },
        }),
    );

    const handleDragStart = (event) => {
        const { active } = event;

        const activeStep = workflow.workflowSteps?.find(ws => ws.id === active.id);
        const indexInEditor = getStepIndexInEditor(workflow, active.id);
        if (activeStep && indexInEditor) {
            setActiveWorkflowStep({ index: indexInEditor, step: activeStep });
        }
    }

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={(event) => {
                handleDragEnd(event);
                setActiveWorkflowStep(null);
            }}
        >
            <SortableContext
                items={sortableItems || []}
                strategy={verticalListSortingStrategy}
            >
                {children}
            </SortableContext>
            <DragOverlay>
                {activeWorkflowStep ?
                    <WorkFlowStep
                        workflowStep={activeWorkflowStep.step}
                        stepCount={workflow.workflowSteps.filter(ws => !ws.branchId).length}
                        stepIdx={activeWorkflowStep.index}
                        activeStep={false}
                        isDragOverlayComponent={true} /> : null}
            </DragOverlay>
        </DndContext>
    )
}