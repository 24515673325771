import { useEffect, useState } from "react";
import React from "react";
import { Formik } from "formik";
import * as Yup from 'yup';
import { useToastAction } from "@metaforcelabs/metaforce-core";
import { createOAuthAppConnection, getOAuthAppConnections, updateOAuthAppConnection } from "../../../../../api/workflowOAuthAppConnection";
import { appConnectionType, digipostEnvironments } from "../../../../../utils/constants";
import toast from "react-hot-toast";
import Modal from "components/Modals/modal";
import { Select } from "components/Form/Select";
import { Password } from "components/Password";
import { Button } from "components/Form/Button";

const EditDigipostConnectionSchema = Yup.object().shape({
    digipostSenderId: Yup.object().test('digipostSenderId', 'digipostSenderId', (value, context) => {
        if (value.hasValue) {
            return true;
        } else {
            if (!value.value) {
                return new Yup.ValidationError(
                    'Required',
                    null,
                    'digipostSenderId'
                );
            }
            if (isNaN(value.value)) {
                return new Yup.ValidationError(
                    'Invalid number',
                    null,
                    'digipostSenderId'
                );
            }
        }
        return true;
    }),
});

export default function EditDigipostConnectionDialog({ isOpen, onClose, connection }) {
    const [initialValues, setInitialValues] = useState({});
    const [connections, setConnections] = useState([]);
    const loadAction = useToastAction();
    const saveAction = useToastAction();
    const dummyPassword = "•••";

    const loadData = async () => {
        loadAction.execute(async () => {
            const result = await getOAuthAppConnections(appConnectionType.digipost);
            setConnections(result.connections);
        }, "Failed to load data");
    }

    useEffect(() => {
        if (isOpen) {
            loadData();
        }
    }, [isOpen])

    useEffect(() => {
        if (connection) {
            setInitialValues(connection);
        } else {
            setInitialValues({
                digipostEnvironment: digipostEnvironments[0].value,
                digipostSenderId: {
                    value: '',
                    hasValue: false,
                    valueChanged: false,
                },
            });
        }
    }, [connection])

    const handleClose = () => {
        onClose();
    };

    const handleSubmitForm = async (values) => {
        saveAction.execute(async () => {
            connection = values;
            connection.appConnectionType = appConnectionType.digipost;

            if (connection.id) {
                await updateOAuthAppConnection(connection);
            } else {
                await createOAuthAppConnection(connection);
            }

            handleClose();
        }, "Failed to save Digipost connection");
    }

    return (
        <Modal
            size={"medium"}
            title={"Digipost connection"}
            isOpen={isOpen}
            onClose={onClose}
        >
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={EditDigipostConnectionSchema}
                onSubmit={async (values, { setSubmitting, setErrors }) => {
                    let foundConnection = connections.filter(x => x.id !== connection?.id).find(x => x.digipostEnvironment === values.digipostEnvironment);
                    if (foundConnection) {
                        const foundEnvironment = digipostEnvironments.find(x => x.value === values.digipostEnvironment);
                        toast.error(`It can not create Digipost connection. Environment ${foundEnvironment?.name} already exists.`);
                        setErrors({
                            digipostEnvironment: 'Already exists',
                        });
                        return;
                    }

                    await handleSubmitForm(values);
                    setSubmitting(false);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="mt-3">
                            <Select
                                id="digipostEnvironment"
                                name="digipostEnvironment"
                                label="Environment"
                                options={digipostEnvironments}
                                selectedValue={values.digipostEnvironment}
                                onChange={(v, name) => {
                                    handleChange({
                                        target: {
                                            name: name,
                                            value: Number(v),
                                        }
                                    });
                                }}
                            />
                            <div className="space-y-1 mt-5">
                                <Password
                                    label="Sender ID"
                                    name="digipostSenderId"
                                    value={values?.digipostSenderId?.value}
                                    onChange={e => {
                                        setFieldValue("digipostSenderId", {
                                            hasValue: false,
                                            value: e.target.value,
                                            valueChanged: true,
                                        });
                                    }}
                                    showEyeButton={values?.digipostSenderId?.valueChanged === true || false}
                                    placeHolder={values?.digipostSenderId?.hasValue === true ? dummyPassword : ''}
                                    error={errors.digipostSenderId}
                                />
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-6 flex sm:block">
                            <div className='flex space-x-4 justify-end'>
                                <Button onClick={() => { handleClose() }} text={"Cancel"} theme='white' />
                                <Button onClick={() => { handleSubmit() }} text={"Save"} />
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </Modal>

    );
}


