import { ContentWrapper, PageHeader, authenticateCodePkce, useTableActions, useToastAction } from "@metaforcelabs/metaforce-core";
import { useState, useEffect, useRef } from "react";
import { createOAuthAppConnection, getOAuthAppWorkflowDefintions, getOAuthAppConnections, testOAuthAppConnections, updateOAuthAppConnection } from "../../../../api/workflowOAuthAppConnection";
import { OAuthAppConnectionContext } from "../../../../contexts";
import ConnectionTabs from "../WorkflowOAuthAppConnections/Components/connectionTabs";
import OAuthAppConnectionComponent from "./Components/oAuthAppConnectionComponent";
import { msalConfig } from "../../../../utils/config";
import OAuthAppWorkflowDefinitionComponent from "../WorkflowOAuthAppConnections/Components/oAuthAppWorkflowDefinitionComponent";
import { appConnectionType, oAuthAppConnections, oAuthConnectionTestComponentType, routePath } from "../../../../utils/constants";
import { useLocation, useParams } from "react-router-dom";
import { SearchIcon } from "@heroicons/react/outline";
import EditKivraConnectionDialog from "./Components/editKivraConnectionDialog";
import EditDigipostConnectionDialog from "./Components/editDigipostConnectionDialog";
import EditSftpConnectionDialog from "./Components/editSftpConnectionDialog";

export default function WorkflowOAuthAppConnections() {
    const { pathname } = useLocation();
    const { connectionId } = useParams();
    const [connections, setConnections] = useState([]);
    const [workflowDefinitions, setWorkflowDefinitions] = useState([]);
    const [connectionToViewWorkflows, setConnectionToViewWorkflows] = useState();
    const connectionsToTestRef = useRef({});
    const [searchTerm, setSearchTerm] = useState();
    const [showEditKivraDialog, setShowEditKivraDialog] = useState(false);
    const [kivraConnectionToEdit, setKivraConnectionToEdit] = useState();
    const [showEditDigipostDialog, setShowEditDigipostDialog] = useState(false);
    const [digipostConnectionToEdit, setDigipostConnectionToEdit] = useState();
    const [showEditSftpDialog, setShowEditSftpDialog] = useState(false);
    const [sftpConnectionToEdit, setSftpConnectionToEdit] = useState();
    const connectionsTableActions = useTableActions(connections, 10, "createdDate", "desc");
    const workflowTableActions = useTableActions(workflowDefinitions, 10, "createdDate", "desc");

    const loadAction = useToastAction();
    const testAction = useToastAction();

    const getAppConnectionType = () => {
        if (pathname.includes('microsoft-outlook'))
            return appConnectionType.microsoftOutlook;
        else if (pathname.includes('kivra'))
            return appConnectionType.kivra;
        else if (pathname.includes('digipost'))
            return appConnectionType.digipost;
        else if (pathname.includes('sftp'))
            return appConnectionType.sftp;
        return undefined;
    }

    const getOAuthConfig = () => {
        const connectionType = getAppConnectionType();
        if (connectionType === appConnectionType.microsoftOutlook)
            return msalConfig;
        return undefined;
    }

    const getOAuthAppName = () => {
        const connectionType = getAppConnectionType();
        const foundConnection = oAuthAppConnections.find(x => x.value === connectionType);
        if (foundConnection) {
            return foundConnection.name;
        }
        return undefined;
    }

    const getViewWorkflowLink = (connectionId) => {
        const connectionType = getAppConnectionType();
        if (connectionType === appConnectionType.microsoftOutlook)
            return `${routePath.microsoftOutlookWorkflows}/${connectionId}`;
        else if (connectionType === appConnectionType.kivra)
            return `${routePath.kivraWorkflows}/${connectionId}`;
        else if (connectionType === appConnectionType.digipost)
            return `${routePath.digipostWorkflows}/${connectionId}`;
        else if (connectionType === appConnectionType.sftp)
            return `${routePath.sftpWorkflows}/${connectionId}`;
        return undefined;
    }

    const loadData = async () => {
        loadAction.execute(async () => {
            const [responseModel, workflowDefinitionsResult] = await Promise.all([
                getOAuthAppConnections(getAppConnectionType()),
                (pathname.includes("workflows") && connectionId) ? getOAuthAppWorkflowDefintions(connectionId) : () => { },
            ]);

            setConnections(responseModel.connections);

            if ((pathname.includes("workflows") && connectionId)) {
                setWorkflowDefinitions(workflowDefinitionsResult);
            } else {
                setWorkflowDefinitions(responseModel.workflowDefinitions);
            }

            const foundConnectionToViewWorkflows = responseModel.connections.find(c => c.id === connectionId);
            if (foundConnectionToViewWorkflows) {
                setConnectionToViewWorkflows(foundConnectionToViewWorkflows);
            } else {
                setConnectionToViewWorkflows(null);
            }

            responseModel.connections.forEach((connection) => {
                if (!connection.isValid) {
                    connectionsToTestRef.current[connection.id] = oAuthConnectionTestComponentType.reconnect;
                } else {
                    connectionsToTestRef.current[connection.id] = oAuthConnectionTestComponentType.none;
                }
            });
        }, "Failed to load connections");
    }

    useEffect(() => {
        loadData();
    }, [pathname])

    const handleCreateConnection = async () => {
        const connectionType = getAppConnectionType();

        switch (connectionType) {
            case appConnectionType.microsoftOutlook:
                const codeResponse = await authenticateCodePkce(getOAuthConfig());
                const createRequest = {
                    appConnectionType: getAppConnectionType(),
                    authorizationCode: codeResponse.authorizationCode,
                    codeVerifier: codeResponse.codeVerifier,
                };
                await createOAuthAppConnection(createRequest);
                await loadData();
                break;
            case appConnectionType.kivra:
                setShowEditKivraDialog(true);
                break;
            case appConnectionType.digipost:
                setShowEditDigipostDialog(true);
                break;
            case appConnectionType.sftp:
                setShowEditSftpDialog(true);
                break;
            default:
                break;
        }
    }

    const handleReconnect = async (connection) => {
        const connectionType = getAppConnectionType();

        switch (connectionType) {
            case appConnectionType.microsoftOutlook:
                const codeResponse = await authenticateCodePkce(getOAuthConfig());
                await updateOAuthAppConnection({
                    ...connection,
                    authorizationCode: codeResponse.authorizationCode,
                    codeVerifier: codeResponse.codeVerifier,
                });
                await loadData();
                break;
            case appConnectionType.kivra:
                setShowEditKivraDialog(true);
                setKivraConnectionToEdit(connection);
                break;
            case appConnectionType.digipost:
                setShowEditDigipostDialog(true);
                setDigipostConnectionToEdit(connection);
                break;
            case appConnectionType.sftp:
                setShowEditSftpDialog(true);
                setSftpConnectionToEdit(connection);
                break;
            default:
                break;
        }
    }

    const handleTestConnections = async (connecttionsToTest) => {
        testAction.execute(async () => {
            connecttionsToTest.forEach(connection => {
                connectionsToTestRef.current[connection.id] = oAuthConnectionTestComponentType.testing;
            });

            const result = await testOAuthAppConnections({
                connectionIds: connecttionsToTest.map(x => x.id),
            });

            if (result.testResult) {
                for (const [key, value] of Object.entries(result.testResult)) {
                    connectionsToTestRef.current[key] = value ? oAuthConnectionTestComponentType.testSuccessful : oAuthConnectionTestComponentType.testFailed;
                }

                const testResultInterval = setInterval(async () => {
                    for (const [key, value] of Object.entries(result.testResult)) {
                        connectionsToTestRef.current[key] = oAuthConnectionTestComponentType.none;
                    }
                    await loadData();
                    clearInterval(testResultInterval);
                }, 1000);
            }
        }, "Failed to test connections");
    }

    const handleSearch = ({ value: searchBy }) => {
        setSearchTerm(searchBy);
        applySearchTerm(searchBy);
    }

    const applySearchTerm = (searchBy) => {
        if (pathname.includes("workflows")) {
            workflowTableActions.search(searchBy, "name");
        } else {
            connectionsTableActions.search(searchBy, "name");
        }
    }

    const handleCloseEditKivraDialog = async () => {
        setShowEditKivraDialog(false);
        setKivraConnectionToEdit(null);
        await loadData();
    }

    const handleCloseEditDigipostDialog = async () => {
        setShowEditDigipostDialog(false);
        setDigipostConnectionToEdit(null);
        await loadData();
    }

    const handleCloseEditSftpDialog = async () => {
        setShowEditSftpDialog(false);
        setSftpConnectionToEdit(null);
        await loadData();
    }

    return (
        <>
            <ContentWrapper>
                <OAuthAppConnectionContext.Provider
                    value={{
                        connections: connections || [],
                        workflowDefinitions: workflowDefinitions || [],
                        setWorkflowDefinitions: setWorkflowDefinitions,
                        connectionToViewWorkflows: connectionToViewWorkflows,
                        loadData: loadData,
                        isLoading: loadAction.isExecuting,
                        handleTestConnections: handleTestConnections,
                        connectionsToTestRef: connectionsToTestRef,
                        connectionsTableActions: connectionsTableActions,
                        workflowTableActions: workflowTableActions,
                        getAppConnectionType: getAppConnectionType,
                        getOAuthConfig: getOAuthConfig,
                        getOAuthAppName: getOAuthAppName,
                        getViewWorkflowLink: getViewWorkflowLink,
                        handleReconnect: handleReconnect,
                    }}
                >
                    <div className="flex flex-row justify-between items-end">
                        <PageHeader
                            title={getOAuthAppName()}
                            optionalSideElement={
                                <div className="flex gap-x-3">
                                    <div className="mt-3 sm:mt-0 sm:ml-4 w-96">
                                        <label htmlFor="desktop-search-candidate" className="sr-only">
                                            Search
                                        </label>
                                        <div className="flex rounded-md shadow-sm">
                                            <div className="relative flex-grow focus-within:z-10">
                                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                    <SearchIcon
                                                        className="h-5 w-5 text-gray-400"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                                <input
                                                    type="text"
                                                    name="desktop-search-candidate"
                                                    id="desktop-search-candidate"
                                                    className="focus:ring-brand-pink focus:border-brand-pink w-full rounded-md pl-10 sm:block sm:text-sm border-gray-300"
                                                    placeholder="Search"
                                                    onChange={e => handleSearch(e.target)}
                                                    value={searchTerm}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                        type="button"
                                        className="w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm"
                                        onClick={() => handleTestConnections([...connections])}
                                    >
                                        Test connections
                                    </button>
                                    <button
                                        type="button"
                                        className="w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm"
                                        onClick={handleCreateConnection}
                                    >
                                        Add connection
                                    </button>
                                </div>
                            }
                        />
                    </div>

                    <div>
                        <ConnectionTabs
                            connections={connections}
                            workflowDefinitions={workflowDefinitions}
                        >
                            <OAuthAppConnectionComponent />
                            <OAuthAppWorkflowDefinitionComponent />
                        </ConnectionTabs>
                    </div>

                    <EditKivraConnectionDialog
                        open={showEditKivraDialog}
                        onClose={handleCloseEditKivraDialog}
                        connection={kivraConnectionToEdit}
                    />
                    <EditDigipostConnectionDialog
                        isOpen={showEditDigipostDialog}
                        onClose={handleCloseEditDigipostDialog}
                        connection={digipostConnectionToEdit}
                    />
                    <EditSftpConnectionDialog
                        isOpen={showEditSftpDialog}
                        onClose={handleCloseEditSftpDialog}
                        connection={sftpConnectionToEdit}
                    />
                </OAuthAppConnectionContext.Provider>
            </ContentWrapper>
        </>
    )
}