import { Menu, Transition } from "@headlessui/react";
import { CogIcon, DotsHorizontalIcon, PencilIcon, TrashIcon } from "@heroicons/react/outline";
import { useClassNames, useInputFocusSelect } from '@metaforcelabs/metaforce-core';
import React, { Fragment } from "react";
import { useContext, useState } from "react";
import { WorkflowContext } from "../../../../../../contexts";
import { useBranchAbbrevation } from "../../../../../../hooks/useBranchAbbrevation";
import { branchType } from "../../../../../../utils/constants";

export const StepBranchConfig = ({ branch }) => {
    const workflowContext = useContext(WorkflowContext);
    const [isRenaming, setIsRenaming] = useState(false);
    const [branchNameInputRef, setBranchNameInputRef] = useInputFocusSelect();
    const { classNames } = useClassNames();
    const { getBranchAbbrevation } = useBranchAbbrevation();

    const isDistribution = branch?.branchType === branchType.distribution;

    const handleRenameBranch = (value) => {
        const updated = { ...branch, name: value };
        workflowContext.updateBranch(updated);
    }

    return <div key={branch.id} className='flex justify-between rounded-lg py-2 pl-2 pr-4 bg-gray-100 items-center'>
        <div className='flex flex-1 space-x-4 items-center'>
            <div className='flex-none bg-gray-200 rounded-lg p-2 font-bold focus:ring-none'>{getBranchAbbrevation(branch.id)}</div>
            <div className='flex-1 pr-4'>
                {isRenaming ? (
                    <div className="flex rounded-md shadow-sm">
                        <input
                            type="text"
                            name="branch-name"
                            autoComplete="off"
                            ref={branchNameInputRef}
                            className="flex-1 py-1 font-semibold focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-l-md border-gray-300"
                            value={branch.name}
                            onChange={e => handleRenameBranch(e.target.value)}
                            onBlur={e => {
                                setIsRenaming(false);
                            }}
                        />
                        <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                            <PencilIcon className="h-5 w-5" />
                        </span>
                    </div>
                ) : (<span>{branch.name}</span>)}
            </div>
        </div>
        <div className='flex flex-none items-center space-x-6'>
            <div>
                <div className="relative">
                    <Menu>
                        <Menu.Button className="w-full flex items-center">
                            <div className={classNames(" rounded h-10 w-10 p-2",
                            )}>
                                <DotsHorizontalIcon className="" />
                            </div>
                        </Menu.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="z-20 absolute right-10 bottom-0 w-56 mt-1 bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="p-1">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={classNames("group flex rounded-md items-center w-full px-2 py-2 text-sm",
                                                    active ? "text-brand-pink font-semibold" : "text-gray-900")}
                                                onClick={() => {
                                                    workflowContext.setEditBranch(branch);
                                                }}
                                            >
                                                <CogIcon
                                                    className="w-5 h-5 mr-2"
                                                    aria-hidden="true"
                                                />
                                                Edit
                                            </button>
                                        )}
                                    </Menu.Item>
                                </div>
                                <div className="p-1">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={classNames("group flex rounded-md items-center w-full px-2 py-2 text-sm",
                                                    active ? "text-brand-pink font-semibold" : "text-gray-900")}
                                                onClick={() => {
                                                    setIsRenaming(true);
                                                    setTimeout(() => {
                                                        setBranchNameInputRef();
                                                    }, 50)
                                                }}
                                            >
                                                <PencilIcon
                                                    className="w-5 h-5 mr-2"
                                                    aria-hidden="true"
                                                />
                                                Rename {isDistribution ? ("Distribution") : ("Branch")}
                                            </button>
                                        )}
                                    </Menu.Item>
                                </div>
                                <div className="p-1">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={classNames("group flex rounded-md items-center w-full px-2 py-2 text-sm",
                                                    active ? "text-brand-pink font-semibold" : "text-gray-900")}
                                                onClick={() => {
                                                    workflowContext.removeBranch(branch);
                                                }}
                                            >
                                                <TrashIcon
                                                    className="w-5 h-5 mr-2"
                                                    aria-hidden="true"
                                                />
                                                Delete {isDistribution ? ("Distribution") : ("Branch")}
                                            </button>
                                        )}
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            </div>
        </div>
    </div>
}