import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getNextStepInstructions } from '../../api/public/workflow';

export default function Worklfow() {
    const { workflowId, workflowStepId, workflowValuesId, refererId } = useParams();
    const [data, setData] = useState("");
    const [displayMessage, setDisplayMessage] = useState("")

    const loadData = async () => {
        const data = await getNextStepInstructions(workflowValuesId, workflowId, workflowStepId, refererId);
        setData(JSON.stringify(data, null, 2))
        console.log(data)

        if (data.nextStepInstruction === "redirect") {
            if (data.redirectUrl) {
                window.location.href = data.redirectUrl
            }
        } else if (data.nextStepInstruction === "complete") {
            // setTimeout(()=> {
            //     window.history.back();
            // },2000)
            window.history.back();
            setDisplayMessage("Workflow Complete")
        } else if (data.nextStepInstruction === "completed-part") {
            setDisplayMessage("Worflow part Complete");
        }
    }

    useEffect(() => {
        loadData();
    }, [])

    return (<>
        <div className='text-lg font-semibold mb-4'>
            {displayMessage}
        </div>
        {/* <div>
            workflowId = {workflowId}
        </div>
        <div>
            workflowStepId = {workflowStepId}
        </div>
        <div>
            workflowValuesId = {workflowValuesId}
        </div>
        <div>
            refererId = {refererId || "null"}
        </div> */}
    </>
    )
}
