import Modal from "../../../../components/Modals/modal";
import { Button } from "../../../../components/Form/Button";
import { useContext, useEffect, useState } from "react";
import { InputText } from "components/Form/InputText";
import { Formik } from "formik";
import * as Yup from 'yup';
import { FolderSidebarContext } from "../../../../contexts";
import toast from "react-hot-toast";

export default function RenameFolderModal({ open, setOpen, folder, onRename }) {
    const { allFolders } = useContext(FolderSidebarContext);
    const [initialValues, setInitialValues] = useState({
        name: '',
    });

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
    });

    useEffect(() => {
        if (open)
            setInitialValues({ name: folder.name });
    }, [open])

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Modal
            size={"medium"}
            title={`Rename ${folder?.name} Folder`}
            isOpen={open}
            onClose={handleClose}
        >
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting, setErrors }) => {
                    let folders = allFolders.privateFolders.filter(x => !x.isAddItem).concat(allFolders.sharedFolders.filter(x => !x.isAddItem));
                    let foundFolder = folders.filter(x => x.id !== folder?.id).find(x => x.name.toLowerCase() === values.name.toLowerCase());
                    if (foundFolder) {
                        toast.error(`It can't rename folder. ${foundFolder?.name} already exists.`);
                        setErrors({
                            name: 'Already exists',
                        });
                        return;
                    }

                    folder.name = values.name;
                    await onRename(folder);
                    setSubmitting(false);
                    handleClose();
                }}
            >
                {({
                    values,
                    errors,
                    handleChange,
                    handleSubmit,
                    /* and other goodies */
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="mt-1 space-y-5">
                            <InputText
                                id="name"
                                name="name"
                                label=""
                                value={values.name}
                                onChange={handleChange}
                                error={errors.name}
                            />
                        </div>
                        <div className="mt-5 sm:mt-6 flex sm:block">
                            <div className='flex space-x-2'>
                                <Button onClick={() => handleSubmit()} text={"Rename"} />
                                <Button onClick={() => handleClose()} text={"Cancel"} theme='white' />
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </Modal>
    )
}