import React from 'react'
import { Button } from '../../../components/Form/Button'
import { EnvironmentBadge } from '../../../components/environmentBadge'

export default function PromotePreview({ version, nextEnv, onCancel, onConfirm }) {
    return (
        <div>
            <div>
                <span>Promote this version from <EnvironmentBadge environment={version.environment} isLatest={version.isLatestEnvVersion} /> to <EnvironmentBadge environment={nextEnv} isLatest={true} />?</span>
            </div>
            <div className='flex space-x-4 justify-end'>
                <Button onClick={() => { onCancel() }} text={"Cancel"} theme='white' />
                <Button onClick={() => { onConfirm(version) }} text={"Confirm"} />
            </div>
        </div>
    )
}

export function RollbackPreview({version, onCancel, onConfirm}){
    return (
        <div>
            <div>
                <span>Rollback this version?</span>
            </div>
            <div className='flex space-x-4 justify-end'>
                <Button onClick={() => { onCancel() }} text={"Cancel"} theme='white' />
                <Button onClick={() => { onConfirm(version) }} text={"Confirm"} />
            </div>
        </div>
    )
}

const EnvHighlight = ({ envName }) => {
    return <span className='italic font-bold'>{envName}</span>;
}
