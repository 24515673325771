import { Badge } from '@metaforcelabs/metaforce-core';
import React from 'react'
import { workflowStates } from '../../../utils/constants';

export default function WorkflowDefinitionStateBadge({ state, useShortText }) {
    switch (state) {
        case workflowStates.active:
            return (<Badge text={useShortText? "Active":"Workflow Active"} type="success" />);
        case workflowStates.inactive:
            return (<Badge text={useShortText?"Inactive":"Workflow Inactive"} type="error" />);
        default:
            return (<></>);
    }
}
