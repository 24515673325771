import { ChevronDownIcon, PlusIcon } from '@heroicons/react/outline';
import React from 'react'

export default function NextStepArrow({ isLastStep, onAddStep }) {

    return (
        <div className="w-full flex flex-col items-center">
            <div className="w-0.5 h-4 bg-gray-500 relative">
            </div>
            <div className="h-7 w-7 rounded-full p-1 bg-white cursor-pointer shadow hover:shadow-md" onClick={() => onAddStep()}>
                <PlusIcon className="text-indigo-700" />
            </div>
            {
                !isLastStep && (
                    <>
                        <div className="w-0.5 h-4 bg-gray-500 relative">
                        </div>
                        <div className="relative text-gray-500 -mt-3 -mb-1">
                            <ChevronDownIcon className="h-5 w-5" />
                        </div>
                    </>
                )
            }
        </div>
    );

}
