import { XIcon } from "@heroicons/react/outline";
import React from "react";
import { useContext, useEffect, useState, useMemo } from "react";
import { ClickOutside } from "../../../../components/ClickOutside";
import { BranchContext, WorkflowContext } from "../../../../contexts";
import WorkFlowStep from "../Components/workFlowStep";
import { BranchTriggerConfigStep } from "./branchTriggerConfigStep";
import { DndContextWrapper } from "../DragAndDrop/dndContextWrapper";
import { useWorkflowStep } from "../../../../hooks/useWorkflowStep";
import DndSortable from "../../../../components/DragAndDrop/dndSortable";


export const WorkflowBranchEditor = ({ branch, onCloseModal }) => {
    const { workFlow: workflow, ...workflowContext } = useContext(WorkflowContext);
    const [branchSteps, setBranchSteps] = useState([]);
    const [configStepActive, setConfigStepActive] = useState(true);
    const { canMove, getSteps, getCanMovedStepIds } = useWorkflowStep();
    const sortableItems = useMemo(() => getCanMovedStepIds(branchSteps) || [], [branchSteps]);

    useEffect(() => {
        setBranchSteps(getSteps(workflow, branch?.id));
    }, [workflow.workflowSteps, branch?.id])

    return (
        <BranchContext.Provider
            value={{
                branch
            }}
        >
            {branch && (
                <div className='bg-gray-100 rounded-lg shadow-lg h-full w-full overflow-y-hidden flex flex-col items-center'>
                    <div className='bg-white rounded-t-lg border-b border-solid p-4 flex justify-between shadow-md w-full'>
                        <div className='font-semibold text-lg'>{branch?.name}</div>
                        <div className='h-6 w-6 cursor-pointer' onClick={e => onCloseModal()}><XIcon /></div>
                    </div>
                    <div className="max-h-fit overflow-y-auto w-full h-full">
                        <div className='pt-4 mx-auto max-w-4xl'>
                            <ClickOutside onOutsideClick={() => {
                                setConfigStepActive(false);
                            }} ignoreRef={null}>

                                <BranchTriggerConfigStep branch={branch} activeStep={configStepActive} setActiveStep={() => setConfigStepActive(true)} />

                            </ClickOutside>
                            <DndContextWrapper sortableItems={sortableItems}>
                                {
                                    branchSteps.length > 0 && branchSteps.map((ws, i) => {
                                        return (
                                            <DndSortable
                                                key={i.toString() + (ws?.name || "new")}
                                                id={ws.id}
                                                isSortable={canMove(ws)}
                                                disabled={workflowContext.dragAndDropDisabled}
                                            >
                                                <WorkFlowStep
                                                    workflowStep={ws}
                                                    //Offset 1 to account for branch config step
                                                    stepCount={branchSteps.length + 1}
                                                    stepIdx={i + 1}
                                                    activeStep={workflowContext.activeStepId === ws.id} />
                                            </DndSortable>
                                        )
                                    })

                                }
                            </DndContextWrapper>

                        </div>
                    </div>

                </div>
            )}
        </BranchContext.Provider>
    );
}