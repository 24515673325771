import axios from 'axios'
import { config } from '../utils/config'
import { apiErrorHandler } from './apiErrorHandler'
import { saveAs } from 'file-saver';

export function setAuthHeader(token) {
  axios.defaults.headers.common['Authorization'] = token ? 'Bearer ' + token : ''
}

export const BaseUrl = {
  Api: config.apiBaseUrl,
  Identity: config.identityBaseUrl,
  Centerpoint: config.centerpointBaseUrl,
  WorkFlow: config.workflowBaseUrl,
  PagesManager: config.pagesManagerBaseUrl,
  Archive: config.archiveBaseUrl,
}

export const get = async (path, params = null, baseUrl = BaseUrl.Api) => {
  const axiosParams = params || {}
  try {
    const result = await axios({
      method: "get",
      url: `${baseUrl}/${path}`,
      ...axiosParams
    })
    return result.data
  }
  catch (err) {
    console.log(err)
    apiErrorHandler(err, params?.statusCodeHandlers)
  }
}

export const download = async (path, params = null, baseUrl = BaseUrl.Api, filename = "smartform.pdf") => {
  const axiosParams = params || {}
  try {
    const result = await axios({
      method: "get",
      responseType: 'blob', // important
      url: `${baseUrl}/${path}`,
      ...axiosParams
    })

    saveAs(result.data, filename);
  }
  catch (err) {
    apiErrorHandler(err, params?.statusCodeHandlers)
  }
}

export const put = async (path, data, params = null, baseUrl = BaseUrl.Api) => {
  const axiosParams = params || {}
  try {
    const result = await axios({
      method: "put",
      url: `${baseUrl}/${path}`,
      data,
      ...axiosParams
    })
    return result.data
  }
  catch (err) {
    apiErrorHandler(err, params?.statusCodeHandlers)
  }
}

export const post = async (path, data, params = null, baseUrl = BaseUrl.Api) => {
  const axiosParams = params || {}
  try {
    const result = await axios({
      method: "post",
      url: `${baseUrl}/${path}`,
      data,
      ...axiosParams
    })
    return result.data
  }
  catch (err) {
    apiErrorHandler(err, params?.statusCodeHandlers)
  }
}

export const destroy = async (path, params = null, baseUrl = BaseUrl.Api) => {
  const axiosParams = params || {}
  try {
    const result = await axios({
      method: "delete",
      url: `${baseUrl}/${path}`,
      ...axiosParams
    })
    return result.data
  }
  catch (err) {
    apiErrorHandler(err, params?.statusCodeHandlers)
  }
}

export const buildStatusCodeHandler = (statusCode, handler) => {
  return {
    statusCodeHandlers: [{
      statusCode: statusCode,
      handler: handler
    }]
  }
}