import React, { useContext, useEffect, useState } from 'react'
import { WorkflowStepContext } from '../../../../../../contexts';
import { NodeItem } from './nodeItem';
import { v4 as uuidv4 } from 'uuid';
import { useClassNames } from '@metaforcelabs/metaforce-core';
import { PlusIcon, XIcon } from '@heroicons/react/outline';

export const ViewpointMetadataArrayNodeComponent = ({ node, onChange, workflowStep }) => {
    const { classNames } = useClassNames();
    const [payLoadValues, setPayLoadValues] = useState([]);
    const stepContext = useContext(WorkflowStepContext);
    const [previousSteps, setPreviousSteps] = useState([]);
    const [previousStepsValues, setPreviousStepsValues] = useState([]);

    const handleParamsNodeChange = (nodeId, value) => {
        const idx = payLoadValues.findIndex(x => x.id === nodeId);
        if (idx === -1) {
            return;
        }

        if (payLoadValues[idx].value === value) {
            return;
        }

        payLoadValues[idx] = { ...payLoadValues[idx], value: value };

        return setPayLoadValues(prev => [...payLoadValues]);
    }

    const handleRemovePayloadValue = (payloadValue) => {
        setPayLoadValues(prev => ([...prev.filter(x => x.id !== payloadValue.id)]))
    }

    const handleAddPayloadValue = () => {
        const added = [...payLoadValues, { id: uuidv4(), value: '' }]
        setPayLoadValues(prev => added)
    }

    useEffect(() => {
        let currValue;
        if (Object.keys(node.value).length > 0) {
            currValue = Object.keys(node.value).map(k => ({ id: uuidv4(), key: k, value: node.value[k] }))
        } else {
            currValue = [{ id: uuidv4(), key: '', value: '' }]
        }

        setPayLoadValues(currValue);

        setPreviousSteps(stepContext.previousSteps);
        setPreviousStepsValues(stepContext.previousStepsValues);
    }, []);

    useEffect(() => {
        if (!node)
            return;

        if (payLoadValues.length === 0) {
            return;
        }

        const asKeyValue = payLoadValues.reduce((prev, curr, i) => {
            return { ...prev, [i]: curr.value }
        }, []);
        const json = JSON.stringify(asKeyValue);
        if (json !== node.value) {
            onChange(node.id, asKeyValue);
        }
    }, [payLoadValues])

    return (
        <div className=''>
            {payLoadValues.map((p, i) => (
                <div key={p.id} className={classNames('grid grid-cols-10 space-x-2', i > 0 ? "mt-2" : "")}>
                    <div className='col-span-8'>
                        <NodeItem
                            item={p}
                            nodeValue={p.value}
                            placeHolder={"Value"}
                            handleNodeValueChange={(nodeId, value) => handleParamsNodeChange(nodeId, value)}
                            previousSteps={previousSteps}
                            stepIdx={stepContext.stepIdx}
                            workflowStep={workflowStep}
                            previousStepsValues={previousStepsValues} />
                    </div>
                    <div className='col-span-2 p-1 flex space-x-1'>
                        <div className='flex-1'>
                            <div className={classNames("text-lg h-8 w-8 text-gray-500 p-1 border border-solid border-gray-400 rounded-lg flex justify-center",
                                i === 0 ? "opacity-0" : "cursor-pointer hover:shadow hover:text-red-600"
                            )}
                                title="Remove"
                                onClick={e => {
                                    if (i === 0) {
                                        return;
                                    }
                                    handleRemovePayloadValue(p);
                                }}
                            >
                                <XIcon />
                            </div>
                        </div>

                        <div className={classNames("text-lg h-8 w-8 text-gray-500 p-1 border border-solid border-gray-400 rounded-lg flex justify-center",
                            i === 0 ? "cursor-pointer hover:shadow hover:text-green-600" : "opacity-0"
                        )}
                            title="Add"
                            onClick={e => {
                                if (i !== 0) {
                                    return;
                                }
                                handleAddPayloadValue();
                            }}
                        >
                            <PlusIcon />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}
