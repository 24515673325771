import React, { useEffect } from "react"
import DataOptionsMenu from "../dataOptionsMenu"

export const OptionsSetupActionStep = ({ setupActionStep, workflowStepValues, onStepValueChange }) => {

    const handleStepValueChange = (property, value) => {
        onStepValueChange(property, value)
    }

    useEffect(() => {
        if (setupActionStep.defaultOptionValue && !workflowStepValues[setupActionStep.property]?.name) {
            onStepValueChange(setupActionStep.property, setupActionStep.defaultOptionValue);
        }
    }, [])


    return (
        <div className="mt-4">
            <h4 className="font-semibold">{setupActionStep.name}: </h4>
            <DataOptionsMenu
                optionsData={setupActionStep.options}
                selectedValue={workflowStepValues[setupActionStep.property]?.name}
                onSelect={value => handleStepValueChange(setupActionStep.property, value)}
                isLoading={false}
            />
            <div className='mt-1 text-gray-500 text-sm'>
                {setupActionStep?.helperText}
            </div>
        </div>

    )
}