import { useToastAction } from '@metaforcelabs/metaforce-core';
import React, { useEffect, useState } from "react";
import { getActiveDialogDefinitions } from "../../../../../api/dialogDefinition";
import DataOptionsMenu from "../dataOptionsMenu";
import { getFolders } from 'api/archive';

export const ApiOptionsSetupActionStep = ({ setupActionStep, workflowStepValues, onStepValueChange }) => {
    const loadDataAction = useToastAction();
    const [optionsData, setOptionsData] = useState([]);

    const loadData = async () => {
        loadDataAction.execute(async () => {
            switch (setupActionStep.dataSourceId) {
                case "active-smartforms":
                    const activeSF = await getActiveDialogDefinitions();
                    setOptionsData(activeSF.sort((a, b) => { return new Date(b.updatedDate) - new Date(a.updatedDate); }).map(({ id, name }) => { return { value: id, name } }))
                    break;
                case "archive-folders":
                    const activeFolders = await getFolders();
                    setOptionsData(activeFolders.map(f => ({ name: f.name, value: f.id })));
                    break;
                default:
                    break;
            }
        }, "Load failed")
    }

    useEffect(() => {
        loadData();
    }, [setupActionStep.dataSourceId]);

    const handleStepValueChange = (property, value) => {
        onStepValueChange(property, value)
    }

    return (
        <div className="mt-4">
            <h4 className="font-semibold">{setupActionStep.name}{
                setupActionStep?.required ? "*" : ""
            }:</h4>
            <DataOptionsMenu
                optionsData={optionsData}
                selectedValue={workflowStepValues[setupActionStep.property]?.name}
                selectedValueValid={workflowStepValues[setupActionStep.property]?.valid !== false}
                onSelect={value => handleStepValueChange(setupActionStep.property, value)}
                isLoading={loadDataAction.isExecuting} />
            <div className='text-gray-500 text-sm mt-1'>
                {setupActionStep?.helperText}
            </div>
        </div>);
}