import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { handleWorkflowTrigger } from '../../api/public/workflow';

export default function TriggerReceiver() {
    const { workflowId, refererId } = useParams();
    const [displayMessage, setDisplayMessage] = useState("Please wait...")
    const loadData = async () => {
        const data = await handleWorkflowTrigger(workflowId, refererId);
        // setData(JSON.stringify(data, null, 2))
        console.log(data)
        if (data.nextStepInstruction === "redirect") {
            if (data.redirectUrl) {
                window.location.href = data.redirectUrl
            }
        } else if (data.nextStepInstruction === "complete") {
            // setTimeout(()=> {
            //     window.history.back();
            // },2000)
            window.history.back();
            setDisplayMessage("Workflow Complete")
        } else if (data.nextStepInstruction === "completed-part") {
            setDisplayMessage("Worflow part Complete");
        } else if (data.nextStepInstruction === "async-event") {
            window.history.back();
        }
    }

    useEffect(() => {
        loadData();
    }, [])

    return (<>

        <div className='text-lg font-semibold mb-4'>
            {displayMessage}
        </div>


        {/* <div>
            workflowId = {workflowId}
        </div>
        <div>
            refererId = {refererId}
        </div> */}
    </>
    )
}
