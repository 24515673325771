import React, { useContext } from 'react';
import { config } from '../../utils/config';
import { ContentWrapper, MenuBar } from '@metaforcelabs/metaforce-core';
import { OidcRoutesContext } from 'contexts';
import { Link } from 'react-router-dom';

const MissingLicense = () => {
  const oidcContext = useContext(OidcRoutesContext);
  const navigationMenu = [];

  return (
    <div className="relative min-h-screen flex flex-col bg-white">
      <MenuBar
        navigation={navigationMenu}
        customerName={oidcContext?.customerInfo?.name}
        customerOrgNumber={oidcContext?.customerInfo?.orgNumber}
        userProfileImage={oidcContext?.userProfile?.userProfileImage}
        homeButtonLink={config.centerpointUiBaseUrl}
        helpLink={config.docsUrl}
      />
      <ContentWrapper>
        <p className="text-2xl mt-6 ml-6">Missing license to access Workflow - Admin</p>
      </ContentWrapper>
    </div>
  )
}
export default MissingLicense;