import { property } from "lodash";
import { features, productCodesConfig } from "../utils/config";
import { appEventIconName, branchType, workflowAppType, workflowStepType } from "../utils/constants";
import { NO_STEP_EVENT_ALLOWED } from "../hooks/useWorkflowHelper";

export const specialApps = {
    filterApp: {
        id: 'filter-app',
        type: workflowAppType.filter,
        name: "Filter",
        appIcon: appEventIconName.filterIcon,
        autoSelectEvent: true,
        events: [
            {
                key: "filter-setup",
                name: "Filter Setup",
                stepType: workflowStepType.action,
                setupSteps: [
                    {
                        property: "filterCondition",
                        name: "Name",
                        type: "filter-conditions",
                        dataSourceId: null,
                    }
                ]
            }
        ]
    },
    branchStep: {
        id: 'branch-app',
        type: workflowAppType.branch,
        name: "Branch",
        appIcon: appEventIconName.branchIcon,
        autoSelectEvent: true,
        events: [
            {
                key: "branch-setup",
                name: "Branch Setup",
                stepType: workflowStepType.action,
                setupSteps: [
                    {
                        property: "branch",
                        name: "Name",
                        type: "branch-setup",
                        dataSourceId: null,
                    }
                ]
            }
        ]
    },
    distribution: {
        id: 'distribution-app',
        type: workflowAppType.distribution,
        name: "Distribution",
        appIcon: appEventIconName.distributionIcon,
        autoSelectEvent: true,
        events: [
            {
                key: "distribution-setup",
                name: "Distribution Setup",
                stepType: workflowStepType.action,
                setupSteps: [
                    {
                        property: "distribution",
                        name: "Name",
                        type: "distribution-setup",
                        dataSourceId: null,
                    }
                ]
            }
        ]
    },
    loop: {
        id: 'loop-app',
        type: workflowAppType.loop,
        name: "Loop",
        appIcon: appEventIconName.loopIcon,
        autoSelectEvent: false,
        events: [
            {
                key: "loop-array",
                name: "Loop on array values",
                stepType: workflowStepType.action,
                loadStepValuesFromApi: true,
                setupSteps: [
                    {
                        property: "loop",
                        name: "Name",
                        type: "loop-setup",
                        dataSourceId: null,
                    }
                ]
            }
        ]
    }
};

export const workFlowApps = [
    {
        id: 'smart-form-app',
        type: workflowAppType.smartForm,
        name: "Smartforms",
        appIcon: appEventIconName.smartFormIcon,
        productCode: productCodesConfig.smartform,
        events: [
            {
                key: "element-on-load",
                name: "Element On Load",
                stepType: workflowStepType.trigger,
                loadStepValuesFromApi: true,
                setupSteps: [
                    {
                        property: "smartformId",
                        name: "Smartform",
                        type: "api-options",
                        dataType: "api",
                        triggerStepChangedEvent: true,
                        dataSourceId: "active-smartforms"
                    }
                ]
            },
            {
                key: "submit",
                name: "Submit",
                stepType: workflowStepType.trigger,
                loadStepValuesFromApi: true,
                setupSteps: [
                    {
                        property: "smartformId",
                        name: "Smartform",
                        type: "api-options",
                        dataType: "api",
                        triggerStepChangedEvent: true,
                        dataSourceId: "active-smartforms"
                    }
                ]
            },
            {
                key: "value-change",
                name: "Element Value Change",
                stepType: workflowStepType.trigger,
                loadStepValuesFromApi: true,
                setupSteps: [
                    {
                        property: "smartformId",
                        name: "Smartform",
                        type: "api-options",
                        dataType: "api",
                        triggerStepChangedEvent: true,
                        dataSourceId: "active-smartforms"
                    }
                ]
            },
            {
                key: "new-smartform",
                name: "New",
                stepType: workflowStepType.action,
                loadStepValuesFromApi: true,
                forBranchTypes: [branchType.branch],
                setupSteps: [
                    {
                        property: "smartformId",
                        name: "Smartform",
                        type: "api-options",
                        triggerStepChangedEvent: true,
                        dataSourceId: "active-smartforms",
                    },
                    {
                        property: "smartform-values",
                        type: "nodes",
                        dependsOn: ["smartformId"],
                        source: "api",
                        dataSourceId: "dialog-definition"
                    }
                ]
            },
            {
                key: "send-smartform",
                name: "Send New",
                stepType: workflowStepType.action,
                loadStepValuesFromApi: true,
                forBranchTypes: [],
                setupSteps: [
                    {
                        property: "recipient-email",
                        name: "Recipient Email",
                        type: "input-field",
                        required: true
                        // dataSourceId: "active-smartforms"
                    },
                    {
                        property: "recipient-name",
                        name: "Recipient Name",
                        type: "input-field",
                        required: true
                        // dataSourceId: "active-smartforms"
                    },
                    {
                        property: "smartformId",
                        name: "Smartform",
                        type: "api-options",
                        dataSourceId: "active-smartforms",
                    },
                    {
                        property: "smartform-values",
                        type: "nodes",
                        dependsOn: ["smartformId"],
                        source: "api",
                        dataSourceId: "dialog-definition"
                    }
                ]
            },
            {
                key: "set-values",
                name: "Update Values",
                stepType: workflowStepType.action,
                loadStepValuesFromApi: true,
                forBranchTypes: [],
                setupSteps: [
                    {
                        property: "smartformId",
                        name: "Smartform",
                        type: "api-options",
                        dataSourceId: "active-smartforms",
                    },
                    {
                        property: "smartform-values",
                        type: "nodes",
                        dependsOn: ["smartformId"],
                        source: "api",
                        dataSourceId: "dialog-definition"
                    }
                ]
            },
            {
                key: "create-pdf",
                name: "Create PDF",
                stepType: workflowStepType.action,
                loadStepValuesFromApi: true,
                setupSteps: [
                    {
                        property: "smartformId",
                        name: "Smartform Id",
                        type: "input-field",
                        dataType: "",
                        dataSourceId: "",
                        required: true
                    },
                    {
                        property: "smartformValueId",
                        name: "Smartform Value Id",
                        type: "input-field",
                        sameStepAsProperty: "smartformId",
                        dataType: "",
                        dataSourceId: "",
                        required: true
                    },
                ]
            },
        ]
    },
    {
        id: 'rest-api-app',
        type: workflowAppType.restApi,
        name: "REST API",
        appIcon: appEventIconName.restApiIcon,
        events: [
            {
                key: "rest-api-post",
                name: "POST",
                stepType: workflowStepType.action,
                useTest: true,
                setupSteps: [
                    {
                        property: "url",
                        name: "Url",
                        type: "input-field",
                        dataType: "",
                        dataSourceId: "",
                        required: true
                    },
                    {
                        property: "payload-type",
                        name: "Body - Format",
                        type: "options",
                        helperText: "",
                        options: [
                            {
                                value: "dictionary", name: "Key - Values",
                            },
                            {
                                value: "json", name: "JSON",
                            },
                            {
                                value: "formurl", name: "Form URL encoded",
                            },
                        ],
                        defaultOptionValue: {
                            value: "dictionary", name: "Key - Values",
                        },
                        dataType: "",
                        dataSourceId: "",

                    },
                    {
                        property: "payload",
                        name: "Body",
                        type: "input-dictionary",
                        dataType: "",
                        dataSourceId: "",
                        helperText: "Allows for flattened json with '.' property separator",
                        displayCondition: {
                            property: "payload-type",
                            value: "dictionary"
                        },
                    },
                    {
                        property: "payload-formurlencoded",
                        name: "Body",
                        type: "input-dictionary",
                        dataType: "",
                        dataSourceId: "",
                        helperText: "Key value pairs with ContentType application/x-www-form-urlencoded",
                        displayCondition: {
                            property: "payload-type",
                            value: "formurl"
                        },
                    },
                    {
                        property: "payload-json",
                        name: "Body",
                        type: "input-field",
                        elementHeight: 52,
                        dataType: "",
                        dataSourceId: "",
                        helperText: "Raw JSON body",
                        displayCondition: {
                            property: "payload-type",
                            value: "json"
                        },
                    },
                    {
                        property: "auth-type",
                        name: "Authentication Type",
                        type: "options",
                        helperText: "",
                        options: [
                            {
                                value: "none", name: "No Authentication",
                            },
                            {
                                value: "basic", name: "Basic Authentication",
                            },
                            {
                                value: "client-credentials", name: "OAuth 2.0 Client Credentials",
                            }
                        ],
                        defaultOptionValue: {
                            value: "none", name: "No Authentication",
                        },
                        dataType: "",
                        dataSourceId: "",
                    },
                    {
                        property: "basic-auth",
                        name: "Basic Authentication",
                        displayCondition: {
                            property: "auth-type",
                            value: "basic"
                        },
                        type: "input-field",
                        helperText: "A pipe (|) separated username, password combo for standard HTTP authentication.",
                        dataType: "",
                        dataSourceId: "",
                    },
                    {
                        property: "client-id",
                        name: "Client Id",
                        type: "input-secret",
                        displayCondition: {
                            property: "auth-type",
                            value: "client-credentials"
                        },
                        helperText: "",
                        dataType: "",
                        dataSourceId: "",
                    },
                    {
                        property: "client-secret",
                        name: "Client Secret",
                        type: "input-secret",
                        displayCondition: {
                            property: "auth-type",
                            value: "client-credentials"
                        },
                        helperText: "",
                        dataType: "",
                        dataSourceId: "",
                    },
                    {
                        property: "token-endpoint",
                        name: "Token Endpoint",
                        type: "input-field",
                        displayCondition: {
                            property: "auth-type",
                            value: "client-credentials"
                        },
                        helperText: "",
                        dataType: "",
                        dataSourceId: "",
                    },
                    {
                        property: "scope",
                        name: "Scope",
                        type: "input-field",
                        displayCondition: {
                            property: "auth-type",
                            value: "client-credentials"
                        },
                        helperText: "",
                        dataType: "",
                        dataSourceId: "",
                    },
                    {
                        property: "http-headers",
                        name: "Headers",
                        type: "input-dictionary",
                        dataType: "",
                        dataSourceId: ""
                    },
                    {
                        property: "response-format",
                        name: "Response Format",
                        type: "options",
                        helperText: "Specify how the response should be formatted",
                        options: [
                            {
                                value: "flattened", name: "Flattened (default)",
                            },
                            {
                                value: "raw", name: "Raw",
                            }
                        ],
                        defaultOptionValue: {
                            value: "flattened", name: "Flattened (default)",
                        },
                        dataType: "",
                        dataSourceId: "",
                    }
                ]
            },
            {
                key: "rest-api-get",
                name: "GET",
                stepType: workflowStepType.action,
                useTest: true,
                setupSteps: [
                    {
                        property: "url",
                        name: "Url",
                        type: "input-field",
                        dataType: "",
                        dataSourceId: "",
                        required: true
                    },
                    {
                        property: "auth-type",
                        name: "Authentication Type",
                        type: "options",
                        helperText: "",
                        options: [
                            {
                                value: "none", name: "No Authentication",
                            },
                            {
                                value: "basic", name: "Basic Authentication",
                            },
                            {
                                value: "client-credentials", name: "OAuth 2.0 Client Credentials",
                            }
                        ],
                        defaultOptionValue: {
                            value: "none", name: "No Authentication",
                        },
                        dataType: "",
                        dataSourceId: "",
                    },
                    {
                        property: "basic-auth",
                        name: "Basic Authentication",
                        displayCondition: {
                            property: "auth-type",
                            value: "basic"
                        },
                        type: "input-field",
                        helperText: "A pipe (|) separated username, password combo for standard HTTP authentication.",
                        dataType: "",
                        dataSourceId: "",
                    },
                    {
                        property: "client-id",
                        name: "Client Id",
                        type: "input-secret",
                        displayCondition: {
                            property: "auth-type",
                            value: "client-credentials"
                        },
                        helperText: "",
                        dataType: "",
                        dataSourceId: "",
                    },
                    {
                        property: "client-secret",
                        name: "Client Secret",
                        type: "input-secret",
                        displayCondition: {
                            property: "auth-type",
                            value: "client-credentials"
                        },
                        helperText: "",
                        dataType: "",
                        dataSourceId: "",
                    },
                    {
                        property: "token-endpoint",
                        name: "Token Endpoint",
                        type: "input-field",
                        displayCondition: {
                            property: "auth-type",
                            value: "client-credentials"
                        },
                        helperText: "",
                        dataType: "",
                        dataSourceId: "",
                    },
                    {
                        property: "scope",
                        name: "Scope",
                        type: "input-field",
                        displayCondition: {
                            property: "auth-type",
                            value: "client-credentials"
                        },
                        helperText: "",
                        dataType: "",
                        dataSourceId: "",
                    },
                    {
                        property: "http-headers",
                        name: "Headers",
                        type: "input-dictionary",
                        dataType: "",
                        dataSourceId: ""
                    },
                    {
                        property: "response-format",
                        name: "Response Format",
                        type: "options",
                        helperText: "Specify how the response should be formatted",
                        options: [
                            {
                                value: "flattened", name: "Flattened (default)",
                            },
                            {
                                value: "raw", name: "Raw",
                            }
                        ],
                        defaultOptionValue: {
                            value: "flattened", name: "Flattened (default)",
                        },
                        dataType: "",
                        dataSourceId: "",
                    }
                ]
            }
        ]
    },
    {
        id: 'editor-form-app',
        type: workflowAppType.editor,
        name: "Editor",
        appIcon: appEventIconName.webEditorIcon,
        productCode: productCodesConfig.editor,
        events: [
            {
                key: "post-process",
                name: "Document Post-Process",
                stepType: workflowStepType.trigger,
                loadStepValuesFromApi: true,
                setupSteps: [    
                ],
                allowedFutureStepEvents: [
                    
                ]
            },
            {
                key: "pre-process",
                name: "Document Pre-Process",
                stepType: workflowStepType.trigger,
                loadStepValuesFromApi: true,
                setupSteps: [    
                ],
                allowedFutureStepEvents: [
                    "rest-api-post",
                    "rest-api-get",
                    "pre-process-output"
                ]
            },
            {
                key: "pre-process-output",
                name: "Document Pre Process Output",
                stepType: workflowStepType.action,
                loadStepValuesFromApi: true,
                setupSteps: [
                    {
                        property: "data-xml",
                        name: "Output Data",
                        type: "input-field",
                        elementHeight: 52,
                        dataType: "",
                        required: true,
                        helperText: "Raw XML output to be returned to the Editor",
                        defaultValue: null
                    }
                ],
                allowedFutureStepEvents: [
                    NO_STEP_EVENT_ALLOWED
                ]
            },
            {
                key: "pre-post-process",
                name: "Document Pre Post Process",
                stepType: workflowStepType.trigger,
                loadStepValuesFromApi: true,
                setupSteps: [    
                ],
                allowedFutureStepEvents: [
                    "rest-api-post",
                    "rest-api-get",
                    "pre-post-process-output"
                ]
            },
            {
                key: "pre-post-process-output",
                name: "Document Pre Post Output",
                stepType: workflowStepType.action,
                loadStepValuesFromApi: true,
                setupSteps: [
                    {
                        property: "validation-message",
                        name: "Message",
                        type: "input-field",
                        // elementHeight: 52,
                        dataType: "",
                        required: true,
                        helperText: "Outout message to be returned to the Editor",
                        defaultValue: null
                    },
                    {
                        property: "validation-result",
                        name: "Result",
                        type: "input-field",
                        // elementHeight: 52,
                        dataType: "",
                        required: true,
                        helperText: "A boolean string literal (\"true\" | \"false\") value to indicate if the document is valid or not",
                        defaultValue: null
                    }
                ],
                allowedFutureStepEvents: [
                    NO_STEP_EVENT_ALLOWED
                ]
            },
            {
                key: "create-dxml",
                name: "Edit Document",
                stepType: workflowStepType.action,
                setupSteps: [
                    {
                        property: "group",
                        name: "Group",
                        type: "options",
                        // required: true,
                        options: [
                            {
                                value: "standard", name: "Standard",
                            }
                        ],
                        defaultOptionValue: {
                            value: "standard", name: "Standard",
                        },
                        // dataSourceId: "active-smartforms"
                    },
                    {
                        property: "environment",
                        name: "Environment",
                        type: "options",
                        // required: true,
                        options: [
                            {
                                value: "0", name: "Development"
                            },
                            {
                                value: "1", name: "Test"
                            },
                            {
                                value: "2", name: "Test 1"
                            },
                            {
                                value: "3", name: "Test 2"
                            },
                            {
                                value: "4", name: "Test 3"
                            },
                            {
                                value: "5", name: "Test 4"
                            },
                            {
                                value: "6", name: "Test 5"
                            },
                            {
                                value: "7", name: "IntegrationTest"
                            },
                            {
                                value: "8", name: "AcceptanceTest"
                            },
                            {
                                value: "9", name: "Production"
                            }

                        ],
                        defaultOptionValue: {
                            value: "0", name: "Development"
                        },
                        // dataSourceId: "active-smartforms"
                    },
                    {
                        property: "template-file",
                        name: "Template File",
                        type: "input-field",
                        required: true,
                    },
                    {
                        property: "template",
                        name: "Document Name",
                        type: "input-field",
                        required: true,
                    }, {
                        property: "data-xml",
                        name: "Data",
                        type: "input-field",
                        elementHeight: 52,
                        dataType: "",
                        required: true,
                        helperText: "Raw XML body",
                        defaultValue: `<?xml version='1.0' encoding='utf-8'?>\n<Data>\n\n</Data>`
                    },

                ]
            },
            {
                key: "create-dxml-raw",
                name: "Create DXML",
                stepType: workflowStepType.action,
                loadStepValuesFromApi: true,
                useTest: true,
                setupSteps: [
                    {
                        property: "group",
                        name: "Group",
                        type: "options",
                        // required: true,
                        options: [
                            {
                                value: "standard", name: "Standard",
                            }
                        ],
                        defaultOptionValue: {
                            value: "standard", name: "Standard",
                        },
                        // dataSourceId: "active-smartforms"
                    },
                    {
                        property: "environment",
                        name: "Environment",
                        type: "options",
                        // required: true,
                        options: [
                            {
                                value: "0", name: "Development"
                            },
                            {
                                value: "1", name: "Test"
                            },
                            {
                                value: "2", name: "Test 1"
                            },
                            {
                                value: "3", name: "Test 2"
                            },
                            {
                                value: "4", name: "Test 3"
                            },
                            {
                                value: "5", name: "Test 4"
                            },
                            {
                                value: "6", name: "Test 5"
                            },
                            {
                                value: "7", name: "IntegrationTest"
                            },
                            {
                                value: "8", name: "AcceptanceTest"
                            },
                            {
                                value: "9", name: "Production"
                            }

                        ],
                        defaultOptionValue: {
                            value: "0", name: "Development"
                        },
                        // dataSourceId: "active-smartforms"
                    },
                    {
                        property: "template-file",
                        name: "Template File",
                        type: "input-field",
                        required: true,
                    },
                    {
                        property: "template",
                        name: "Document Name",
                        type: "input-field",
                        required: true,
                    }, {
                        property: "data-xml",
                        name: "Data",
                        type: "input-field",
                        elementHeight: 52,
                        dataType: "",
                        required: true,
                        helperText: "Raw XML body",
                        defaultValue: `<?xml version='1.0' encoding='utf-8'?>\n<Data>\n\n</Data>`
                    },

                ]
            },
            {
                key: "create-pdf",
                name: "Create PDF",
                stepType: workflowStepType.action,
                useTest: true,
                loadStepValuesFromApi: true,
                setupSteps: [
                    {
                        property: "group",
                        name: "Group",
                        type: "options",
                        required: true,
                        options: [
                            {
                                value: "standard", name: "Standard",
                            }
                        ],
                        defaultOptionValue: {
                            value: "standard", name: "Standard",
                        },
                        // dataSourceId: "active-smartforms"
                    },
                    {
                        property: "environment",
                        name: "Environment",
                        type: "options",
                        required: true,
                        options: [
                            {
                                value: "0", name: "Development"
                            },
                            {
                                value: "1", name: "Test"
                            },
                            {
                                value: "2", name: "Test 1"
                            },
                            {
                                value: "3", name: "Test 2"
                            },
                            {
                                value: "4", name: "Test 3"
                            },
                            {
                                value: "5", name: "Test 4"
                            },
                            {
                                value: "6", name: "Test 5"
                            },
                            {
                                value: "7", name: "IntegrationTest"
                            },
                            {
                                value: "8", name: "AcceptanceTest"
                            },
                            {
                                value: "9", name: "Production"
                            }

                        ],
                        defaultOptionValue: {
                            value: "0", name: "Development"
                        },
                        // dataSourceId: "active-smartforms"
                    },
                    {
                        property: "template-file",
                        name: "Template File",
                        type: "input-field",
                        required: true,
                    },
                    {
                        property: "template",
                        name: "Document Name",
                        type: "input-field",
                        required: true,
                    }, {
                        property: "data-xml",
                        name: "Data",
                        type: "input-field",
                        elementHeight: 52,
                        required: true,
                        dataType: "",
                        helperText: "Raw XML body",
                        defaultValue: `<?xml version='1.0' encoding='utf-8'?>\n<Data>\n\n</Data>`
                    },

                ]
            },
            {
                key: "pdf-created",
                name: "PDF Created",
                stepType: workflowStepType.trigger,
                useTest: false,
                loadStepValuesFromApi: true,
                setupSteps: [
                    {
                        property: "group",
                        name: "Group",
                        type: "options",
                        required: true,
                        options: [
                            {
                                value: null, name: "Default",
                            },
                            {
                                value: "standard", name: "Standard",
                            }
                        ],
                        defaultOptionValue: {
                            value: null, name: "Default",
                        },
                        // dataSourceId: "active-smartforms"
                    },
                    {
                        property: "environment",
                        name: "Environment",
                        type: "options",
                        required: true,
                        options: [
                            {
                                value: "0", name: "Development"
                            },
                            {
                                value: "1", name: "Test"
                            },
                            {
                                value: "2", name: "Test 1"
                            },
                            {
                                value: "3", name: "Test 2"
                            },
                            {
                                value: "4", name: "Test 3"
                            },
                            {
                                value: "5", name: "Test 4"
                            },
                            {
                                value: "6", name: "Test 5"
                            },
                            {
                                value: "7", name: "IntegrationTest"
                            },
                            {
                                value: "8", name: "AcceptanceTest"
                            },
                            {
                                value: "9", name: "Production"
                            }

                        ],
                        defaultOptionValue: {
                            value: "0", name: "Development"
                        },
                        // dataSourceId: "active-smartforms"
                    },
                    {
                        property: "template-file",
                        name: "Template File",
                        type: "input-field",
                        required: true,
                    },
                    {
                        property: "template",
                        name: "Document Name",
                        type: "input-field",
                        required: true,
                    }
                ]
            },
        ]
    },
    {
        id: 'microsoft-outlook-app',
        type: workflowAppType.microsoftOutlook,
        name: "Microsoft Outlook",
        appIcon: appEventIconName.outlookIcon,
        events: [
            {
                key: "outlook-send-email",
                name: "Send Email",
                stepType: workflowStepType.action,
                forBranchTypes: [],
                useTest: true,
                useAccountSelect: true,
                setupSteps: [
                    {
                        property: "Sender",
                        name: "From Email",
                        type: "input-field",
                        helperText: "Leave this field blank to send from your own address. Use this field to send from a shared inbox you have access to.",
                        dataSourceId: "",
                    },
                    {
                        property: "Recipients",
                        name: "To Email(s)",
                        type: "input-list",
                        helperText: "An Email value can either be a simple email address or a semicolon (;) separated list of email addresses",
                        dataSourceId: "",
                        required: true
                    },
                    {
                        property: "CcRecipients",
                        name: "CC Email(s)",
                        type: "input-list",
                        helperText: "An Email value can either be a simple email address or a semicolon (;) separated list of email addresses",
                        dataSourceId: "",
                    },
                    {
                        property: "BccRecipients",
                        name: "BCC Email(s)",
                        type: "input-list",
                        helperText: "An Email value can either be a simple email address or a semicolon (;) separated list of email addresses",
                        dataSourceId: "",
                    },
                    {
                        property: "Subject",
                        name: "Subject",
                        type: "input-field",
                        helperText: "",
                        dataSourceId: "",
                        required: true
                    },
                    {
                        property: "BodyFormat",
                        name: "Body Format",
                        type: "options",
                        helperText: "",
                        options: [
                            {
                                value: "text", name: "Text",
                            },
                            {
                                value: "html", name: "HTML",
                            }
                        ],
                        defaultOptionValue: {
                            value: "text", name: "Text",
                        },
                        dataType: "",
                        dataSourceId: "",
                    },
                    {
                        property: "Body",
                        name: "Body",
                        type: "input-field",
                        elementHeight: 52,
                        dataType: "",
                        dataSourceId: "",
                        required: true
                    },
                    {
                        property: "Attachments",
                        name: "Attachments",
                        type: "input-list",
                        dataType: "",
                        dataSourceId: "",
                        helperText: "A file to be attached to the email. Can be an actual file or a public URL which will be downloaded and attached. Plain text content will converted into a txt file and attached. Please note the maximum size for an attachment is 150MB.",
                    },
                ]
            },
        ]
    },
    {
        id: 'kivra-app',
        type: workflowAppType.kivra,
        name: "Kivra",
        appIcon: appEventIconName.kivraIcon,
        events: [
            {
                key: "kivra-find-user",
                name: "Find User",
                stepType: workflowStepType.action,
                forBranchTypes: [],
                useAccountSelect: true,
                loadStepValuesFromApi: true,
                setupSteps: [
                    {
                        property: "RecipientIdentifier",
                        name: "Recipient Identifier",
                        type: "options",
                        helperText: "",
                        options: [
                            {
                                value: "vat", name: "VAT number",
                            },
                            {
                                value: "ssn", name: "SSN",
                            },
                            {
                                value: "email", name: "Email",
                            }
                        ],
                        defaultOptionValue: {
                            value: "vat", name: "VAT number",
                        },
                        dataType: "",
                        dataSourceId: "",
                        triggerStepChangedEvent: true,
                        required: true
                    },
                    {
                        property: "VAT-number",
                        name: "VAT Number",
                        type: "input-field",
                        displayCondition: {
                            property: "RecipientIdentifier",
                            value: "vat"
                        },
                        dataType: "",
                        dataSourceId: "",
                        required: true,
                        helperText: "It is used to match VAT number against the available recipients in Kivra to check whether a user can receive content from a tenant.",
                    },
                    {
                        property: "SSN",
                        name: "SSN",
                        type: "input-field",
                        displayCondition: {
                            property: "RecipientIdentifier",
                            value: "ssn"
                        },
                        dataType: "",
                        dataSourceId: "",
                        required: true,
                        helperText: "It is used to match SSN against the available recipients in Kivra to check whether a user can receive content from a tenant.",
                    },
                    {
                        property: "Email",
                        name: "Email",
                        type: "input-field",
                        displayCondition: {
                            property: "RecipientIdentifier",
                            value: "email"
                        },
                        dataType: "",
                        dataSourceId: "",
                        required: true,
                        helperText: "It is used to match email against the available recipients in Kivra to check whether a user can receive content from a tenant.",
                    },
                ],
            },
            {
                key: "kivra-create-document",
                name: "Create Document",
                stepType: workflowStepType.action,
                forBranchTypes: [],
                loadStepValuesFromApi: true,
                setupSteps: [
                    {
                        property: "pdf",
                        name: "PDF File",
                        type: "input-field",
                        dataType: "",
                        dataSourceId: "",
                        required: true,
                        helperText: "If it contains multiple PDF files, it will combine them into one single PDF document.",
                    },
                    {
                        property: "html",
                        name: "HTML Responsive Content",
                        type: "input-field",
                        elementHeight: 52,
                        dataType: "",
                        dataSourceId: "",
                        helperText: "The responsive HTML version of the document",
                    },
                ],
            },
            {
                key: "kivra-create-invoice-payment-option",
                name: "Create Invoice Payment Option",
                stepType: workflowStepType.action,
                forBranchTypes: [],
                loadStepValuesFromApi: true,
                setupSteps: [
                    {
                        property: "DueDate",
                        name: "Due Date",
                        type: "input-field",
                        dataType: "",
                        dataSourceId: "",
                        required: true,
                        helperText: "Kivra uses ISO 8601 standard for formatting dates. The format looks like YYYY-MM-DD.",
                    },
                    {
                        property: "Amount",
                        name: "Amount",
                        type: "input-field",
                        dataType: "",
                        dataSourceId: "",
                        required: true,
                        helperText: "The payment amount for this option. A positive number.",
                    },
                    {
                        property: "ReferenceType",
                        name: "Reference Type",
                        type: "options",
                        options: [
                            {
                                value: "SE_OCR", name: "SE_OCR",
                            },
                            {
                                value: "TENANT_REF", name: "TENANT_REF",
                            },
                        ],
                        defaultOptionValue: {
                            value: "SE_OCR", name: "SE_OCR",
                        },
                        dataType: "",
                        dataSourceId: "",
                        required: true,
                        helperText: "Type of format for the reference. It must be consistent with Reference (below). SE_OCR must fulfill the requirements for OCR. TENANT_REF is a reference of your choice.",
                    },
                    {
                        property: "Reference",
                        name: "Reference",
                        type: "input-field",
                        dataType: "",
                        dataSourceId: "",
                        required: true,
                        helperText: "The reference used for paying. Must be consistent with Type (above).",
                    },
                    {
                        property: "Title",
                        name: "Title",
                        type: "input-field",
                        dataType: "",
                        dataSourceId: "",
                        helperText: "",
                    },
                    {
                        property: "Description",
                        name: "Description",
                        type: "input-field",
                        dataType: "",
                        dataSourceId: "",
                        helperText: "",
                    },
                    {
                        property: "Icon",
                        name: "Icon",
                        type: "input-field",
                        dataType: "",
                        dataSourceId: "",
                        helperText: "You can insert PNG image file here. The image must be quadratic (that is, width and height must be the same). The sides must be at least 256 pixels long, and at most 512 pixels long. The image must have an alpha channel. Max size is 134 kB when encoded, which is roughly equivalent to 100 kB before encoding.",
                    },
                ],
            },
            {
                key: "kivra-create-invoice-payment-flexible-option",
                name: "Create Invoice Payment Flexible Option",
                stepType: workflowStepType.action,
                forBranchTypes: [],
                loadStepValuesFromApi: true,
                setupSteps: [
                    {
                        property: "DueDate",
                        name: "Due Date",
                        type: "input-field",
                        dataType: "",
                        dataSourceId: "",
                        required: true,
                        helperText: "Kivra uses ISO 8601 standard for formatting dates. The format looks like YYYY-MM-DD.",
                    },
                    {
                        property: "ReferenceType",
                        name: "Reference Type",
                        type: "options",
                        options: [
                            {
                                value: "SE_OCR", name: "SE_OCR",
                            },
                            {
                                value: "TENANT_REF", name: "TENANT_REF",
                            },
                        ],
                        defaultOptionValue: {
                            value: "SE_OCR", name: "SE_OCR",
                        },
                        dataType: "",
                        dataSourceId: "",
                        required: true,
                        helperText: "Type of format for the reference. It must be consistent with Reference (below). SE_OCR must fulfill the requirements for OCR. TENANT_REF is a reference of your choice.",
                    },
                    {
                        property: "Reference",
                        name: "Reference",
                        type: "input-field",
                        dataType: "",
                        dataSourceId: "",
                        required: true,
                        helperText: "The reference used for paying. Must be consistent with Type (above).",
                    },
                    {
                        property: "Title",
                        name: "Title",
                        type: "input-field",
                        dataType: "",
                        dataSourceId: "",
                        helperText: "",
                    },
                    {
                        property: "Description",
                        name: "Description",
                        type: "input-field",
                        dataType: "",
                        dataSourceId: "",
                        helperText: "",
                    },
                    {
                        property: "Icon",
                        name: "Icon",
                        type: "input-field",
                        dataType: "",
                        dataSourceId: "",
                        helperText: "You can insert PNG image file here. The image must be quadratic (that is, width and height must be the same). The sides must be at least 256 pixels long, and at most 512 pixels long. The image must have an alpha channel. Max size is 134 kB when encoded, which is roughly equivalent to 100 kB before encoding.",
                    },
                    {
                        property: "MinLimit",
                        name: "Minimum Allowed Amount",
                        type: "input-field",
                        dataType: "",
                        dataSourceId: "",
                        helperText: "The hard minimum allowed to be paid. The user may not specify an amount lower than this. In case min_limit is not provided the minimum allowed amount is 1 SEK.",
                    },
                    {
                        property: "MinSuggested",
                        name: "Suggested Minimum Amount",
                        type: "input-field",
                        dataType: "",
                        dataSourceId: "",
                        helperText: "The suggested minimum to pay. The app will notify the user if they enter an amount lower than this, but if the user insists the payment is allowed to go through. The suggested minimum must be larger than the hard minimum, if both are provided.",
                    },
                    {
                        property: "MaxSuggested",
                        name: "Suggested Maximum Amount",
                        type: "input-field",
                        dataType: "",
                        dataSourceId: "",
                        helperText: "The suggested maximum to pay. The app will notify the user if they enter an amount greater than this, but if the user insists the payment is allowed to go through. The suggested maximum must be less than the hard maximum, if both are provided.",
                    },
                    {
                        property: "MaxLimit",
                        name: "Maximum Allowed Amount",
                        type: "input-field",
                        dataType: "",
                        dataSourceId: "",
                        helperText: "The hard maximum allowed to be paid. The user may not specify an amount greater than this. In all cases the maximum allowed amount is 150 000 SEK due to payment provider restrictions.",
                    },
                ],
            },
            {
                key: "kivra-create-invoice-payment",
                name: "Create Invoice Payment",
                stepType: workflowStepType.action,
                forBranchTypes: [],
                loadStepValuesFromApi: true,
                setupSteps: [
                    {
                        property: "Method",
                        name: "Method",
                        type: "options",
                        options: [
                            {
                                value: "1", name: "Bankgiro",
                            },
                            {
                                value: "2", name: "Plusgiro",
                            },
                        ],
                        defaultOptionValue: {
                            value: "1", name: "Bankgiro",
                        },
                        dataType: "",
                        dataSourceId: "",
                        required: true,
                        helperText: "",
                    },
                    {
                        property: "Account",
                        name: "Account",
                        type: "input-field",
                        dataType: "",
                        dataSourceId: "",
                        required: true,
                        helperText: "The receiving account number.",
                    },
                    {
                        property: "Currency",
                        name: "Currency",
                        type: "options",
                        options: [
                            {
                                value: "SEK", name: "SEK",
                            },
                        ],
                        defaultOptionValue: {
                            value: "SEK", name: "SEK",
                        },
                        dataType: "",
                        dataSourceId: "",
                        required: true,
                        helperText: "Currency used in specifying amount. In Sweden, only SEK is allowed.",
                    },
                    {
                        property: "SwishAlias",
                        name: "Swish Payee Alias",
                        type: "input-field",
                        dataType: "",
                        dataSourceId: "",
                        helperText: "The Swish Payee Alias that should be used as the recipient if the user choses to pay this invoice with Swish. You can leave this field empty on the invoices that should not be payable via Swish.",
                    },
                    {
                        property: "Options",
                        name: "Payment Options",
                        type: "input-list",
                        helperText: "You can insert the return value of Kivra Create Invoice Payment Option action here. Each option should differ in either amount or reference.",
                        dataSourceId: "kivra-create-invoice-payment-option",
                        required: true
                    },
                    {
                        property: "FlexibleOption",
                        name: "Flexible Payment Option",
                        type: "input-field",
                        helperText: "You can insert the return value of Kivra Create Invoice Payment Flexible Option action here. The flexible option enables the user to enter the amount to pay within a range that you define.",
                        dataSourceId: "kivra-create-invoice-payment-flexible-option",
                    },
                ],
            },
            {
                key: "kivra-send-letter",
                name: "Send Letter",
                stepType: workflowStepType.action,
                forBranchTypes: [],
                loadStepValuesFromApi: true,
                // useTest: true,
                useAccountSelect: true,
                setupSteps: [
                    {
                        property: "RecipientIdentifier",
                        name: "Recipient Identifier",
                        type: "options",
                        helperText: "",
                        options: [
                            {
                                value: "vat", name: "VAT number",
                            },
                            {
                                value: "ssn", name: "SSN",
                            },
                            {
                                value: "email", name: "Email",
                            }
                        ],
                        defaultOptionValue: {
                            value: "vat", name: "VAT number",
                        },
                        dataType: "",
                        dataSourceId: "",
                        required: true
                    },
                    {
                        property: "VAT-number",
                        name: "VAT Number",
                        type: "input-field",
                        displayCondition: {
                            property: "RecipientIdentifier",
                            value: "vat"
                        },
                        dataType: "",
                        dataSourceId: "",
                        required: true,
                        helperText: "You should create Kivra Find User action before this action to check whether a user can receive content from a tenant by matching VAT number against the available recipients in Kivra.",
                    },
                    {
                        property: "SSN",
                        name: "SSN",
                        type: "input-field",
                        displayCondition: {
                            property: "RecipientIdentifier",
                            value: "ssn"
                        },
                        dataType: "",
                        dataSourceId: "",
                        required: true,
                        helperText: "You should create Kivra Find User action before this action to check whether a user can receive content from a tenant by matching SSN against the available recipients in Kivra.",
                    },
                    {
                        property: "Email",
                        name: "Email",
                        type: "input-field",
                        displayCondition: {
                            property: "RecipientIdentifier",
                            value: "email"
                        },
                        dataType: "",
                        dataSourceId: "",
                        required: true,
                        helperText: "You should create Kivra Find User action before this action to check whether a user can receive content from a tenant by matching email against the available recipients in Kivra.",

                    },
                    {
                        property: "Subject",
                        name: "Subject",
                        type: "input-field",
                        helperText: "",
                        dataSourceId: "",
                        required: true
                    },
                    {
                        property: "Type",
                        name: "Type",
                        type: "options",
                        helperText: "It provides information about the type of content being sent. The type of a content may influence how the user interacts with the content and how the user is notified about the content.",
                        options: [
                            {
                                value: "letter", name: "letter",
                            },
                            // {
                            //     value: "letter.salary", name: "letter.salary",
                            // },
                            // {
                            //     value: "letter.creditnotice", name: "letter.creditnotice",
                            // },
                        ],
                        defaultOptionValue: {
                            value: "letter", name: "letter",
                        },
                        dataType: "",
                        dataSourceId: "",
                        required: true
                    },
                    // {
                    //     property: "Retain",
                    //     name: "Retain",
                    //     type: "options",
                    //     helperText: "Boolean denoting if Kivra should try and retain this Content if it can't be delivered. Default false. Please note that retain must never be set to true for payable content.",
                    //     options: [
                    //         {
                    //             value: "false", name: "false",
                    //         },
                    //         {
                    //             value: "true", name: "true",
                    //         },
                    //     ],
                    //     defaultOptionValue: {
                    //         value: "false", name: "false",
                    //     },
                    //     dataType: "",
                    //     dataSourceId: "",
                    // },
                    // {
                    //     property: "RetentionDays",
                    //     name: "Retention Days",
                    //     type: "options",
                    //     helperText: "",
                    //     options: [
                    //         {
                    //             value: "30", name: "30",
                    //         },
                    //         {
                    //             value: "390", name: "390",
                    //         },
                    //     ],
                    //     defaultOptionValue: {
                    //         value: "30", name: "30",
                    //     },
                    //     displayCondition: {
                    //         property: "Retain",
                    //         value: "true",
                    //     },
                    //     dataType: "",
                    //     dataSourceId: "",
                    // },
                    {
                        property: "Campaign",
                        name: "Campaign",
                        type: "input-field",
                        helperText: "",
                        dataSourceId: "",
                    },
                    {
                        property: "Documents",
                        name: "Documents",
                        type: "input-list",
                        helperText: "You can insert the Document return value of Kivra Create Document action here.",
                        dataSourceId: "kivra-create-document",
                        required: true
                    },
                ]
            },
            {
                key: "kivra-send-invoice",
                name: "Send Invoice",
                stepType: workflowStepType.action,
                forBranchTypes: [],
                loadStepValuesFromApi: true,
                // useTest: true,
                useAccountSelect: true,
                setupSteps: [
                    {
                        property: "RecipientIdentifier",
                        name: "Recipient Identifier",
                        type: "options",
                        helperText: "",
                        options: [
                            {
                                value: "vat", name: "VAT number",
                            },
                            {
                                value: "ssn", name: "SSN",
                            },
                            {
                                value: "email", name: "Email",
                            }
                        ],
                        defaultOptionValue: {
                            value: "vat", name: "VAT number",
                        },
                        dataType: "",
                        dataSourceId: "",
                        required: true
                    },
                    {
                        property: "VAT-number",
                        name: "VAT Number",
                        type: "input-field",
                        displayCondition: {
                            property: "RecipientIdentifier",
                            value: "vat"
                        },
                        dataType: "",
                        dataSourceId: "",
                        required: true,
                        helperText: "You should create Kivra Find User action before this action to check whether a user can receive content from a tenant by matching VAT number against the available recipients in Kivra.",
                    },
                    {
                        property: "SSN",
                        name: "SSN",
                        type: "input-field",
                        displayCondition: {
                            property: "RecipientIdentifier",
                            value: "ssn"
                        },
                        dataType: "",
                        dataSourceId: "",
                        required: true,
                        helperText: "You should create Kivra Find User action before this action to check whether a user can receive content from a tenant by matching SSN against the available recipients in Kivra.",
                    },
                    {
                        property: "Email",
                        name: "Email",
                        type: "input-field",
                        displayCondition: {
                            property: "RecipientIdentifier",
                            value: "email"
                        },
                        dataType: "",
                        dataSourceId: "",
                        required: true,
                        helperText: "You should create Kivra Find User action before this action to check whether a user can receive content from a tenant by matching email against the available recipients in Kivra.",
                    },
                    {
                        property: "Subject",
                        name: "Subject",
                        type: "input-field",
                        helperText: "",
                        dataSourceId: "",
                        required: true
                    },
                    {
                        property: "Type",
                        name: "Type",
                        type: "options",
                        helperText: "It provides information about the type of content being sent. The type of a content may influence how the user interacts with the content and how the user is notified about the content.",
                        options: [
                            {
                                value: "invoice", name: "invoice",
                            },
                        ],
                        defaultOptionValue: {
                            value: "invoice", name: "invoice",
                        },
                        dataType: "",
                        dataSourceId: "",
                        required: true
                    },
                    {
                        property: "Documents",
                        name: "Documents",
                        type: "input-list",
                        helperText: "You can insert the Document return value of Kivra Create Document action here.",
                        dataSourceId: "kivra-create-document",
                        required: true
                    },
                    {
                        property: "Payment",
                        name: "Payment",
                        type: "input-field",
                        helperText: "You can insert the return value of Kivra Create Invoice Payment action here.",
                        dataSourceId: "kivra-create-invoice-payment",
                        required: true
                    },
                ]
            },
        ]
    },
    {
        id: 'cron-app',
        type: workflowAppType.chron,
        name: "Cron",
        appIcon: appEventIconName.chronIcon,
        events: [
            {
                key: "schedule",
                name: "Schedule",
                stepType: workflowStepType.trigger,
                setupSteps: [
                    {
                        property: "crontab",
                        name: "Crontab",
                        type: "input-field",
                        helperText: "The crontab expression that defines the schedule for the workflow. The crontab expression is a string comprising five fields separated by white spaces that represent the time to execute the workflow. The fields are as follows: minute, hour, day of month, month, day of week. The fields can contain any of the following values: an asterisk (*) to indicate all possible values, a list of comma-separated values, a range of values, or a step value. For example, the crontab expression '0 0 * * *' will execute the workflow at midnight every day.",
                    }
                ]
            },
        ]
    },
    {
        id: 'sftp-app',
        type: workflowAppType.sftp,
        name: "SFTP",
        appIcon: appEventIconName.sftpIcon,

        events: [
            {
                key: "list-files",
                name: "List files",
                useAccountSelect: true,
                loadStepValuesFromApi: false,
                useTest: true,
                stepType: workflowStepType.action,
                setupSteps: [
                    {
                        property: "path",
                        name: "Path",
                        type: "input-field",
                        helperText: "The path to the directory on the SFTP server where the files are located.",
                    }
                ]
            },
            {
                key: "sftp-download-file",
                name: "Download File",
                useAccountSelect: true,
                loadStepValuesFromApi: true,
                useTest: false,
                stepType: workflowStepType.action,
                setupSteps: [
                    {
                        property: "file-full-name",
                        name: "Full File Name",
                        type: "input-field",
                        helperText: "The full name of the file to download, including the file extension.",
                    }
                ]
            },
        ]
    },
    {
        id: 'digipost-app',
        type: workflowAppType.digipost,
        name: "Digipost",
        appIcon: appEventIconName.digipostIcon,
        events: [
            {
                key: "digipost-find-user",
                name: "Find User",
                stepType: workflowStepType.action,
                forBranchTypes: [],
                useAccountSelect: true,
                loadStepValuesFromApi: true,
                setupSteps: [
                    {
                        property: "PersonalIdentificationNumber",
                        name: "Personal Identification Number",
                        type: "input-field",
                        dataType: "",
                        dataSourceId: "",
                        required: true,
                        helperText: "You can identify a person by personal identification number and it returns whether he or she has a Digipost account",
                    },
                ],
            },
            {
                key: "digipost-send-message",
                name: "Send Message",
                stepType: workflowStepType.action,
                forBranchTypes: [],
                loadStepValuesFromApi: true,
                useAccountSelect: true,
                setupSteps: [
                    {
                        property: "PersonalIdentificationNumber",
                        name: "Personal Identification Number",
                        type: "input-field",
                        dataType: "",
                        dataSourceId: "",
                        required: true,
                        helperText: "You should create Digipost Find User action before this action to check if a person is a Digipost user by personal identification number.",
                    },
                    {
                        property: "Subject",
                        name: "Subject",
                        type: "input-field",
                        helperText: "",
                        dataSourceId: "",
                        required: true
                    },
                    {
                        property: "PDF",
                        name: "PDF File",
                        type: "input-field",
                        helperText: "If it contains multiple PDF files, it will combine them into one single PDF document.",
                        dataSourceId: "",
                        required: true
                    },
                    {
                        property: "ExternalLink",
                        name: "External Link",
                        type: "input-field",
                        helperText: "An external URL, along with an optional description and deadline for resources such as a survey. Must be http or https.",
                        dataSourceId: "",
                    },
                    {
                        property: "ExternalLinkDeadline",
                        name: "External Link Deadline",
                        type: "input-field",
                        helperText: "Optional deadline for the user to respond. ISO8601 full DateTime.",
                        dataSourceId: "",
                    },
                    {
                        property: "ExternalLinkDescription",
                        name: "External Link Description",
                        type: "input-field",
                        helperText: "A short, optional text-field, describing the external link.",
                        dataSourceId: "",
                    },
                    {
                        property: "ExternalLinkButtonText",
                        name: "External Link Button Text",
                        type: "input-field",
                        helperText: "Optional text which will be displayed on the button.",
                        dataSourceId: "",
                    },
                ]
            },
        ]
    },
    {
        id: 'json-reader-app',
        type: workflowAppType.jsonReader,
        name: "JSON Reader",
        appIcon: appEventIconName.jsonIcon,
        disabled: !features.JSONStepEnabled,
        events: [
            {
                key: "read-json-file",
                name: "Read file",
                useAccountSelect: false,
                loadStepValuesFromApi: false,
                useTest: true,
                stepType: workflowStepType.action,
                setupSteps: [
                    {
                        property: "node",
                        name: "Node",
                        type: "input-field",
                        helperText: "The node to read from the JSON file.",
                        requried: false,
                    },
                    {
                        property: "node-type",
                        name: "Node Type",
                        type: "options",
                        options: [
                            {
                                value: 1, name: "Array",
                            },
                            {
                                value: 2, name: "Object",
                            },
                        ],
                        defaultOptionValue: {
                            value: "array", name: "Array",
                        },
                        required: true,
                        helperText: "The type of the node to read from the JSON file.",
                    },
                    {
                        property: "file",
                        name: "File",
                        type: "input-field",
                        required: true,
                        helperText: "File from previous step",
                    },
                ]
            },
        ]
    },
    {
        id: 'csv-reader-app',
        type: workflowAppType.csvReader,
        name: "CSV Reader",
        appIcon: appEventIconName.csvIcon,
        disabled: false,
        events: [
            {
                key: "read-csv-file",
                name: "Read file",
                useAccountSelect: false,
                loadStepValuesFromApi: false,
                useTest: true,
                stepType: workflowStepType.action,
                setupSteps: [
                    {
                        property: "file",
                        name: "File",
                        type: "input-field",
                        required: true,
                        helperText: "File from a previous step",
                    },
                    {
                        property: "separator",
                        name: "Separator",
                        type: "input-field",
                        required: false,
                        helperText: "Default is comma (,).",
                    },
                    {
                        property: "includes-header-row",
                        name: "Includes Header Row",
                        type: "checkbox-field",
                        defaultValue: "true",
                    },
                    {
                        property: "clean-file",
                        name: "Clean File",
                        type: "checkbox-field",
                        defaultValue: "true",
                        helperText: "If checked, the file will be cleaned. Empty lines and lines with only whitespace will be removed. Empty lines can lead to problems further down the workflow",
                    }
                    // ,{
                    //     property: "header-config",
                    //     name: "Header Config",
                    //     type: "options",
                    //     options: [
                    //         {
                    //             value: 1, name: "First Row",
                    //         },
                    //         {
                    //             value: 2, name: "Custom",
                    //         },
                    //     ],
                    //     defaultOptionValue: {
                    //         value: 1, name: "First Row",
                    //     },
                    //     helperText: "The header row of the CSV file. Ignored if the file does not have a header row.",
                    //     requried: false,
                    // },
                ]
            },
            {
                key: "parse-csv-row",
                name: "Parse CSV Row",
                useAccountSelect: false,
                loadStepValuesFromApi: false,
                useTest: true,
                stepType: workflowStepType.action,
                setupSteps: [
                    {
                        property: "csv-header",
                        name: "CSV Header",
                        type: "input-field",
                        required: true,
                        helperText: "The header of the CSV file.",
                    },
                    {
                        property: "separator",
                        name: "Separator",
                        type: "input-field",
                        required: false,
                        helperText: "Default is comma (,).",
                    },
                    {
                        property: "csv-row",
                        name: "CSV Row",
                        type: "input-field",
                        required: true
                    },
                ]
            }
        ]
    },
    {
        id: 'webhook-app',
        type: workflowAppType.webhook,
        name: "Webhook",
        appIcon: appEventIconName.webhookIcon,
        disabled: false,
        dryRunEnabled: true,
        events: [
            {
                key: "catch-hook",
                name: "Catch Hook",
                useAccountSelect: false,
                loadStepValuesFromApi: false,
                useTest: true,
                stepType: workflowStepType.trigger,
                setupSteps: [
                    {
                        property: "response-format",
                        name: "Response Format",
                        type: "options",
                        helperText: "Specify how the response should be formatted. This is value is used when consuming the webhook. And response format cannot be changed after for previous webhooks.",
                        options: [
                            {
                                value: "flattened", name: "Flattened (default)",
                            },
                            {
                                value: "raw", name: "Raw",
                            }
                        ],
                        defaultOptionValue: {
                            value: "flattened", name: "Flattened (default)",
                        },
                        dataType: "",
                        dataSourceId: "",
                    }
                ]
            },
        ]
    },
    {
        id: 'viewpoint-app',
        type: workflowAppType.viewpoint,
        name: "Viewpoint",
        appIcon: appEventIconName.viewpointIcon,
        productCode: productCodesConfig.viewpoint,
        events: [
            {
                key: "viewpoint-upload-file",
                name: "Upload File",
                stepType: workflowStepType.action,
                forBranchTypes: [],
                loadStepValuesFromApi: true,
                useAccountSelect: true,
                setupSteps: [
                    {
                        property: "File",
                        name: "File",
                        type: "input-field",
                        helperText: "",
                        dataSourceId: "",
                        required: true
                    },
                    {
                        property: "Metadata",
                        name: "Metadata",
                        type: "viewpoint-metadata-setup",
                        dataType: "",
                        dataSourceId: "",
                    }
                ]
            },
        ]
    },
    {
        id: 'archive-app',
        type: workflowAppType.archive,
        name: "Archive",
        appIcon: appEventIconName.archiveIcon,
        productCode: productCodesConfig.archive,
        events: [
            {
                key: "archive-store-file",
                name: "Store File",
                stepType: workflowStepType.action,
                forBranchTypes: [],
                loadStepValuesFromApi: true,
                setupSteps: [
                    {
                        property: "File",
                        name: "File",
                        type: "input-field",
                        helperText: "",
                        dataSourceId: "",
                        required: true
                    },
                    {
                        property: "DocumentType",
                        name: "Document Type",
                        type: "options",
                        options: [
                            {
                                value: "0", name: "Default Document",
                            },
                            {
                                value: "1", name: "Smartform",
                            },
                            {
                                value: "2", name: "Digitally Signed File",
                            },
                        ],
                        defaultOptionValue: {
                            value: "0", name: "Default Document",
                        },
                        required: true,
                        helperText: "",
                    },
                    {
                        property: "FolderId",
                        name: "Folder",
                        type: "api-options",
                        dataType: "api",
                        dataSourceId: "archive-folders",
                        helperText: "You can go to Centerpoint Administration to create Archive folder.",
                        required: true,
                    },
                    {
                        property: "Search1",
                        name: "Search1",
                        type: "input-field",
                        helperText: "Metadata column is used to search file.",
                        dataSourceId: "",
                    },
                    {
                        property: "Search2",
                        name: "Search2",
                        type: "input-field",
                        helperText: "Metadata column is used to search file.",
                        dataSourceId: "",
                    },
                    {
                        property: "Search3",
                        name: "Search3",
                        type: "input-field",
                        helperText: "Metadata column is used to search file.",
                        dataSourceId: "",
                    },
                    {
                        property: "Search4",
                        name: "Search4",
                        type: "input-field",
                        helperText: "Metadata column is used to search file.",
                        dataSourceId: "",
                    },
                    {
                        property: "Search5",
                        name: "Search5",
                        type: "input-field",
                        helperText: "Metadata column is used to search file.",
                        dataSourceId: "",
                    },
                    {
                        property: "Search6",
                        name: "Search6",
                        type: "input-field",
                        helperText: "Metadata column is used to search file.",
                        dataSourceId: "",
                    },
                    {
                        property: "Search7",
                        name: "Search7",
                        type: "input-field",
                        helperText: "Metadata column is used to search file.",
                        dataSourceId: "",
                    },
                    {
                        property: "Search8",
                        name: "Search8",
                        type: "input-field",
                        helperText: "Metadata column is used to search file.",
                        dataSourceId: "",
                    },
                    {
                        property: "Search9",
                        name: "Search9",
                        type: "input-field",
                        helperText: "Metadata column is used to search file.",
                        dataSourceId: "",
                    },
                    {
                        property: "Search10",
                        name: "Search10",
                        type: "input-field",
                        helperText: "Metadata column is used to search file.",
                        dataSourceId: "",
                    },
                ]
            },
        ]
    },
    {
        id: 'toolbox-app',
        type: workflowAppType.toolbox,
        name: "Toolbox",
        appIcon: appEventIconName.toolboxIcon,
        events: [
            {
                key: "toolbox-convert-base64-to-pdf",
                name: "Convert Base64 to PDF file object",
                stepType: workflowStepType.action,
                forBranchTypes: [],
                loadStepValuesFromApi: true,
                useTest: true,
                setupSteps: [
                    {
                        property: "FileToDecode",
                        name: "File to decode",
                        type: "input-field",
                        helperText: "The base64 string of PDF file",
                        dataSourceId: "",
                        required: true
                    },
                    {
                        property: "Filename",
                        name: "Filename",
                        type: "input-field",
                        helperText: "PDF filename",
                        dataSourceId: "",
                        required: true
                    },
                ]
            },
        ]
    },

    // {
    //     id: 'dummy-app',
    //     type: workflowAppType.workflow,
    //     name: "Workflow",
    //     appIcon: appEventIconName.workFlowIcon
    // }
];