import { Menu, Transition } from "@headlessui/react";
import { ArrowsExpandIcon, DotsHorizontalIcon, TrashIcon } from "@heroicons/react/outline";
import { useClassNames } from '@metaforcelabs/metaforce-core';
import React, { Fragment } from "react";

export const WorkflowFolderCtxMenu = ({ folder, onRename, onDestroy }) => {
    const { classNames } = useClassNames();
    return (
        <Menu as="div" className="inline-block text-left">
            <div>
                <Menu.Button aria-label="Options" className="text-gray-400 p-1 bg-white hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md">
                    <DotsHorizontalIcon className="w-5 h-5" />
                    <span className="sr-only">Options</span>
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute z-50 right-5 w-44 mt-1 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    className={classNames("group flex rounded-md items-center w-full px-3 py-2 text-sm",
                                        active ? "bg-gray-100 text-gray-900 font-semibold" : "text-gray-700")}
                                    onClick={e => {
                                        onRename(folder)
                                    }}
                                >
                                    <ArrowsExpandIcon
                                        className="w-5 h-5 mr-2"
                                        aria-hidden="true"
                                    />
                                    Rename
                                </button>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    className={classNames("group flex rounded-md items-center w-full px-3 py-2 text-sm",
                                        active ? "bg-gray-100 text-gray-900 font-semibold" : "text-gray-700")}
                                    onClick={e => {
                                        onDestroy()
                                    }}
                                >
                                    <TrashIcon
                                        className="w-5 h-5 mr-2"
                                        aria-hidden="true"
                                    />
                                    Delete
                                </button>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}