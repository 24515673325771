import React from "react";
import { useContext, useEffect, useState } from "react";
import { WorkflowStepContext } from "../../../../../contexts";

export const InputSecret = ({ workflowStepValues, setupActionStep, onStepValueChange, apiParameters, workflowStep }) => {

    const handleNodeValueChange = (value) => {
        onStepValueChange(setupActionStep.property, { value });
    }

    return (
        <div className='mt-4'>
            <h4 className="font-semibold">{setupActionStep?.name}{
                setupActionStep?.required ? "*" : ""
            }:</h4>
            <div>
                <input
                    className="min-w-0 w-full border-transparent rounded-lg bg-gray-100 hover:shadow-sm focus:border-indigo-500 focus:border-sm cursor-text px-4 py-2"
                    placeholder="Enter text"
                    type="password"
                    autoComplete="false"
                    value={workflowStepValues[setupActionStep.property]?.value}
                    onChange={e => handleNodeValueChange(e.target.value)}
                />
            </div>
            <div className='text-gray-500 text-sm mt-1'>
                {setupActionStep?.helperText}
            </div>
        </div>
    )
}