
export default function MicrosoftOutlookIcon({ className }) {
    return (
        <svg version="1.1" viewBox="0 0 512 512" className={className}>
            <g id="_x32_43-outlook">
                <g>
                    <path style={{ fill: "#0071C5" }} d="M471.624,126.283H284.746V54.219c-0.001-4.298-1.892-8.364-5.198-11.104    c-3.282-2.738-7.681-3.922-11.819-3.053L37.731,83.3c-6.814,1.275-11.73,7.211-11.73,14.157v317.106    c0,6.919,4.916,12.883,11.73,14.157l229.997,43.24c0.862,0.17,1.754,0.259,2.646,0.259c3.334,0,6.582-1.152,9.172-3.318    c3.309-2.734,5.199-6.828,5.199-11.099v-72.067h186.878c7.938,0,14.375-6.46,14.375-14.419V140.7    C486,132.747,479.563,126.283,471.624,126.283z" />
                    <g>
                        <polygon style={{ fill: "#FFFFFF" }} points="429.736,155.112 284.746,155.112 284.746,187.845 341.419,223.984" />
                        <path style={{ fill: "#FFFFFF" }} d="M351.08,252.984c-2.592,2.02-5.726,3.026-8.831,3.026c-2.674,0-5.376-0.744-7.708-2.249     l-49.795-31.755v134.9h172.505v-186.75L351.08,252.984z" />
                    </g>
                    <path style={{ fill: "#FFFFFF" }} d="M155.373,183.939c-39.618,0-71.874,38.805-71.874,86.484c0,47.683,32.256,86.485,71.874,86.485    s71.876-38.803,71.876-86.485C227.249,222.745,194.991,183.939,155.373,183.939z M155.373,328.08    c-23.776,0-43.123-25.857-43.123-57.656c0-31.797,19.347-57.654,43.123-57.654s43.13,25.858,43.13,57.654    C198.503,302.223,179.149,328.08,155.373,328.08z" />
                </g>
            </g>
            <g id="Layer_1" />
        </svg>
    )
}