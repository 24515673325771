import { DocumentAddIcon } from '@heroicons/react/outline';
import * as Icon from "@heroicons/react/outline"
import React, { useContext } from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Link, NavLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import NewWorkflow from '../newWorkflow';
import WorkflowDefinitionStateBadge from '../WorkFlowEditor/workflowDefinitionStateBadge';
import { stringToLocaleDateTimeString } from '../../../utils/date';
import { getWorkflowDashboard } from '../../../api/workflowDashboard';
import { ButtonV2, CoreButtonTypes, HighlightCard, HighlightCardVariants, ItemCard, SparkleCard, useToastAction } from '@metaforcelabs/metaforce-core';
import { ContentWrapper } from '@metaforcelabs/metaforce-core';
import WorkflowDisplayArt from './workflowDisplayArt';
import SparkleIcon from '../../../components/Icons/sparkleIcon';
import { workflowTemplateType } from '../../../utils/constants';
import { workflowTemplates } from '../../../utils/templates';
import { Button } from '../../../components/Form/Button';
import { useHasProductLicensed } from '../../../hooks/useHasProductLicensed';
import { workFlowApps } from '../../../domain/workflowApps';

export default function Dashboard() {
    const dashboardAction = useToastAction();
    const [dashboardData, setDashboardData] = useState({});
    const [showNewWorkflow, setShowNewWorkflow] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedTemplate, setSelectedTemplate] = useState();
    let [searchParams, setSearchParams] = useSearchParams();
    const { hasProductLicensed } = useHasProductLicensed();

    const handleCreateWorkflowTemplate = async (templateType) => {
        const template = workflowTemplates.find(x => x.type === templateType);
        setSelectedTemplate(template);
        setShowNewWorkflow(true);
    }

    const hasTemplateProductLicensed = (templateType) => {
        const foundTemplate = workflowTemplates.find(x => x.type === templateType);
        if (!foundTemplate)
            return true;

        const appEvents = foundTemplate.steps.map(step => {
            return workFlowApps.find(x => x.type === step.appType);
        }).filter(x => !!x);

        const allLicensed = appEvents.every(ae => hasProductLicensed(ae));
        return allLicensed;
    }

    const templateLists = [
        {
            description: "Create a workflow that is triggered by a Smartform and if a specific condition is met it will open a new Smartform. You will have to fill in the blanks.",
            onClick: () => handleCreateWorkflowTemplate(workflowTemplateType.workflowTriggeredByWorkflow),
            hasProductLicensed: hasTemplateProductLicensed(workflowTemplateType.workflowTriggeredByWorkflow),
        },
        {
            description: "Create a workflow that will take the input from a Smartform and use it to create a document with editor before the end result is distributed with Kivra.",
            onClick: () => handleCreateWorkflowTemplate(workflowTemplateType.kivraSendLetter),
            hasProductLicensed: hasTemplateProductLicensed(workflowTemplateType.kivraSendLetter),
        },
        {
            description: "Create a workflow that will look up a value from a Smartform through a REST API before inserting the value back again.",
            onClick: () => handleCreateWorkflowTemplate(workflowTemplateType.elementValueChangeAndRestApiPostAndUpdateValue),
            hasProductLicensed: hasTemplateProductLicensed(workflowTemplateType.elementValueChangeAndRestApiPostAndUpdateValue),
        },
    ]

    useEffect(() => {
        if (searchParams.has('new')) {
            setShowNewWorkflow(true);
        }
    }, [searchParams]);


    const handleSetShowNewWorkflow = (show) => {
        setShowNewWorkflow(show);
        setSelectedTemplate(null);
        if (!show) {
            setSearchParams({});
        }
    }

    useEffect(() => {
        load();
    }, []);

    const load = () => {
        dashboardAction.execute(async () => {
            const results = await getWorkflowDashboard();
            setDashboardData(results);
        }, "Failed to load dashboard")
    }

    // const hasData = dashboardData?.topDialogDefinitions?.length > 0
    return dashboardAction.isExecuting ? (<></>) : (
        <ContentWrapper>
            <div className="mt-5">
                <h1 className="text-2xl font-bold sm:text-4xl">Workflow</h1>

                <section className='mt-10'>
                    <HighlightCard
                        variant={HighlightCardVariants.yellow}
                        header="Create a workflow"
                        body={
                            <div className='w-full lg:w-150%'>
                                <p>A workflow  will connect elements in a customer communication in order to create fully automated processes.</p>
                                <p>A workflow will always need a trigger to be started. That is why you will see first step in a workflow called trigger.</p>
                                <p>The next step is an action where you would like something to happen. Filters and branches offers logical handling of actions within a workflow and helps you achieveing your objectives.</p>
                            </div>
                        }
                        buttonText='Create'
                        onClick={() => setSearchParams({ new: "true" })}
                        rightContent={<WorkflowDisplayArt />}
                    />
                </section>

                <section className='mt-10'>
                    <div className='mt-2 grid gap-x-2 gap-y-2 sm:gap-x-4 lg:gap-x-6 grid-cols-1 sm:grid-cols-3'>
                        {
                            templateLists.filter(t => t.hasProductLicensed).map((t, index) => {
                                return (
                                    <SparkleCard
                                        key={index}
                                        body={t.description}
                                        onClick={t.onClick}
                                    />
                                )
                            })
                        }
                    </div>
                </section>

                <>
                    <div className='flex justify-between mt-12'>
                        <h3 className="text-xl leading-6 font-bold text-gray-900">Last 10 Workflows</h3>
                        <Link to={"/admin/workflow-list"}
                            className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                        >
                            See all <span aria-hidden="true"> &rarr;</span>
                        </Link>
                    </div>
                    <div className="mt-5 w-full flex flex-col">
                        <div className="-my-2  overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                {
                                    (!dashboardData?.highlightedWorkflows || dashboardData?.highlightedWorkflows?.length === 0) &&
                                    <div className="text-center border-2 rounded-md border-gray-300 border-dashed p-5 mt-4">
                                        <svg
                                            className="mx-auto h-12 w-12 text-gray-400"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                vectorEffect="non-scaling-stroke"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                                            />
                                        </svg>
                                        <h3 className="mt-2 text-sm font-medium text-gray-900">No workflows created</h3>
                                        <p className="mt-1 text-sm text-gray-500">Create a workflow from the list page</p>
                                        <div className="mt-6">
                                            <button
                                                type="button"
                                                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-pink hover:bg-brand-pink focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                onClick={e => setShowNewWorkflow(true)}
                                            >
                                                <Icon.PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                                New workflow
                                            </button>
                                        </div>
                                    </div>
                                }
                                {
                                    !dashboardAction.isExecuting && dashboardData?.highlightedWorkflows && dashboardData?.highlightedWorkflows.length > 0 &&
                                    <div className="relative z-0">
                                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                                <div className="shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg">
                                                    <table className="min-w-full divide-y divide-gray-200">
                                                        <thead className="bg-gray-50">
                                                            <tr>
                                                                <th
                                                                    scope="col"
                                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                >
                                                                    Name
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider "
                                                                >
                                                                    State
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                >
                                                                    Created{" "}
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                >
                                                                    Updated{" "}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="bg-white divide-y divide-gray-200">
                                                            {dashboardData?.highlightedWorkflows?.map((element) => (
                                                                <tr>
                                                                    {/* <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                
                                                                                        </td> */}
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-indigo-600 relative" style={{ minWidth: "250px" }}>
                                                                        <NavLink className={"cursor-pointer hover:underline"}
                                                                            to={`/admin/workflow/editor/${element.id}`}
                                                                        >
                                                                            {element.name}
                                                                        </NavLink>
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                                        <WorkflowDefinitionStateBadge state={element.workflowDefinitionState} useShortText={true} />
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                                        {stringToLocaleDateTimeString(
                                                                            element.createdDate
                                                                        )}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                                        {stringToLocaleDateTimeString(
                                                                            element.updatedDate
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </>
                <NewWorkflow open={showNewWorkflow} setOpen={handleSetShowNewWorkflow} template={selectedTemplate} />
                {/* <NewDialog open={showNewDialog} setOpen={setShowNewDialog} /> */}
            </div>
        </ContentWrapper>
    )
}