
export const NO_STEP_EVENT_ALLOWED = "no-step-event-allowed";

export const useWorkflowhelper = (workflow) => {

    const getAllowedFutureSteps = (currentStepId) => {
        const stepIdx = workflow.workflowSteps.findIndex(x => x.id === currentStepId);
        const prevSteps = workflow.workflowSteps.slice(0, stepIdx);
        const stepsWithLimitations = prevSteps.filter(x => (x.selectedEvent?.allowedFutureStepEvents?.length ?? [])  > 0);
        const stepRestrictions = stepsWithLimitations.map(x => x.selectedEvent.allowedFutureStepEvents);
        
        if(stepRestrictions.length === 0){
            return [];
        }

        //Shortcircut if there is only one step with limitations
        if(stepRestrictions.length === 1){
            return stepRestrictions[0];
        }

        //all distinct steps limitiations
        const all = stepRestrictions.flat().filter((x, i, self) => self.indexOf(x) === i);
        // if there are multiple steps with limitations, we need to find the intersection of the allowed steps
        const res = all.filter(x => stepRestrictions.every(y => y.includes(x)));
        
        if(res.length === 0){
            //if thre is no intersection, no steps are allowed
            return [NO_STEP_EVENT_ALLOWED];
        }

        return res;
    }

    return {
        noFutureStepsAreAllowed: (currentStepId) => {
            const currentStep = workflow.workflowSteps.find(x => x.id === currentStepId);
            const currentStepLimitations = currentStep?.selectedEvent?.allowedFutureStepEvents ?? [];
            return [...getAllowedFutureSteps(currentStepId),...currentStepLimitations].includes(NO_STEP_EVENT_ALLOWED);
        },
        getAllowedFutureSteps: getAllowedFutureSteps
    }
}