import Modal from "../../../../components/Modals/modal";
import { Button } from "../../../../components/Form/Button";

export default function DeleteFolderModal({ open, setOpen, folder, onDelete }) {
    return (
        <Modal
            size={"medium"}
            title={"Are you sure you want to delete the folder?"}
            isOpen={open}
            onClose={() => setOpen(false)}
        >
            <div className='flex space-x-2 mt-5'>
                <Button onClick={(event) => onDelete(folder?.id)} text={"Delete"} />
                <Button onClick={() => setOpen(false)} text={"Cancel"} theme='white' />
            </div>
        </Modal>
    )
}