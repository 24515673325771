import { get, BaseUrl } from './api'

export const getCustomerUserAccess = async ()=> {
  return await get(`api/CustomerUser/ProductFeaturesWithAccess`, {
  }, BaseUrl.Centerpoint);
}

/**
 * @returns {Promise.<{customerId:string,name:string,orgNumber: string,country: {id: string,code: string,name: string,currency: string,languageCode: string }}>}
 */
export const getCustomerInfo = async () => {
  return await get(`api/Customer/GetCustomer`, null,
    BaseUrl.Centerpoint);

}

export const getLicensedProducts = async () => {
  return await get(`api/Customer/GetLicensedProducts`, null,
    BaseUrl.Centerpoint);

}