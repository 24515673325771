import React, { useContext, useEffect, useState } from 'react'
import { WorkflowStepContext } from '../../../../../contexts';
import { NodeItem } from './Components/nodeItem';
import { v4 as uuidv4 } from 'uuid';
import { useToastAction } from '@metaforcelabs/metaforce-core';
import toast from 'react-hot-toast';
import * as SetupActionsSteps from '../SetupActionSteps';
import { getViewpointSchemas } from '../../../../../api/viewpoint';
import DataOptionsMenu from '../dataOptionsMenu';
import { editorEnvironmentNames, editorEnvironments, viewpointColumnType, viewpointProperties } from '../../../../../utils/constants';
import { getEnvironmentGroups } from '../../../../../api/pagesManager';
import { ViewpointMetadataArrayNodeComponent } from './Components/viewpointMetadataArrayNodeComponent';

export const ViewpointSetupActionStep = ({ workflowStepValues, setupActionStep, onStepValueChange, workflowStep }) => {
    const centerpointSchema = "centerpoint";
    const schemaSetupActionStepInitialValue = {
        property: viewpointProperties.schema,
        name: viewpointProperties.schema,
        type: "options",
        required: true,
        triggerStepChangedEvent: true,
    };
    const [schemaSetupActionStep, setSchemaSetupActionStep] = useState(schemaSetupActionStepInitialValue);
    const retentionPolicySetupActionStepInitialValue = {
        property: viewpointProperties.retentionPolicy,
        name: viewpointProperties.retentionPolicy,
        type: "options",
        required: true,
        dataSourceId: "",
    };
    const [retentionPolicySetupActionStep, setRetentionPolicySetupActionStep] = useState(retentionPolicySetupActionStepInitialValue);
    const [metadataNodeValues, setMetadataNodeValues] = useState([]);
    const [connectionId, setConnectionId] = useState();
    const [schemas, setSchemas] = useState([]);
    const [selectedSchema, setSelectedSchema] = useState();
    const [environmentGroups, setEnvironmentGroups] = useState([]);

    const loadAction = useToastAction();

    const handleSchemaRequiredColumns = (schema) => {
        schema.requiredColumnProperties = {};

        if (schema.schema?.required) {
            if (schema.name === centerpointSchema) {
                const customerIdIndex = schema.schema.required.findIndex(r => r === "CustomerId");
                if (customerIdIndex >= 0)
                    schema.schema.required.splice(customerIdIndex, 1);
            }
            schema.requiredColumnProperties = schema.schema.required.reduce((prev, curr, i) => {
                const rc = `${viewpointProperties.metadataPrefix}${curr}`;
                return { ...prev, [rc]: rc }
            }, {});
        }

        if (schema.partitionKey) {
            const partitionKeyProperty = `${viewpointProperties.metadataPrefix}${schema.partitionKey}`;
            if (!schema.requiredColumnProperties.hasOwnProperty(partitionKeyProperty)) {
                schema.requiredColumnProperties[partitionKeyProperty] = partitionKeyProperty;
            }
        }
    }

    const loadData = async () => {
        loadAction.execute(async () => {
            if (!workflowStep.oAuthAppConnectionId || connectionId === workflowStep.oAuthAppConnectionId)
                return;

            if (connectionId === workflowStep.oAuthAppConnectionId)
                return;
            setConnectionId(workflowStep.oAuthAppConnectionId);

            const schemasResult = await getViewpointSchemas(workflowStep.oAuthAppConnectionId);
            if (!schemasResult || schemasResult.length === 0) {
                toast.error(`Viewpoint connection does not have schema.`);
            }
            schemasResult?.forEach(schema => {
                handleSchemaRequiredColumns(schema);
                schema.columns.forEach(column => {
                    column.property = viewpointProperties.metadataPrefix + column.name;
                    column.required = schema.requiredColumnProperties.hasOwnProperty(column.property);
                })
            })
            setSchemas(schemasResult);

            let newSelectedSchema = schemasResult[0];
            const schemaStepValue = workflowStepValues[schemaSetupActionStepInitialValue.property];
            if (schemaStepValue) {
                const foundSchema = schemasResult.find(x => x.name === schemaStepValue.value);
                if (foundSchema) {
                    newSelectedSchema = foundSchema;
                }
            }
            setSelectedSchema(newSelectedSchema);
        }, "Failed to load")
    }

    useEffect(() => {
        loadData();
    }, [workflowStep])

    useEffect(() => {
        if (selectedSchema) {
            handleSchemaChange(selectedSchema);
        }
    }, [selectedSchema])

    const handleRetentionPolicy = (schema) => {
        if (!schema.retentionPolicies || schema.retentionPolicies.length === 0) {
            toast.error(`Viewpoint schema '${schema.name}' does not define retention policy.`);
        }

        setRetentionPolicySetupActionStep({
            ...retentionPolicySetupActionStepInitialValue,
            options: schema.retentionPolicies.map(x => ({ name: x, value: x })),
        });

        const retentionPolicyStepValue = workflowStepValues[viewpointProperties.retentionPolicy];
        let newRetentionPolicy = schema.retentionPolicies[0];
        if (retentionPolicyStepValue) {
            const foundRetentionPolicy = schema.retentionPolicies.find(x => x === retentionPolicyStepValue.value);
            if (foundRetentionPolicy) {
                newRetentionPolicy = foundRetentionPolicy;
            }
        }
        onStepValueChange(viewpointProperties.retentionPolicy, { name: newRetentionPolicy, value: newRetentionPolicy });
    }

    const handleCenterpointSchemaChange = async (schema, newMetaDataNodeValues) => {
        if (schema.name !== centerpointSchema)
            return;

        const foundNodeEnvironmentId = newMetaDataNodeValues.find(x => x.property === viewpointProperties.environmentId);
        if (foundNodeEnvironmentId) {
            if (foundNodeEnvironmentId.value.value === '') {
                foundNodeEnvironmentId.value.value = editorEnvironmentNames[editorEnvironments.development];
            }
        }

        const foundNodeEnvironmentGroupId = newMetaDataNodeValues.find(x => x.property === viewpointProperties.environmentGroupId);
        if (foundNodeEnvironmentGroupId) {
            let environmentGroupsResult = await getEnvironmentGroups();
            let newEnvironmentGroups = environmentGroupsResult?.map(x => ({ name: x.name, value: x.name })) || [];
            newEnvironmentGroups.unshift({ name: 'None', value: '' });
            setEnvironmentGroups(newEnvironmentGroups);

            let newSelectedEnvironmentGroup;
            if (foundNodeEnvironmentGroupId.value.value !== '') {
                const foundEnvironmentGroup = newEnvironmentGroups.find(x => x.name === foundNodeEnvironmentGroupId.value.value);
                if (foundEnvironmentGroup) {
                    newSelectedEnvironmentGroup = foundEnvironmentGroup;
                }
            }
            foundNodeEnvironmentGroupId.value.value = newSelectedEnvironmentGroup ? newSelectedEnvironmentGroup.name : '';
        }
    }

    const handleMetadataValue = (column, currPropertyValue) => {
        let value;
        switch (column.type) {
            case viewpointColumnType.array:
                value = currPropertyValue ? currPropertyValue : {};
                break;
            case viewpointColumnType.boolean:
                value = currPropertyValue ? currPropertyValue : { name: 'true', value: 'true' };
                break;
            default:
                value = {
                    name: column.property,
                    value: currPropertyValue ? currPropertyValue.value : '',
                };
                break;
        }
        return value;
    }

    const handleMetadata = async (schema) => {
        let newMetaDataNodeValues = schema.columns.map(c => {
            const currPropertyValue = workflowStepValues[c.property];
            return {
                id: uuidv4(),
                property: c.property,
                name: c.name,
                value: handleMetadataValue(c, currPropertyValue),
                required: c.required,
                type: c.type
            };
        });

        setMetadataNodeValues(newMetaDataNodeValues);

        await handleCenterpointSchemaChange(schema, newMetaDataNodeValues);

        newMetaDataNodeValues.forEach(node => {
            onStepValueChange(node.property, node.value);
        })
    }

    const handleSchemaChange = async (schema) => {
        setSchemaSetupActionStep({
            ...schemaSetupActionStepInitialValue,
            options: schemas.map(x => ({ name: x.name, value: x.name })),
        })

        onStepValueChange(viewpointProperties.schema, { name: schema.name, value: schema.name });

        onStepValueChange(viewpointProperties.requiredColumns, schema.requiredColumnProperties);

        handleRetentionPolicy(schema);

        await handleMetadata(schema);
    }

    const handleMetadataNodeChange = (nodeId, value) => {
        const idx = metadataNodeValues.findIndex(x => x.id === nodeId);
        if (idx === -1) {
            return;
        }

        if (metadataNodeValues[idx].value === value) {
            return;
        }

        metadataNodeValues[idx] = { ...metadataNodeValues[idx], value: value };
        setMetadataNodeValues(metadataNodeValues);

        onStepValueChange(metadataNodeValues[idx].property, value);
    }

    return (
        <div className="space-y-4">
            {schemas?.length > 1 && (
                <SetupActionsSteps.OptionsSetupActionStep
                    workflowStepValues={workflowStepValues}
                    setupActionStep={schemaSetupActionStep}
                    onStepValueChange={(property, value) => {
                        const foundSchema = schemas.find(x => x.name === value.value);
                        if (foundSchema && foundSchema.name !== selectedSchema.name) {
                            handleSchemaChange(foundSchema);
                        }
                    }}
                />
            )}

            <SetupActionsSteps.OptionsSetupActionStep
                workflowStepValues={workflowStepValues}
                setupActionStep={retentionPolicySetupActionStep}
                onStepValueChange={onStepValueChange}
            />

            <div>
                <h4 className="font-semibold">{setupActionStep.name}:</h4>
                {metadataNodeValues.map((p, i) => (
                    <div key={p.id} className="grid grid-cols-12 space-x-2 mt-2">
                        <div className='col-span-4'>
                            <div className='min-w-0 w-full rounded-lg bg-gray-100 px-4 py-2 border-none'>
                                {p.name}{p.required && <span className="text-brand-pink font-medium">*</span>}
                            </div>
                        </div>
                        <div className='col-span-8'>
                            <ViewpointMetadataNodeComponent
                                workflowStep={workflowStep}
                                schema={selectedSchema}
                                node={p}
                                groupOptions={environmentGroups}
                                onChange={(nodeId, value) => handleMetadataNodeChange(nodeId, value)}
                                isLoading={loadAction.isExecuting}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

const ViewpointMetadataNodeComponent = ({ workflowStep, node, schema, groupOptions = [], onChange, isLoading }) => {
    const stepContext = useContext(WorkflowStepContext);
    const booleanOptions = [{ name: "true", value: "true" }, { name: "false", value: "false" }];

    const isEnvironmentIdColumn = (columnName) => {
        if (schema?.name === "centerpoint" && columnName === "EnvironmentId")
            return true;
        return false;
    }

    const isEnvironmentGroupIdIdColumn = (columnName) => {
        if (schema?.name === "centerpoint" && columnName === "EnvironmentGroupId")
            return true;
        return false;
    }

    if (isEnvironmentIdColumn(node.name)) {
        return (
            <div className='-mt-2'>
                <DataOptionsMenu
                    optionsData={Object.values(editorEnvironmentNames).map(env => ({ name: env, value: env }))}
                    selectedValue={node.value.value}
                    onSelect={value => {
                        onChange(node.id, value);
                    }}
                    isLoading={isLoading}
                />
            </div>
        )
    } else if (isEnvironmentGroupIdIdColumn(node.name)) {
        return (
            <div className='-mt-2'>
                <DataOptionsMenu
                    optionsData={groupOptions}
                    selectedValue={node.value.value}
                    onSelect={value => {
                        onChange(node.id, value)
                    }}
                    isLoading={isLoading}
                />
            </div>
        )
    } else {
        switch (node.type) {
            case viewpointColumnType.array:
                return (
                    <ViewpointMetadataArrayNodeComponent
                        node={node}
                        onChange={(nodeId, value) => onChange(nodeId, value)}
                        workflowStep={workflowStep}
                    />
                )
            case viewpointColumnType.boolean:
                return (
                    <div className='-mt-2'>
                        <DataOptionsMenu
                            optionsData={booleanOptions}
                            selectedValue={node.value.value}
                            onSelect={value => {
                                onChange(node.id, value)
                            }}
                            isLoading={isLoading}
                        />
                    </div>
                )
            default:
                return (
                    <NodeItem
                        item={node}
                        nodeValue={node.value.value}
                        placeHolder={"Value"}
                        handleNodeValueChange={(nodeId, value) => onChange(nodeId, { name: node.property, value: value })}
                        previousSteps={stepContext.previousSteps}
                        stepIdx={stepContext.stepIdx}
                        workflowStep={workflowStep}
                        previousStepsValues={stepContext.previousStepsValues}
                    />
                )
        }
    }
}
