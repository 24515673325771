import { Badge } from "@metaforcelabs/metaforce-core";
import { centerPointEnvironments, centerpointEnvironmentNames } from "../utils/constants";
import React from "react";

export const EnvironmentBadge = ({
    environment = null,
    isLatest = false
}) => {

    const envName = centerpointEnvironmentNames[environment];
    const determineBadgeType = () => {
        if (isLatest) {
            switch (environment) {
                case centerPointEnvironments.development:
                    return "info-alt";
                case centerPointEnvironments.test:
                    return "info";
                case centerPointEnvironments.preProd:
                    return "warn";
                case centerPointEnvironments.production:
                    return "success";
                default:
                    return "default"
            }
        }
        return "default";
    }

    return (
        <>
            <Badge type={determineBadgeType()} text={envName} />
        </>
    );
}